<template>
    <div class="sidebar-content">
        <div class="scrollable-nav">
            <ul class="nav flex-column">

                <b-row style="padding-left: 20px; padding-right: 20px;">

                    <SidebarMenuItem router-link="home" :hide-titles="!mobile" title="Dashboard" icon="home" />
                    <SidebarMenuItem router-link="create-diary" :hide-titles="!mobile" title="CPD Diary" icon="edit" v-requires-role="'User'"/>
                    <SidebarMenuItem router-link="create-reflection" :hide-titles="!mobile" title="Reflection" icon="edit" v-requires-role="'User'"/>
                    <SidebarMenuItem router-link="clinical-exposure" :hide-titles="!mobile" title="Clinical Exposure" icon="stethoscope" />
                    <SidebarMenuItem router-link="clinical-skills" :hide-titles="!mobile" title="Clinical Skills" icon="edit" />
                    <SidebarMenuItem router-link="mandatory-training" :hide-titles="!mobile" title="Courses and Compliance" icon="teach" />
                    <SidebarMenuItem router-link="entries" :hide-titles="!mobile" title="View All Entries" icon="list" />
                    <SidebarMenuItem router-link="profile" :hide-titles="!mobile" title="Profile" icon="account" v-requires-role="'User'" />
                    <SidebarMenuItem router-link="files" :hide-titles="!mobile" title="My Files" icon="file-cabinet" v-requires-role="'User'" />
                    <SidebarMenuItem router-link="portfolio-builder" :hide-titles="!mobile" title="Portfolio Builder" icon="portfolio" v-requires-role="'User'" />
                    <SidebarMenuItem router-link="shared-cpd" :hide-titles="!mobile" title="Shared CPD" icon="shared-cpd" v-requires-role="'User'"/>

                </b-row>

                <div class="mentor mt-2" v-if="isMentor">
                    <SidebarHeading title="Mentoring" />
                    <b-row style="padding-left: 20px; padding-right: 20px;">
                        <SidebarMenuItem router-link="mentor/for-approval" :hide-titles="!mobile" title="For Approval" icon="list" />
                        <SidebarMenuItem router-link="mentor/view-dashboard" :hide-titles="!mobile" title="View Dashboard" icon="loginas" />
                        <SidebarMenuItem router-link="mentor/reviews" :hide-titles="!mobile" title="Reviews" icon="review" />
                    </b-row>
                </div>

                <div class="manager mt-2" v-if="isManager">
                    <SidebarHeading title="Management" />
                    <b-row style="padding-left: 20px; padding-right: 20px;">
                        <SidebarMenuItem router-link="manager/reporting" :hide-titles="!mobile" title="Reporting" icon="chart" />
                    </b-row>
                </div>

                <div class="admin mt-2" v-if="isAdmin" >
                    <SidebarHeading title="Administration" />
                    <b-row style="padding-left: 20px; padding-right: 20px;">
                        <SidebarMenuItem router-link="admin/users" :hide-titles="!mobile" title="Users" icon="account" />
                        <SidebarMenuItem router-link="admin/configuration" :hide-titles="!mobile" title="Configure" icon="settings" v-requires-role="'Superadmin'"/>
                        <SidebarMenuItem router-link="admin/activity-log" :hide-titles="!mobile" title="Activity Log" icon="activity" />
                        <SidebarMenuItem router-link="admin/shared-cpd" :hide-titles="!mobile" title="Shared CPD" icon="list" />
                    </b-row>
                </div>

            </ul>

        </div>
    </div>
</template>

<script>
    import SidebarMenuItem from "./SidebarMenuItem";
    import SidebarHeading from "./SidebarHeading";
    import Managers from '../../managers/index';

    export default {
        name: "SidebarContent",
        components: {SidebarHeading, SidebarMenuItem},
        props: {
            mobile: {
                type: Boolean,
                required: false,
                defaultValue: false,
            }
        },
        data() {
            return {
                roles: []
            }
        },
        computed: {
            isAdmin() {
                return this.roles.includes('Admin');
            },
            isManager() {
                return this.roles.includes('Manager');
            },
            isMentor() {
                return this.roles.includes("Mentor");
            }
        },
        created() {
            this.roles = Managers.authentication.roles();
        }
    }
</script>

<style scoped>
.scrollable-nav {
    margin-bottom: 30px;
}

</style>
