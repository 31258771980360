import Vue from 'vue';

export default {
    approvalPreview(type, accessKey) {
        return Vue.http.get(`external/approval/${type}/${accessKey}`);
    },
    approve(type, accessKey, comments) {
        return Vue.http.post(`external/approval/${type}/${accessKey}/approve`, {comments});
    },
    reject(type, accessKey, comments) {
        return Vue.http.post(`external/approval/${type}/${accessKey}/reject`, {comments});
    }
}