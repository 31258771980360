import Vue from 'vue';

export default {
    pendingApproval(perPage, page) {
        return Vue.http.get(`admin/shared-cpd/pending-approval?page=${page}&per_page=${perPage}`);
    },
    approve(sharedCpdId) {
        return Vue.http.post(`admin/shared-cpd/${sharedCpdId}/approve`);
    },
    reject(sharedCpdId, message) {
        return Vue.http.post(`admin/shared-cpd/${sharedCpdId}/reject`, {reason: message});
    }
}