<template>
    <div class="date-range">
        <b-row>
            <b-col cols="6">
                <b-form-group label="From">
                    <b-form-datepicker v-model="startDate" />
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group label="To">
                    <b-form-datepicker v-model="endDate" />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <div class="actions">
                    <b-button pill size="sm" variant="outline-info" @click="fillLast12Months">Last 12 months</b-button>
                    <b-button v-for="range in quickRanges" v-bind:key="range.title" pill size="sm" variant="outline-info" @click="fillRange(range.from, range.to)">
                        {{range.title}}
                    </b-button>
                </div>

            </b-col>
        </b-row>

    </div>
</template>

<script>
    import Providers from '../../providers';

    export default {
        name: "DateRange",
        computed: {
            startDate: {
                get() {
                    return this.$store.state.portfolioBuilder.request.startDate;
                },
                set(val) {
                    this.$store.commit('portfolioBuilder/updateStartDate', val);
                }
            },
            endDate: {
                get() {
                    return this.$store.state.portfolioBuilder.request.endDate;
                },
                set(val)  {
                    this.$store.commit('portfolioBuilder/updateEndDate', val);
                }
            },
            quickRanges() {
                if (this.reportingPeriods == null) {
                    return [];
                }
                let ranges = [];
                ranges.push({
                    title: 'Current Reporting Period',
                    from: this.reportingPeriods.current.from,
                    to: this.reportingPeriods.current.to
                });
                if (this.reportingPeriods.previous) {
                    ranges.push({
                        title: 'Previous Reporting Period',
                        from: this.reportingPeriods.previous.from,
                        to: this.reportingPeriods.previous.to
                    });
                }
                return ranges;
            }
        },
        data() {
            return {
                reportingPeriods: null,
            }
        },
        methods: {
            fillLast12Months() {
                let currentDate = this.moment().format('YYYY-MM-DD');
                let startDate = this.moment().subtract(1, "year").format('YYYY-MM-DD');
                this.fillRange(startDate, currentDate);
            },
            fillRange(from, to) {
                this.startDate = from;
                this.endDate = to;
            },
            loadReportingPeriods() {
                Providers.information.reportingPeriods().then((resp) => {
                    this.reportingPeriods = resp.body;
                });
            }
        },
        created() {
            this.loadReportingPeriods();
        }
    }
</script>

<style scoped>
div.actions > * {
    margin-right: 4px;
}
</style>