<template>
    <div class="password-reset">
        <h1 class="h3 mb-3 font-weight-normal">Password Reset</h1>
        <b-form class="form-password-reset" @submit.prevent="updatePassword">
            <p>You can now set your new password below. Once updated, you will be directed to the login page to access your account.</p>
            <b-form-group label="New Password">
                <b-form-input id="password"
                              type="password"
                              v-model="password"
                              required
                              autofocus
                              :disabled="loading" />
            </b-form-group>
            <LoadingButton variant="primary" :loading="loading" type="submit" :block="true" @click="updatePassword">Update Password</LoadingButton>
        </b-form>
    </div>
</template>

<script>
    import LoadingButton from "../common/LoadingButton";
    export default {
        name: "PasswordResetFinishPage",
        props: {
            loading: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        components: {LoadingButton},
        data() {
            return {
                password: ''
            }
        },
        methods: {
            updatePassword() {
                this.$emit('update-password', {password: this.password});
            }
        }

    }
</script>

<style scoped>
    .password-reset {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }
</style>