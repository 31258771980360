<template>
    <div>
        <b-form class="form-login">
            <h1 class="h3 mb-3 font-weight-normal">Enter code</h1>
            <p>We have just send a code to your chosen verification contact.</p>
            <b-alert v-if="alert" :variant="alert.variant" show>
                {{alert.text}}
            </b-alert>
            <b-form-group label="Login Code">
                <b-form-input type="text" v-model="code" />
            </b-form-group>
            <LoadingButton :loading="loading" type="submit" :block="true" :disabled="!canLogin" @click="login">Login</LoadingButton>
            <LoadingButton :loading="resendLoading" type="button" :block="true" variant="default" :disabled="!canResend" @click="resendCode" v-b-tooltip="resendTooltip">Send another code</LoadingButton>
        </b-form>
    </div>
</template>

<script>

import Managers from '../../managers';
import LoadingButton from 'cpdcloud-dashboard/src/components/common/LoadingButton';
import Providers from "@/providers";

export default {
    name: "TwoFactorAuthCapture",
    components: {LoadingButton},
    data() {
        return {
            code: '',
            alert: null,
            username: null,
            password: null,
            loading: false,
            resendLoading: false,
            lastSend: Date.now(),
            timeNow: Date.now(),
            timeNowUpdater: null,
        }
    },
    computed: {
        canLogin() {
            return this.code.length >= 6;
        },
        canResend() {
             return this.timeSinceLastSend > 30;
        },
        timeSinceLastSend() {
            return Math.floor((this.timeNow - this.lastSend) / 1000);
        },
        resendTooltip() {
            if (!this.canResend) {
                return `You can send another code after ${30 - this.timeSinceLastSend} seconds`;
            }
            return '';
        }
    },
    methods: {
        login() {
            this.clearAlert();
            this.loading = true;
            Managers.authentication.login(this.username, this.password, this.code).catch((err) => {
                this.$httpError('Failed to login', err);
            }).finally(() => {
                this.loading = false;
            });
        },
        resendCode() {
            this.clearAlert();
            this.resendLoading = true;
            Providers.authentication.send2FA(this.username, this.password, this.method).then(() => {
                this.alert = {
                    text: 'A new code has been sent. Please enter the code below.',
                    variant: 'success'
                }
                this.lastSend = Date.now();
            }).catch((err) => {
                this.$httpError('Failed to perform Two-factor authentication', err);
            }).finally(() => {
                this.resendLoading = false;
            });
        },
        clearAlert() {
            this.alert = null;
        }
    },
    created() {
        this.username = this.$route.params.username;
        this.password = this.$route.params.password;
        this.method = this.$route.params.method;
        this.lastSend = Date.now();
        if (!this.username || !this.password || !this.method) {
            this.$router.push({name: 'login'});
        }

        this.timeNowUpdater = setInterval(() => {
            this.timeNow = Date.now();
        }, 1000);

    },
    beforeDestroy() {
        clearInterval(this.timeNowUpdater);
    }
}
</script>

<style scoped>
.form-login {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}
</style>
