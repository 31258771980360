<template>
    <b-modal ref="modal" title="Delete Entry" :hide-header-close="true"
             :no-close-on-esc="true" :no-close-on-backdrop="true" header-bg-variant="danger" header-text-variant="light"
             size="lg" ok-variant="danger" ok-title="Delete Entry" @ok="doDeleteEntry" :ok-disabled="loading" :cancel-disabled="loading">
        <b-overlay :show="loading">
            <p >Are you sure you want to delete the entry with title: </p>
            <p>"{{entryTitle}}"?</p>
            <b-alert show variant="danger">
                <AlertIcon /> Deletions are irreversible. Please make sure you don't need this entry before deleting.
            </b-alert>
        </b-overlay>
    </b-modal>
</template>

<script>
    import AlertIcon from 'vue-material-design-icons/Alert'
    import Providers from '../../providers/index';

    export default {
        name: "DeleteEntryModal",
        components: {AlertIcon},
        data() {
            return {
                entry: null,
                loading: false
            }
        },
        computed: {
            entryTitle() {
                if (this.entry) {
                    return this.entry.title;
                }
                return '';
            },
            entryId() {
                if (this.entry) {
                    return this.entry.id;
                }
                return null;
            }
        },
        methods: {
            show(entry) {
                this.entry = entry;
                this.$refs.modal.show();
            },
            hide() {
                this.$refs.modal.hide();
            },
            doDeleteEntry(e) {
                e.preventDefault();
                this.loading = true;
                Providers.entries.deleteEntry(this.entry.entryId).then(() => {
                    this.$success('Entry Deleted', 'Your entry has been successfully deleted');
                    this.$emit('entry-deleted');
                    this.hide();
                }).catch((resp) => {
                    this.$httpError('Failed to delete entry', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>