<template>
    <div class="dashboard-layout">
        <Header title="Defence Medical Services - CPD Solution" @logout="logout" @change-password="changePassword" @profile="profile" @show-menu="showMenu" :logo="logoUrl" :breadcrumb-title="breadcrumbTitle" :name="name"/>
        <div class="container-fluid">
            <div class="row">
                <Sidebar>
                    <SidebarContent />
                </Sidebar>
                <MainContent :version="version">
                    <template slot="banner-content">
                        <TopBannerNotice />
                        <TwoFactorUpsellNotice />
                    </template>
                </MainContent>
            </div>
        </div>
        <MobileSidebar ref="mobileSidebar" />
        <ChangePasswordModal ref="changePasswordModal" />
    </div>
</template>

<script>
    import Header from 'cpdcloud-dashboard/src/components/common/Header'
    import MainContent from 'cpdcloud-dashboard/src/components/common/MainContent'
    import Sidebar from 'cpdcloud-dashboard/src/components/common/Sidebar'
    import Managers from '../../managers/index';
    import Providers from '../../providers/index';
    import SidebarContent from "./SidebarContent";
    import MobileSidebar from "./MobileSidebar";
    import {version} from '../../../package.json';
    import ChangePasswordModal from "../tools/ChangePasswordModal";
    import TopBannerNotice from "./TopBannerNotice";
    import EventBus from "../../event-bus";
    import TwoFactorUpsellNotice from "@/components/common/TwoFactorUpsellNotice";

    export default {
        name: "DashboardLayout",
        components: {
            TwoFactorUpsellNotice,
            TopBannerNotice, ChangePasswordModal, MobileSidebar, SidebarContent, Header, MainContent, Sidebar},
        data() {
            return {
                breadcrumbTitle: '',
                name: '',
                logoOverride: '',
                version: version
            }
        },
        computed: {
            logoUrl() {
                if (this.logoOverride) {
                    return this.logoOverride;
                }
                return '/images/logo.png';
            },
        },
        methods: {
            logout() {
                Managers.authentication.logout();
            },
            // loadUserInfo() {
            //     Providers.profile.userInfo().then((resp) => {
            //         let userInfo = resp.body;
            //         this.name = userInfo.firstName;
            //     })
            // },
            loadBranding() {
                Providers.branding.branding().then((resp) => {
                    this.logoOverride = resp.body.logoUrl;
                })
            },
            loadSettings() {
               Providers.settings.settings().then((resp) => {
                    this.$store.dispatch('settings/storeSettings', resp.body);
                }).catch((resp) => {
                    this.$httpError('Failed to load settings', resp);
                });
            },
            showMenu() {
                this.$refs.mobileSidebar.show();
            },
            profile() {
                this.$router.push({name: 'profile'});
            },
            changePassword() {
                this.$refs.changePasswordModal.show();
            }
        },
        watch:{
            $route (to){
                this.breadcrumbTitle = to.meta.breadcrumbTitle;
            }
        },
        created() {
            // this.loadUserInfo();
            this.loadBranding();
            this.loadSettings();
            this.name = Managers.authentication.name();

            EventBus.$on('settings-updated', this.loadSettings);
        }
    }
</script>

<style scoped>

</style>
