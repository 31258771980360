<template>
    <div class="training-actions">
        <div class="actions">
            <b-button variant="info" @click="accessTraining"><HumanMaleBoardIcon /> Access Training</b-button>
            <b-button variant="secondary" @click="logTraining" v-requires-role="'User'"><PencilIcon /> Log Training Completion</b-button>
        </div>
        <LogTrainingModal ref="logTrainingModal" @training-added="trainingAdded" />
        <AvailableTrainingModal ref="availableTrainingModal" />
    </div>
</template>

<script>

    import HumanMaleBoardIcon from 'vue-material-design-icons/HumanMaleBoard';
    import PencilIcon from 'vue-material-design-icons/Pencil';
    import LogTrainingModal from "./LogTrainingModal";
    import AvailableTrainingModal from "./AvailableTrainingModal";
    export default {
        name: "TrainingActions",
        components: {AvailableTrainingModal, LogTrainingModal, HumanMaleBoardIcon, PencilIcon},
        methods: {
            logTraining() {
                this.$refs.logTrainingModal.show();
            },
            accessTraining() {
                this.$refs.availableTrainingModal.show();
            },
            trainingAdded() {
                this.$emit('training-added');
            }
        },
        mounted() {
            if (this.$route.params.intent === 'log') {
                this.logTraining();
            }
        }
    }
</script>

<style scoped>
    .training-actions {
        padding-left: 20px;
        padding-right: 20px;
    }
    .actions > * {
        margin: 0 4px;
    }


</style>
