<template>
    <div class="ce-entries">
        <b-table :fields="fields" :items="entries" show-empty>
            <template v-slot:empty>
                <b-alert show variant="primary">
                    There are no entries in this period
                </b-alert>
            </template>
            <template v-slot:cell(type)="data">
                <b-badge variant="info" pill v-if="data.item.type === 'Simulation Training'">{{data.item.type}}</b-badge>
                <b-badge variant="primary" pill v-if="data.item.type === 'Patient Contact / Placement'">{{data.item.type}}</b-badge>
            </template>
            <template v-slot:cell(cpdHours)="data">
                <div v-if="data.item.type === 'Simulation Training'">
                    {{data.item.cpdHours}}
                </div>
                <div v-else>
                    -
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>

    import Providers from '../../providers/index';

    const FIELDS = [
        {
            key: 'dateCompleted',
            label: 'Date'
        },
        {
            key: 'type',
            label: 'Type'
        },
        {
            key: 'title',
            label: 'Title'
        },
        {
            key: 'cceHours',
            label: 'CCE Hours'
        },
        {
            key: 'cpdHours',
            label: 'CPD Hours'
        },
        {
            key: 'state',
            label: 'Status'
        },
        {
            key: 'actions',
            label: 'Actions'
        }
    ];

    export default {
        name: "Entries",
        props: {
            dateRange: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {
                fields: FIELDS,
                entries: [],
                loading: false
            }
        },
        watch: {
            dateRange: {
                deep: true,
                handler() {
                    this.refresh();
                }
            }
        },
        methods: {
            loadEntries() {
                this.loading = true;
                Providers.clinicalExposure.clinicalExposures(this.dateRange).then((resp) => {
                    this.entries = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load entries', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            refresh() {
                this.loadEntries();
            }
        },
        created() {
            this.loadEntries();
        }
    }
</script>

<style scoped>

</style>