<template>
    <div class="entries">

        <EntryFilter :filter="filter" @filter-changed="updateFilter" />
        <b-table ref="entriesTable" :fields="fields" :items="entryProvider" :responsive="true" :per-page="perPage" :busy.sync="isBusy" :current-page="currentPage" striped>
            <template v-slot:cell(render_completeness)="data" style="padding: 0;">
                <div :class="'completeness completeness-' + data.item.completeness"></div>
            </template>
            <template v-slot:cell(type)="data">
                <b-badge variant="secondary">{{uppercase(data.item.type)}}</b-badge>
            </template>
            <template v-slot:cell(state)="data">
                <b-badge :variant="stateVariant(data.item.state)">{{uppercase(data.item.state)}}</b-badge>
            </template>
            <template v-slot:cell(actions)="data">
                <div class="actions">
                    <b-button v-requires-role="'User'" v-if="can(data.item.possibleActions, 'Edit')" @click="edit(data.item)" size="sm" variant="outline-secondary"><pencil-outline-icon /> Edit</b-button>
                    <b-button v-if="can(data.item.possibleActions, 'Preview')" @click="previewEntry(data.item)" size="sm" variant="outline-dark"><eye-outline-icon /> Preview</b-button>
                    <b-button v-requires-role="'User'" v-if="can(data.item.possibleActions, 'SubmitForApproval')" @click="submitForApproval(data.item)" size="sm" variant="outline-info">Submit for Approval</b-button>
                    <b-button v-requires-role="'User'" v-if="can(data.item.possibleActions, 'Delete')" @click="deleteEntry(data.item)" size="sm" variant="outline-danger"><trash-can-outline-icon /> Delete</b-button>
                    <b-button v-requires-role="'User'" v-if="can(data.item.possibleActions, 'ShareCPD')" @click="shareEntry(data.item)" size="sm" variant="outline-success"><ShareIcon /> Share Entry</b-button>
                    <b-button v-requires-role="'User'" v-if="can(data.item.possibleActions, 'UnshareCPD')" @click="unshareEntry(data.item)" size="sm" variant="outline-warning"><ShareOffIcon /> Unshare</b-button>
                </div>
            </template>
            <template v-slot:cell(evidence)="data">
                <div class="evidence-item" :key="e.downloadLink" v-for="e of data.item.attachments">
                    <b-dropdown size="sm" variant="outline-dark" class="mx-1" right :text="truncateFilename(e.filename)" :title="e.filename">
                        <b-dropdown-item @click="openEvidence(e)" target="_blank">Open</b-dropdown-item>
                    </b-dropdown>
                </div>
            </template>
        </b-table>
        <b-pagination :disabled="isBusy" align="center" :hideGotoEndButtons="true" prevText="Previous" nextText="Next" size="sm" :limit="10" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" />
        <PreviewEntryModal ref="previewModal" show-approval-info />
        <DeleteEntryModal ref="deleteEntryModal" @entry-deleted="refresh"/>
        <ShareCPDModal ref="shareCPDModal" @cpd-shared="refresh"/>
    </div>
</template>

<script>

    import FormatterUtils from 'cpdcloud-dashboard/src/utilities/FormatterUtils';
    import Providers from '../providers';
    import EyeOutlineIcon from 'vue-material-design-icons/EyeOutline';
    import TrashCanOutlineIcon from 'vue-material-design-icons/TrashCanOutline';
    import PencilOutlineIcon from 'vue-material-design-icons/PencilOutline';
    import ShareIcon from 'vue-material-design-icons/Share';
    import ShareOffIcon from 'vue-material-design-icons/ShareOff';
    import PreviewEntryModal from "../components/entries/PreviewEntryModal";
    import EntryFilter from "../components/entries/EntryFilter";
    import DeleteEntryModal from "../components/entries/DeleteEntryModal";
    import ShareCPDModal from "../components/shared-cpd/ShareCPDModal";

    const TABLE_FIELDS = [
        {
            key: 'render_completeness',
            label: '',
        },
        {
            key: 'startDate',
            label: 'Start Date',
        },
        {
            key: 'type',
            label: 'Type',
        },
        {
            key: 'title',
        },
        {
            label: 'Hours',
            key: 'hours',
            formatter: function(val) {
                if (val === null) {
                    return '--'
                }
                return val;
            }
        },
        {
            label: 'Evidence',
            key: 'evidence'
        },
        {
            label: 'Status',
            key: 'state'
        },
        {
            label: 'Actions',
            key: 'actions'
        }
    ];

    export default {
        name: "Entries",
        components: {
            ShareCPDModal,
            DeleteEntryModal,
            EntryFilter, PreviewEntryModal, EyeOutlineIcon, TrashCanOutlineIcon, PencilOutlineIcon, ShareIcon, ShareOffIcon},
        data() {
            return {
                perPage: 10,
                currentPage: 1,
                totalRows: 0,
                isBusy: false,
                fields: TABLE_FIELDS,
                filter: {
                    year: '',
                    state: '',
                    title: '',
                    type: ''
                }
            }
        },
        methods: {
            entryProvider: function (ctx, callback) {
                this.isBusy = true;
                Providers.entries.entries(ctx.currentPage, ctx.perPage, this.filter).then(response => {
                    callback(response.body.items);
                    this.totalRows = response.body.totalItems
                }).catch((resp) => {
                    this.$httpError('Failed to load entries', resp);
                }).finally(() => {
                    this.isBusy = false;
                });
                return null
            },
            uppercase(type) {
                return FormatterUtils.capitalize(type);
            },
            can(possibleActions, action) {
                for (let possibleAction of possibleActions) {
                    if (possibleAction === action) {
                        return true;
                    }
                }
                return false;
            },
            submitForApproval(item) {
                this.isBusy = true;
                Providers.entries.submitForApproval(item.entryId).then(() => {
                    this.$success('Submitted Successfully', 'Your entry has now been submitted for approval. You\'ll receive an email once this has been approved.');
                }).catch((resp) => {
                    this.$httpError('Failed to Submit Entry for Approval', resp);
                }).finally(() => {
                    this.isBusy = false;
                    this.refresh();
                });
            },
            edit(entry) {
                let routeName = 'edit/' + entry.type.toLowerCase();
                this.$router.push({name: routeName, params: {id: entry.entryId}});
            },
            previewEntry(entry) {
                this.$refs.previewModal.show(entry.entryId, entry.entryType);
            },
            deleteEntry(entry) {
                this.$refs.deleteEntryModal.show(entry);
            },
            shareEntry(entry) {
                this.$refs.shareCPDModal.show(entry);
            },
            unshareEntry(entry) {
                this.loading = true;
                Providers.sharedCPD.unshare(entry.entryId).then(() => {
                    this.$success('Entry Unshared', 'The entry has been unshared successfully');
                    this.refresh();
                }).catch((resp) => {
                    this.$httpError('Failed to unshare entry', resp);
                }).finally(() =>{
                    this.loading = false;
                })
            },
            refresh() {
                this.$refs.entriesTable.refresh();
            },
            updateFilter($e) {
                this.filter = $e;
                this.refresh();
            },
            truncateFilename: function(filename) {
                if (filename.length <= 30) {
                    return filename;
                } else {
                    return filename.substr(0, 17) + '...' + filename.substr(filename.length-17, filename.length);
                }
            },
            openEvidence(e) {
                window.open(e.downloadLink);
            },
            stateVariant(state) {
                switch (state) {
                    case 'Created':
                        return 'secondary'
                    case 'Pending Approval':
                        return 'info'
                    case 'Approved':
                        return 'success'
                    case 'Returned':
                        return 'danger'
                }
                return 'dark';
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '~cpdcloud-dashboard/src/assets/variables';

    .completeness {
        height: 25px;
        width: 8px;
        display: inline-block;
        background-color:darkgray;
    }

    .completeness-3 { /* incomplete */
        background-color: #cc3232;
    }

    .completeness-2 { /* partial */
        background-color: #e7b416;
    }

    .completeness-1 { /* complete */
        background-color: #99c140;
    }

    .actions > * {
        margin: 0 4px;
    }

</style>