<template>
    <div class="pie-chart-widget">
        <b-card :header="title">
            <PieGraph :height="200" :chart-data="chartData.chartData" :options="chartData.options" />
        </b-card>
    </div>
</template>

<script>
    import graphUtilsMixin from "../../../mixins/graphUtils";
    import PieGraph from "../../graphs/PieGraph";

    export default {
        name: "PieChartWidget",
        components: {PieGraph},
        mixins: [graphUtilsMixin],
        props: {
            title: {
                type: String,
                required: true
            },
            categoryData: {
                type: Array,
                required: true
            }
        },
        computed: {
            chartData() {
                return this.$transformToChartData(this.categoryData);
            }
        }
    }
</script>

<style scoped>

</style>