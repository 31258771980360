<template>
    <div class="top-stats">
        <b-row>
            <b-col sm="3">
                <a class="stat-link" @click.prevent="goto('entries')" href="#">
                    <TopStatistic header="Diary Entries" :loading="this.statistics == null" :value="diaryEntries" variant="primary" text-variant="white"/>
                </a>
            </b-col>
            <b-col sm="3">
                <a class="stat-link" @click.prevent="goto('entries')" href="#">
                    <TopStatistic header="Reflection Entries" :loading="this.statistics == null" :value="reflectiveEntries" variant="primary" text-variant="white" />
                </a>
            </b-col>
            <b-col sm="3">
                <a class="stat-link" @click.prevent="goto('entries')" href="#">
                    <TopStatistic header="CCE Entries" :loading="this.statistics == null" :value="cceEntries" variant="primary" text-variant="white" />
                </a>
            </b-col>
            <b-col sm="3">
                <a class="stat-link" @click.prevent="goto('clinical-skills')" href="#">
                    <TopStatistic  header="Clinical Skills" :loading="this.statistics == null" :value="clinicalSkillsEntries" variant="primary" text-variant="white" />
                </a>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import TopStatistic from 'cpdcloud-dashboard/src/components/common/TopStatistic'

    export default {
        name: "TopStatistics",
        components: {TopStatistic},
        props: {
            statistics: {
                type: Object,
                required: true
            }
        },
        computed: {
            diaryEntries() {
                return this.statistics == null ? 0 : this.statistics.diaryEntries;
            },
            reflectiveEntries() {
                return this.statistics == null ? 0 : this.statistics.reflectiveEntries;
            },
            cceEntries() {
                return this.statistics == null ? 0 : this.statistics.cceEntries;
            },
            clinicalSkillsEntries() {
                return this.statistics == null ? 0 : this.statistics.clinicalSkillsEntries;
            }
        },
        data() {
            return {
            }
        },
        methods: {
            goto(routeName) {
                this.$router.push({name: routeName});
            }
        },
    }
</script>

<style scoped>
    a.stat-link, a.stat-link:hover {
        text-decoration: none;
    }
</style>