<template>
    <div class="approval-details">
        <h3>{{type}}</h3>
        <b-form-group v-for="(attribute, index) in attributes" v-bind:key="index" :label="attribute.title">
            {{attribute.value}}
        </b-form-group>
        <b-form-group label="Attachments" v-if="attachments && attachments.length > 0">
            <ul>
                <li v-for="attachment in attachments" v-bind:key="attachment.id"><a :href="attachment.downloadUrl" target="_blank"><PaperclipIcon /> {{attachment.name}}</a></li>
            </ul>
        </b-form-group>
    </div>
</template>

<script>
    import PaperclipIcon from 'vue-material-design-icons/Paperclip';

    export default {
        name: "EntryPreview",
        components: {PaperclipIcon},
        props: {
            attributes: {
                type: Array,
                required: true,
            },
            attachments: {
                type: Array,
                required: false,
                default: () => []
            },
            type: {
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>
