import Vue from 'vue';

export default {
    approvalDetails(type, entryId) {
        return Vue.http.get(`all-entries/${type}/${entryId}/approval-details`);
    },
    preview(type, entryId) {
        return Vue.http.get(`all-entries/${type}/${entryId}/preview`);
    },
    attachableEntries(type) {
        return Vue.http.get(`all-entries/${type}/attachable`);
    }
}