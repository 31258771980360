<template>
    <div class="basic-details">
        <b-card header="Basic Details">
            <b-row>
                <b-col sm="12" md="4" class="profile-image-container" >
                    <ProfileImage :profile-image-url="profileImageUrl" ref="profileImage" />
                    <small><a href="#" @click="changeImage">Upload Image</a></small>
                    <input type="file" accept="image/jpeg, image/png" v-on:change="imageChanged" ref="profileImageInput" style="display: none">
                </b-col>
                <b-col sm="12" md="8">
                    <!-- details -->
                    <b>Name: </b>{{userInformation.firstName}} {{userInformation.lastName}}<br />
                    <b>Unit: </b> {{userInformation.unit}}<br />
                    <b>Facility: </b> {{userInformation.facility}}<br />
                    <b>Mentor(s): </b> {{mentors}}<br />
                    <b>Job Role(s): <b-badge pill v-bind:key="jobRole" v-for="jobRole of userInformation.roles" class="mr-2">{{jobRole}}</b-badge></b><br />
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
    import ProfileImage from "./ProfileImage";

    export default {
        name: "ProfileBasicDetails",
        components: {ProfileImage},
        props: {
            userInformation: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                baseUrl: ''
            }
        },
        methods: {
            imageChanged(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                let file = files[0];
                if (file.size > 10 * 1024 * 1024) {
                    alert('File size cannot exceed 10MB');
                    return;
                }
                let type = file.type;
                this.isLoading = true;
                this.$http.post(`profile/image`, file, {
                    headers: {
                        'Content-Type': type
                    }
                }).then(() => {
                    this.isLoading = false;
                    this.$refs.profileImage.refresh();
                }).catch((resp) => {
                    this.$httpError('Failed to upload picture', resp);
                });
            },
            changeImage() {
                this.$refs.profileImageInput.click()
            }
        },
        computed: {
            profileImageUrl() {
                let authToken = this.$store.state.authentication.loginToken;
                return `${this.baseUrl}profile/image?auth_token=${authToken}`
            },
            mentors() {
                if (this.userInformation.mentors.length === 0) {
                    return 'none';
                }
                return this.userInformation.mentors.join(', ');
            }
        },
        created() {
            this.baseUrl = process.env.VUE_APP_API_ROOT;
        }
    }
</script>

<style scoped>
    .profile-image-container {
        text-align: center;
        border-right: 1px solid lightgrey;
    }
</style>
