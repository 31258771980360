import Vue from "vue";

export default {
    users(perPage, page, search) {
        return Vue.http.get(`admin/user-management/users?per_page=${perPage}&page=${page}&search=${search || ''}`);
    },
    createUser(request) {
        return Vue.http.post('admin/user-management/user', request);
    },
    user(userId) {
        return Vue.http.get(`admin/user-management/user/${userId}`);
    },
    updateUser(userId, user) {
        return Vue.http.put(`admin/user-management/user/${userId}`, user);
    },
    enableUser(userId) {
        return Vue.http.post(`admin/user-management/user/${userId}/enable`);
    },
    disableUser(userId) {
        return Vue.http.post(`admin/user-management/user/${userId}/disable`);
    },
    availableMentors() {
        return Vue.http.get('admin/user-management/available-mentors');
    },
    resetPassword(userId) {
        return Vue.http.post(`admin/user-management/user/${userId}/reset-password`);
    }
}
