<template>
    <div class="reporting-period-selector">
        <b-form inline>
            <Dropdown title="Period" :options="reportingPeriods" v-model="selectedPeriod" size="sm" />
            <p class="ml-2 mt-3 font-weight-light" v-if="showRange"><span>Reporting Period</span> {{dateRange}}</p>
        </b-form>
    </div>
</template>

<script>
    import Providers from "../../providers";
    import Dropdown from "./Dropdown";

    export default {
        name: "ReportingPeriodSelector",
        components: {Dropdown},
        props: {
            value: {
                type: Object,
                required: false,
            },
            showRange: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                loading: false,
                selectedPeriod: null,
                userReportingPeriods: null
            }
        },
        computed: {
            reportingPeriods() {
                let today = this.moment().format('YYYY-MM-DD');
                let lastYear = this.moment().subtract(1, 'years').add(1, 'days').format('YYYY-MM-DD');
                let periods = [];
                periods.push({
                    title: 'Last 12 months',
                    value: `${lastYear}:${today}`
                });
                if (this.userReportingPeriods) {
                    let current = this.userReportingPeriods.current;
                    let previous = this.userReportingPeriods.previous;
                    periods.push({
                        title: 'Current Reporting Period',
                        value: `${current.from}:${current.to}`
                    });
                    if (previous) {
                        periods.push({
                            title: 'Previous Reporting Period',
                            value: `${previous.from}:${previous.to}`
                        });
                    }
                }
                return periods;
            },
            dateRange() {
                if (this.value) {
                    let from = this.moment(this.value.from);
                    let to = this.moment(this.value.to);
                    return `${from.format('DD/MM/YYYY')} to ${to.format('DD/MM/YYYY')}`;
                }
                return '';
            }
        },
        watch: {
            selectedPeriod() {
                this.updateValue(this.selectedPeriod);
            }
        },
        methods: {
            loadUserReportingPeriods() {
                this.loading = true;
                Providers.information.reportingPeriods().then((resp) => {
                    this.userReportingPeriods = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load reporting periods', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            updateValue(kvValue) {
                let parts = kvValue.split(':');
                this.$emit('input', {
                    from: parts[0],
                    to: parts[1]
                });
            }

        },
        created() {
            this.selectedPeriod = this.reportingPeriods[0].value;
            this.loadUserReportingPeriods();
        }
    }
</script>

<style scoped>

</style>