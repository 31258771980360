<template>
    <div class="agreement mb-4">
        <b-form-checkbox v-model="statement1">1. I continue to practice in line with my code of conduct.</b-form-checkbox>
        <b-form-checkbox v-model="statement2">2. I accept my professional obligations to ensure that my health does not pose a risk to my patients and have reported any concerns.</b-form-checkbox>
        <b-form-checkbox v-model="statement3">3. I am not involved in any complaints or have reflected upon those in which I am involved and discussed with my mentor.</b-form-checkbox>
    </div>
</template>

<script>
    export default {
        name: "Agreement",
        props: {
            value: {
                type: Boolean,
                required: true,
                default: false,
            }
        },
        data() {
            return {
                statement1: false,
                statement2: false,
                statement3: false
            }
        },
        watch: {
            statement1() {
                this.update();
            },
            statement2() {
                this.update();
            },
            statement3() {
                this.update();
            }
        },
        methods: {
            update() {
                if (this.statement1 && this.statement2 && this.statement3) {
                    this.$emit('input', true);
                } else {
                    this.$emit('input', false);
                }
            }
        },
        created() {
            if (this.value === true) {
                this.statement1 = true;
                this.statement2 = true;
                this.statement3 = true;
            }
        }
    }
</script>

<style scoped>

</style>