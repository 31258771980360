<template>
    <div class="approval-details">
        <div class="loaded" v-if="showApproval">
            <SpeechBubble v-if="approvalDetails.comments" :author="approvalDetails.approverName" variant="success">
                {{approvalDetails.comments}}
            </SpeechBubble>
        </div>
        <div class="loader text-center" v-if="loading">
            <b-spinner></b-spinner>
        </div>
    </div>
</template>

<script>
    import Providers from '../../providers/index';
    import SpeechBubble from "../common/SpeechBubble";

    export default {
        name: "ApprovalDetails",
        components: {SpeechBubble},
        props: {
            type: {
                required: true,
                type: String
            },
            entryId: {
                required: true,
                type: Number
            }
        },
        data() {
            return {
                loading: false,
                approvalDetails: null,
            }
        },
        computed: {
            showApproval() {
                return this.approvalDetails !== null && this.approvalDetails.type !== 'Self Certify';
            }
        },
        methods: {
            loadApprovalDetails() {
                this.loading = true;
                Providers.allEntries.approvalDetails(this.type, this.entryId).then((resp) => {
                    this.approvalDetails = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load approval details', resp);
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        created() {
            this.loadApprovalDetails();
        }
    }
</script>

<style scoped>
    div.approval-details {
        width: 100%;
    }
</style>