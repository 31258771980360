<template>
    <div class="activation">
        <b-form class="form" @submit.prevent="doActivate">
            <h1 class="h3 mb-3 font-weight-normal">Complete Registration</h1>
            <b-form-group label="Create Password">
                <b-form-input type="password" :invalid-feedback="passwordStateInvalid" :state="passwordState" v-model="password"/>
            </b-form-group>
            <b-form-group label="Confirm Password">
                <b-form-input type="password" :invalid-feedback="confirmPasswordStateInvalid" :state="confirmPasswordState" v-model="passwordConfirm" />
            </b-form-group>

            <b-button variant="primary" type="submit" :block="true" :disabled="loading || !(passwordState && confirmPasswordState)">Register</b-button>
        </b-form>
    </div>
</template>

<script>
    import Providers from '../../providers/index';
    export default {
        name: "Activate",
        data() {
            return {
                activationToken: null,
                password: '',
                passwordConfirm: '',
                loading: false
            }
        },
        computed: {
            passwordState() {
                if (this.password === '') {
                    return null;
                }
                return this.password.length >= 8
            },
            passwordStateInvalid() {
                if (this.password.length >= 8) {
                    return ''
                } else if (this.password.length > 0) {
                    return 'Your password should be at least 8 characters.'
                } else {
                    return ''
                }
            },
            confirmPasswordState() {
                if (this.passwordConfirm === '') {
                    return null;
                }
                return  this.passwordConfirm === this.password;
            },
            confirmPasswordStateInvalid() {
                if (this.passwordConfirm.length > 0 && this.password !== this.passwordConfirm) {
                    return 'Please ensure the passwords match.';
                }
                return '';
            }
        },
        methods: {
            doActivate() {
                if (this.passwordState && this.confirmPasswordState) {
                    this.loading = true;
                    Providers.authentication.activateAccount(this.activationToken, this.password).then(() => {
                        this.$success('Account Created', 'Your account has been set up successfully! Please proceed to login with your credentials.');
                        this.$router.push({name: 'login'});
                    }).catch((resp) => {
                        this.$httpError('Failed to activate', resp);
                    }).finally(() => {
                        this.loading = false;
                    });
                }
            }
        },
        created() {
            this.activationToken = this.$route.params.activationToken;
        }
    }
</script>

<style scoped>
    .form {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }
</style>