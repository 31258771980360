<template>
    <div class="statistics">
        <b-card header="Statistics">
            <b-overlay :show="loading">
                <b-row>
                    <b-col md="7">
                        <ReportingPeriodSelector v-model="selectedPeriod" />
                        <b-row>
                            <b-col cols="12">
                                <b-card-group deck>
                                    <TopStatistic header="Total Hours" :value="loggedHours" small/>
                                    <TopStatistic header="Simulation Training Hours" :value="etsHours" small/>
                                    <TopStatistic header="Patient Contact Hours" :value="dpcHours" small/>
                                </b-card-group>
                            </b-col>
                        </b-row>


                    </b-col>
                    <b-col md="5">
                        <PieGraph :height="200" :chart-data="statusBreakdown.chartData" :options="statusBreakdown.options" v-if="statusBreakdown"/>
                    </b-col>
                </b-row>
            </b-overlay>
        </b-card>
    </div>
</template>

<script>
    import PieGraph from '../graphs/PieGraph';
    import graphUtilsMixin from "../../mixins/graphUtils";
    import Providers from '../../providers/index';
    import TopStatistic from "cpdcloud-dashboard/src/components/common/TopStatistic";
    import ReportingPeriodSelector from "../common/ReportingPeriodSelector";

    export default {
        name: "Statistics",
        components: {ReportingPeriodSelector, PieGraph, TopStatistic},
        mixins: [graphUtilsMixin],
        data() {
            return {
                loading: false,
                selectedPeriod: null,
                statusBreakdown: null,
                loggedHours: 0,
                etsHours: 0,
                dpcHours: 0
            }
        },
        watch: {
            selectedPeriod() {
                this.loadStatistics();
            }
        },
        methods: {
            loadStatistics() {
                if (this.selectedPeriod === null)
                    return;
                this.loading = true;
                Providers.clinicalExposure.statistics(this.selectedPeriod).then((resp) => {
                    this.statusBreakdown = this.$transformToChartData(resp.body.statusBreakdown);
                    this.loggedHours = resp.body.loggedHours;
                    this.etsHours = resp.body.etsHours;
                    this.dpcHours = resp.body.dpcHours;
                }).catch((resp) => {
                    this.$httpError('Failed to load Statistics', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            refresh() {
                this.loadStatistics();
            }
        },
        created() {
            this.loadStatistics();
        }
    }
</script>

<style scoped>

</style>