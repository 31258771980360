<template>
    <div class="activity-widget">
        <b-card header="Recent Activity">
            <div class="activity-container" @scroll="onScroll">
                <Timeline message-when-no-items="You've had no activity recorded yet" :timeline-items="timelineItems" :unique-timeline="true" :unique-year="true" color-dots="#4e2a3c"/>
                <div class="controls text-center">
                    <b-button v-if="hasMore && !loading" variant="light">Load More</b-button>
                    <b-spinner v-if="loading"></b-spinner>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
    import Providers from '../../../providers';
    import "timeline-vuejs/dist/timeline-vuejs.css";
    import Timeline from 'timeline-vuejs';

    export default {
        name: "ActivityWidget",
        components: {Timeline},
        data() {
            return {
                items: [],
                currentPage: 1,
                hasMore: false,
                loading: false,
            }
        },
        computed: {
            timelineItems() {
                return this.items.map(item => {
                    let date = this.moment(item.happenedAt);
                    return {
                        from: date.toDate(),
                        title: item.message,
                        description: date.fromNow(),
                        showDayAndMonth: true,
                        showYear: false
                    }
                });
            }
        },
        methods: {
            onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
                if (scrollTop + clientHeight >= scrollHeight) {
                    if (!this.isLoading && this.hasMore) {
                        console.log('Load more');
                        this.loadEntries();
                    }
                }
            },
            loadEntries() {
                this.loading = true;
                Providers.activityLog.activityLog(4, this.currentPage).then((resp) => {
                    this.items.push(...resp.body.items);
                    this.hasMore = resp.body.numberOfPages > this.currentPage;
                    this.currentPage++;
                }).catch((resp) => {
                    this.$httpError('Failed to load activity', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        created() {
            this.loadEntries();
        }
    }
</script>

<style scoped>
.activity-container {
    height: 315px;
    overflow: scroll;
    position: relative;
}
    .fade {
        position: absolute;
        bottom: 0px;

        display: block;

        width: 100%;
        height: 50px;

        background-image: linear-gradient(to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.9)
        100%);
    }
</style>