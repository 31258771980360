import Vue from 'vue'
import Vuex from 'vuex'
import authentication from "./modules/authentication";
import session from './modules/session';
import settings from "./modules/settings";
import portfolioBuilder from './modules/portfolio-builder';
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    key: 'dms-data',
    storage: window.localStorage,
    modules: ['authentication']
});

export default new Vuex.Store({
    modules: {
        authentication,
        session,
        settings,
        portfolioBuilder
    },
    state: {

    },
    mutations: {

    },
    actions: {

    },
    plugins: [vuexLocal.plugin]
})
