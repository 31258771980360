<template>
    <div class="form-container">
        <b-form v-if="form.pages && form.pages.length > 0" class="form" @change="formChanged">
            <div class="form-page" v-for="(page, index) in form.pages" :key="index">
                <FormPage :page="page" :form-data="formData" v-if="pageActivated(page)" @open-file="openFile"/>
            </div>
        </b-form>
        <div class="scope-of-practice">
            <ScopeOfPractice :scope-of-practice-info="scopeOfPracticeInfo" />
        </div>
        <div class="approval">
            <ApprovalInfo :approval-info="approvalInfo" no-confirmation />
        </div>
        <div class="disclaimer mt-2 mb-5" >
            <small>By submitting this entry, you confirm that the information provided above is a true reflection of your personal development and does not contain any person, patient, client or service user identifiable data that could breach data protection laws or legislation relating to your employer, governing bodies, professional organisations, code of conduct and associated revalidation guidance. </small>
        </div>
        <div class="actions">
            <b-row>
                <b-col sm="6" class="mt-2">
                    <b-button @click="sAndClose" variant="success" :block="true" type="button"><content-save-icon /> Save and Close</b-button>
                </b-col>
                <b-col sm="6" class="mt-2">
                    <b-button @click="sAndContinue" variant="outline-info" :block="true" type="button"><content-save-edit-icon /> Save and Continue</b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12" class="mt-2" v-b-tooltip.hover="previewHoverText" :title="previewHoverText">
                    <b-button  @click="previewEntry" :disabled="edited || !isEditing" variant="outline-dark" :block="true" type="button"><eye-outline-icon /> Preview Entry</b-button>
                </b-col>
            </b-row>
        </div>

        <PreviewEntryModal ref="previewModal" />
<!--        <AutosaveModal ref="autosaveModal" @leave-without-saving="leaveRoute" @save-and-exit="sAndClose"/>-->
    </div>
</template>

<script>
    import FormPage from 'cpdcloud-dashboard/src/components/forms/FormPage';
    import PreviewEntryModal from '../../components/entries/PreviewEntryModal';

    import ContentSaveEditIcon from "vue-material-design-icons/ContentSaveEdit";
    import ContentSaveIcon from "vue-material-design-icons/ContentSave";
    import EyeOutlineIcon from "vue-material-design-icons/EyeOutline";
    import FormUtils from 'cpdcloud-dashboard/src/utilities/FormUtils';
    import EventBus from "../../event-bus";
    import ApprovalInfo from "./ApprovalInfo";
    import Providers from '../../providers/index';
    import ScopeOfPractice from "./ScopeOfPractice";

    export default {
        name: "Form",
        props: {
            form: Object,
            loadForm: Function,
            entryId: String,
            approvalInfo: Object,
            scopeOfPracticeInfo: Object
        },
        components: {
            ScopeOfPractice,
            ApprovalInfo, ContentSaveIcon, ContentSaveEditIcon, EyeOutlineIcon, FormPage, PreviewEntryModal},
        data: function () {
            return {
                busy: false,
                edited: false,
                continueRoute: null
            }
        },
        computed: {
            formData: function () {
                return FormUtils.flatten(this.form);
            },
            isEditing: function () {
                return this.entryId != null
            },
            previewHoverText() {
                if (!this.isEditing || this.edited) {
                    return 'You must Save and Continue before you can preview the entry.';
                }
                return '';
            }
        },
        methods: {
            openFile($e) {
                Providers.files.requestFilePreview($e).then((resp) => {
                    window.open(resp.body.url, '_blank');
                }).catch((resp) => {
                    this.$httpError('Failed to load file', resp);
                });
            },
            formChanged() {
                this.edited = true;
            },
            sAndClose() {
                if (this.isEditing) {
                    this.saveAndClose();
                } else {
                    this.submitAndClose();
                }
            },
            sAndContinue() {
                if (this.isEditing) {
                    this.saveAndContinue();
                } else {
                    this.submitAndContinue();
                }
            },
            pageActivated: function (page) {
                if (!page.requiresActivation) return true;
                let data = this.formData;
                for (let key in page.activatedBy) {
                    if (Object.prototype.hasOwnProperty.call(page.activatedBy, key)) {
                        if (data.has(key)) {
                            let formValue = data.get(key);
                            if (page.activatedBy[key].includes(formValue)) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            },
            submitAndContinue() {
                this.submitEntry((entryId) => {
                    let routeName = 'edit/' + this.form.identifier;
                    this.$router.push({name: routeName, params: {id: entryId}});
                    this.$success('Entry saved successfully', 'You can continue make changes to it.');
                });
            },
            submitAndClose() {
                this.submitEntry(() => {
                    EventBus.$emit('entry-submitted');
                    EventBus.$emit('show-toast', {title: 'Entry Submitted', message: 'You can edit it under the \'Entries\' section'});
                    this.$router.push({name: 'entries'});
                });
            },
            submitEntry: function (completionFunction) {
                let data = FormUtils.flatten(this.form);
                this.busy = true;

                Providers.entries.createEntry(this.form.identifier, FormUtils.mapToJson(data), this.approvalInfo, this.scopeOfPracticeInfo).then((resp) => {
                    this.$emit('loading-entry');
                    // EventBus.$emit('entry-submitted');
                    this.edited = false;
                    completionFunction(resp.body.entryId);
                }).catch((errResp) => {
                    this.$emit('show-message', {message: errResp.body.message, type: 'danger'});
                }).finally(() => {
                    this.busy = false;
                });

            },
            saveAndClose() {
                this.saveEntry(() => {
                    // EventBus.$emit('show-toast', {title: 'Entry saved successfully', message: 'You can edit it under the \'Entries\' section.'});
                    this.$router.push({name: 'entries'});
                });
            },
            saveAndContinue() {
                this.saveEntry(() => {
                    this.loadForm();
                    this.$success('Entry saved successfully', 'You can continue make changes to it.');
                });
            },
            saveEntry: function(completionFunction) {
                let data = FormUtils.flatten(this.form);
                this.busy = true;

                Providers.entries.updateEntry(this.entryId, FormUtils.mapToJson(data), this.approvalInfo, this.scopeOfPracticeInfo).then(() => {
                    this.$emit('loading-entry');
                    this.edited = false;
                    completionFunction();
                }).catch((resp) => {
                    this.$httpError('Failed to update entry', resp);
                    this.loadForm();
                }).finally(() => {
                    this.busy = false;
                });
            },
            previewEntry() {
                this.$refs.previewModal.show(this.entryId + '');
            },
            leavingRoute(to, from, next) {
                next();
                /* to, from, next */
                // if (this.edited) {
                //     this.$refs.autosaveModal.show();
                //     this.continueRoute = next;
                // } else {
                //     next();
                // }
            },
            leaveRoute() {
                if (this.continueRoute) {
                    this.continueRoute();
                }
            }

        },
        watch: {
            busy: function (newValue) {
                if (newValue === true) {
                    this.$emit('loading-started');
                } else {
                    this.$emit('loading-finished');
                }
            }
        },


    }
</script>

<style>
    .form-container label,
    .form-container legend {
        font-weight: bold;
    }
</style>

<style scoped>
    .form {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
    }

    .form-container {
        width: 100%;

    }
</style>
