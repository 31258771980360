<template>
    <div class="scope-of-practice">
        <b-form-group label="Relevant Scopes of Practice Areas">
            <div class="sop-info-inner">
                <b-overlay :show="loading">
                    <b-alert show variant="info" v-if="scopeOfPracticeInfo.items.length === 0">
                        No scopes of practice areas attached
                    </b-alert>
                    <b-table-lite table-class="light" small table-variant="primary" v-if="scopeOfPracticeInfo.items.length > 0" :fields="fields" :items="attachedScopesOfPractice" >
                        <template v-slot:cell(actions)="data">
                            <b-button size="sm" variant="dark" @click="remove(data.item.id)">Remove</b-button>
                        </template>
                    </b-table-lite>
                    <small>You can attach up to 3 scopes of practice areas</small>
                    <div class="add-new" v-if="scopeOfPracticeInfo.items.length < 3">
                        <b-form-select :options="scopesOfPractice" v-model="selectedGroup" size="sm">
                            <template v-slot:first>
                                <b-form-select-option :value="null" disabled>-- Choose a Group --</b-form-select-option>
                            </template>
                        </b-form-select>
                        <b-input-group v-if="selectedGroup" >
                            <b-form-select :options="selectedGroup" v-model="selectedSOP" size="sm">
                                <template v-slot:first>
                                    <b-form-select-option :value="null" disabled>-- Choose a Scope of Practice Area --</b-form-select-option>
                                </template>
                            </b-form-select>
                            <span class="input-group-btn">
                                <b-button variant="dark" size="sm" @click="add" :disabled="!selectedSOP">Add</b-button>
                            </span>
                        </b-input-group>
                    </div>
                </b-overlay>
            </div>
        </b-form-group>
    </div>
</template>

<script>
    import Providers from '../../providers';
    import DEFAULT_SOP from './scope-of-practice/defaultScopeOfPracticeInfo';

    export default {
        name: "ScopeOfPractice",
        props: {
            scopeOfPracticeInfo: {
                type: Object,
                required: false,
                default: () => ({...DEFAULT_SOP})
            }
        },
        data() {
            return {
                loading: false,
                scopesOfPractice: [],
                scopeOfPracticeMapping: new Map(),
                selectedGroup: null,
                selectedSOP: null,
                fields: [
                    {
                        key: 'title',
                        label: ''
                    },
                    {
                        key: 'actions',
                        label: ''
                    }
                ]
            }
        },
        computed: {
            attachedScopesOfPractice() {
                let items = [];
                for (let scopeId of this.scopeOfPracticeInfo.items) {
                    let sOP = this.scopeOfPracticeMapping.get(scopeId);
                    items.push({title: sOP.title, id: sOP.id});
                }
                return items;
            }
        },
        methods: {
            loadScopesOfPractice() {
                this.loading = true;
                Providers.information.scopesOfPractice().then((resp) => {
                    this.scopesOfPractice = resp.body.map(group => {
                        return {
                            value: group.categories.map(category => {
                                return {
                                    text: category.name,
                                    options: category.scopesOfPractice.map(scope => {
                                        return {
                                            text: scope.title,
                                            value: scope.id
                                        }
                                    })
                                }
                            }),
                            text: group.name
                        }
                    });

                    this.scopeOfPracticeMapping = new Map();
                    for (let group of resp.body) {
                        for (let category of group.categories) {
                            for (let scopeOfPractice of category.scopesOfPractice) {
                                this.scopeOfPracticeMapping.set(scopeOfPractice.id, scopeOfPractice);
                            }
                        }
                    }
                }).catch((resp) => {
                    this.$httpError('Failed to load scopes of practice areas', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            add() {
                this.scopeOfPracticeInfo.items.push(this.selectedSOP);
                this.selectedSOP = null;
                this.selectedGroup = null;
            },
            remove(id) {
                this.scopeOfPracticeInfo.items = this.scopeOfPracticeInfo.items.filter(tid => tid !== id);
            }
        },
        created() {
            this.loadScopesOfPractice();
        }
    }
</script>

<style scoped>
    .sop-info-inner {
        border-style: dashed;
        border-width: 2px;
        border-color: #BBB;
        padding: 10px;
    }
</style>