<template>
    <div class="wizard-progress">
        <ol>
            <li v-for="stage in parsedSteps" v-bind:key="stage.routeName" :class="{current: stage.active}" @click="goTo(stage)">{{stage.title}}</li>
        </ol>
        <div class="container">
            <b-row>
                <b-col cols="12">
                    <h2>{{activeStep.title}}</h2>
                </b-col>
                <b-col cols="12">
                    <slot></slot>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col cols="6">
                    <b-button variant="light" block v-if="previousStep" @click="previous">Back</b-button>
                </b-col>
                <b-col cols="6">
                    <b-button variant="primary" block v-if="nextStep" @click="next">Next</b-button>
                </b-col>
            </b-row>
        </div>

    </div>
</template>

<script>
    export default {
        name: "WizardProgress",
        props: {
            steps: {
                type: Array,
                required: true,
            }
        },
        computed: {
            parsedSteps() {
                let steps = [];
                for (let step of this.steps) {
                    let _step = {...step};
                    _step.active = this.$route.name === _step.routeName;
                    steps.push(_step);
                }
                return steps;
            },
            activeStep() {
                for (let step of this.parsedSteps) {
                    if (step.active) {
                        return step;
                    }
                }
                return null;
            },
            previousStep() {
                let previous = null;
                for (let step of this.parsedSteps) {
                    if (step.active) {
                        return previous;
                    }
                    previous = step;
                }
                return previous;
            },
            nextStep() {
                let returnNext = false;
                for (let step of this.parsedSteps) {
                    if (returnNext) {
                        return step;
                    }
                    if (step.active) {
                        returnNext = true;
                    }
                }
                return null;
            },
        },
        methods: {
            next() {
                if (this.nextStep) {
                    this.$router.push({name: this.nextStep.routeName});
                }
            },
            previous() {
                if (this.previousStep) {
                    this.$router.push({name: this.previousStep.routeName});
                }
            },
            goTo(stage) {
                if (this.activeStep !== stage) {
                    this.$router.push({name: stage.routeName});
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    $number-of-steps:  4;

    $line-width:       1px;
    $bullet-size:      2.5em;

    $background-color: $gray-400;
    $foreground-color: $gray-700;


    $active-background-color: $blue;
    $active-foreground-color: white;


    ol {
        position: relative;
        overflow: hidden;
        counter-reset: wizard;
        list-style-type: none;
    }

    li {
        position: relative;
        float: left;
        width: 100% / $number-of-steps;
        text-align: center;
        color: $active-background-color;
        list-style-type: none;
    }

    .current ~ li {
        color: $foreground-color;
    }

    li:before {
        counter-increment: wizard;
        content: counter(wizard);
        display: block;
        color: $active-foreground-color;
        background-color: $active-background-color;
        border: $line-width solid $active-background-color;
        text-align: center;
        width: $bullet-size;
        height: $bullet-size;
        line-height: $bullet-size;
        border-radius: $bullet-size;
        position: relative;
        left: 50%;
        margin-bottom: $bullet-size / 2;
        margin-left: $bullet-size * -0.5;
        z-index: 1;

        .current ~ & {
            background-color: $background-color;
            color: $foreground-color;
            border-color: $foreground-color;
        }
    }

    li + li {
        &:after{
            content: "";
            display: block;
            width: 100%;
            background-color: $active-background-color;
            height: $line-width;
            position: absolute;
            left: -50%;
            top: $bullet-size / 2;
            z-index: 0;
        }
    }

    .current ~ li:after {
        background-color: $foreground-color;
    }


</style>