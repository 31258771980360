<template>
    <div class="edit-pdp-objective-modal">
        <b-modal title="Update Objective" header-bg-variant="primary" header-text-variant="light"
                 no-close-on-esc no-close-on-backdrop @ok="update" ref="modal" size="lg">
            <b-overlay :show="loading">
                <PDPObjectiveInformation :objective="objective" v-if="objective" />
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
    import PDPObjectiveInformation from "./PDPObjectiveInformation";
    import Providers from '../../providers';

    export default {
        name: "EditPDPObjectiveModal",
        components: {PDPObjectiveInformation},
        data() {
            return {
                objective: null,
                loading: false,
            }
        },
        methods: {
            update($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.profile.updateObjective(this.objective).then(() => {
                    this.$success('Objective Updated', 'The objective has been updated successfully');
                    this.$emit('objective-updated');
                    this.close();
                }).catch((resp) => {
                    this.$httpError('Failed to update Objective', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            show(objective) {
                this.objective = {...objective};
                this.$refs.modal.show();
            },
            close() {
                this.objective = null;
                this.$refs.modal.hide();
            }
        }
    }
</script>

<style scoped>

</style>