import Vue from "vue";

export default {
    availableTraining() {
        return Vue.http.get('training');
    },
    mandatoryTraining() {
        return Vue.http.get('training/log/mandatory');
    },
    nonMandatoryTraining() {
        return Vue.http.get('training/log/non-mandatory');
    },
    logTraining(trainingItem, dateCompleted, description, cceHours, attachments, approvalInfo, scopeOfPracticeInfo) {
        return Vue.http.post('training/log', {trainingItem, dateCompleted, description, cceHours, attachments, approvalInfo, scopeOfPracticeInfo});
    },
    statistics() {
        return Vue.http.get('training/statistics');
    }
}
