<template>
    <b-col cols="4" class="no-padding">
        <li class="nav-item" id="hello" v-b-tooltip.hover.bottom="popupTitle">
            <router-link :to="destination" class="nav-link"  @click.native="clicked">
                <div class="nav-icon">
                    <img src="../../assets/menu-icon.png" class="nav-icon"  :alt="title"/>
                    <div class="nav-icon-icon">
                        <img :src="iconImage"  :alt="title"/>
                    </div>
                    <div class="nav-icon-content" :class="{'hide-content': hideTitles}">
                        <span class="icon-title">{{title}}</span>
                    </div>
                </div>
            </router-link>
        </li>
    </b-col>
</template>

<script>

    import EventBus from "../../event-bus";

    export default {
        name: "SidebarMenuItem",
        components: {},
        props: {
            routerLink: {
                type: String,
                required: false
            },
            url: {
                type: String,
                required: false,
                defaultValue: null
            },
            title: {
                type: String,
                required: true
            },
            icon: {
                type: String,
                required: true
            },
            hideTitles: {
                type: Boolean,
                required: false,
                defaultValue: true
            }
        },
        computed: {
            popupTitle() {
                return this.hideTitles ? this.title : '';
            },
            iconImage() {
                return `/images/icons/icon-${this.icon}.png`;
            },
            destination() {
                return {name: this.routerLink}
            }
        },
        methods: {
            clicked() {
                EventBus.$emit('sidebar-item-clicked');
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '~cpdcloud-dashboard/src/assets/variables';

    .nav-link:hover .icon,
    .nav-link.active .icon {
        color: inherit;
    }

    .nav-link {
        font-weight: bold;
        color: #333;
        cursor: pointer;
        padding: 0;
        font-size: 9px;
        text-align: center;
    }

    .nav-link .icon {
        margin-right: 4px;
        color: #999;
    }

    .nav-link img.icon-large {
        width: 100%;
    }

    .nav-link img.icon-large:hover {
        filter: brightness(90%);
    }
    div.nav-icon {
        width: 100%;
        background-color: $gray-700;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }

    div.nav-icon:hover {
        background-color: $gray-800;
    }

    div.nav-icon img {
        width: 100%;
    }

    div.no-padding {
        padding: 5px;
    }

    div.nav-icon-content {
        /*float: left;*/
        padding: 10px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        width: 100%;

    }

    div.nav-icon-content span.icon-title {
        position: absolute;
        bottom: 10px;
        right: 5px;
        left: 5px;
        color: white;
    }

    a.router-link-active.active div.nav-icon {
        background-color: $primary;
    }

    div.nav-icon-icon img {
        max-width: 35px;
        margin-top: 15px;
        filter: grayscale(80%);
    }

    div.nav-icon-icon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }



    @media screen and (max-width: 1400px) {
        div.nav-icon-content.hide-content {
            display: none;
            margin-top: 0px;
        }
    }

</style>