<template>
    <div class="authentication">
        <div class="logo"></div>
        <router-view></router-view>
        <Footer :show-divider="false" />
    </div>
</template>

<script>
    import Footer from "../common/Footer";
    export default {
        name: "AuthenticationPage",
        components: {Footer}
    }
</script>

<style scoped>
.logo {
    background-image: url('/images/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 320px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px
}
</style>