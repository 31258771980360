<template>
    <div class="available-training">
        <h2>Training Log</h2>
        <b-card no-body>
            <b-tabs lazy card fill>
                <b-tab title="Mandatory Training"><TrainingList :items="mandatoryTraining"/></b-tab>
                <b-tab title="Optional Training"><TrainingList :items="nonMandatoryTraining"/></b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
    import Providers from '../../providers/index';
    import TrainingList from "./TrainingList";
    export default {
        name: "TrainingLog",
        components: {TrainingList},
        data() {
            return {
                mandatoryTraining: [],
                nonMandatoryTraining: []
            }
        },
        methods: {
            loadMandatoryTraining() {
                Providers.training.mandatoryTraining().then((resp) => {
                    this.mandatoryTraining = resp.body;
                });
            },
            loadNonMandatoryTraining() {
                Providers.training.nonMandatoryTraining().then((resp) => {
                    this.nonMandatoryTraining = resp.body;
                });
            },
            refresh() {
                this.loadMandatoryTraining();
                this.loadNonMandatoryTraining();
            }
        },
        created() {
            this.refresh();
        }
    }
</script>

<style scoped>

</style>