import Vue from "vue";

export default {
    /*
        When implementing short access tokens & refresh tokens, use an interceptor and block processing until the re-auth occurs:
        https://github.com/pagekit/vue-resource/issues/224
     */
    login(username, password, totp = null) {
        return Vue.http.post('auth/login', {email: username, password: password, totp: totp})
    },
    activateAccount(activationToken, password) {
        return Vue.http.post(`auth/activate/${activationToken}`, {password: password});
    },
    requestPasswordReset(email) {
        return Vue.http.post(`auth/password-reset`, {email: email});
    },
    updatePassword(token, password) {
        return Vue.http.post(`auth/password-reset/${token}`, {password});
    },
    changePassword(oldPassword, newPassword) {
        return Vue.http.post(`auth/change-password`, {oldPassword, newPassword});
    },
    loginAs(userId) {
        return Vue.http.post(`auth/login/${userId}`);
    },
    send2FA(username, password, type) {
        return Vue.http.post(`auth/login/2fa-send?type=${type}`, {email: username, password: password});
    },
    get2FAOptions(username, password) {
        return Vue.http.post(`auth/login/2fa-options`, {email: username, password: password})
    },
    has2FA() {
        return Vue.http.get('auth/2fa-status').then(() => {
            return true;
        }).catch(() => {
            return false;
        });
    },
    enable2FA() {
        return Vue.http.post('auth/enable-2fa');
    }
}
