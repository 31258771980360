import Vue from 'vue'

export default {
    create(request) {
        return Vue.http.post('portfolio-builder', request, {
            responseType: 'blob'
        }).then(response => {
            let result = document.createElement('a');
            let contentDisposition = response.headers.get('Content-Disposition') || '';
            let filename;
            if (contentDisposition) {
                filename = contentDisposition.split('filename=')[1];
                filename = filename.replace(/"/g, "")
            } else {
                filename = "portfolio.pdf";
            }

            return response.blob()
                .then(function(data) {
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(data, filename);
                        return null;
                    } else {
                        result.href = window.URL.createObjectURL(data);
                        result.target = '_self';
                        result.download = filename;
                        return result;
                    }
                });
        }).then(link => {
            return () => link.click();
        });
    }
}