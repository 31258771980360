<template>
    <div class="clinical-skills">
        <b-row>
            <b-col md="4">
                <Guidance />
            </b-col>
            <b-col md="8">
                <Statistics />
            </b-col>
        </b-row>

        <b-row class="mt-4">
            <b-col cols="12">
                <b-button variant="primary" @click="logSkill" v-requires-role="'User'"><PlusIcon /> Add Skills</b-button>
                <b-button variant="outline-secondary" class="ml-2" @click="manageCompetencies"><FormatListBulletedIcon /> Manage Additional Competencies</b-button>

            </b-col>
        </b-row>

        <b-row class="mt-4">
            <b-col cols="12">
                <ClinicalSkillsTable ref="clinicalSkillsLog"/>
            </b-col>
        </b-row>
        <LogClinicalSkillModal ref="logSkillModal" @skills-added="loadSkillsLog"/>
        <ManageAdditionalCompetenciesModal ref="manageAdditionalCompetenciesModal" />
    </div>
</template>

<script>
    import PlusIcon from 'vue-material-design-icons/Plus';
    import FormatListBulletedIcon from 'vue-material-design-icons/FormatListBulleted';
    import ClinicalSkillsTable from "../components/clinical-skills/ClinicalSkillsTable";
    import LogClinicalSkillModal from "../components/clinical-skills/LogClinicalSkillModal";
    import ManageAdditionalCompetenciesModal from "../components/clinical-skills/ManageAdditionalCompetenciesModal";
    import Guidance from '../components/clinical-skills/Guidance';
    import Statistics from "../components/clinical-skills/Statistics";

    export default {
        name: "ClinicalSkills",
        components: {
            Statistics,
            ManageAdditionalCompetenciesModal,
            LogClinicalSkillModal,
            ClinicalSkillsTable,  PlusIcon, FormatListBulletedIcon, Guidance},
        data() {
            return {

            }
        },
        methods: {
            logSkill() {
                this.$refs.logSkillModal.show();
            },
            loadSkillsLog() {
                this.$refs.clinicalSkillsLog.refresh();
            },
            manageCompetencies() {
                this.$refs.manageAdditionalCompetenciesModal.show();
            }
        }

        /*
        this.graphData = {
                        cpdCategories: {
                            datasets: [
                                {
                                    data: cpdDataPoints,
                                    backgroundColor: this.pieColors
                                }
                            ],
                            labels: cpdCategories
                        },
                        activityArranged: {
                            datasets: [
                                {
                                    data: arrangedDataPoints,
                                    backgroundColor: this.pieColors
                                }
                            ],
                            labels: arrangedCategories
                        }
                    };
         */

    }
</script>

<style scoped>

</style>