<template>
    <b-form-group :label="item.title" :description="item.helpText">
        <ChooseFiles v-model="item.value" />
    </b-form-group>
</template>

<script>
    // import EventBus from '../../event-bus';
    import FormItem from "./FormItem";
    import ChooseFiles from "../common/ChooseFiles";

    export default {
        name: "UploadFormItem",
        components: {ChooseFiles},
        extends: FormItem,
        data: function() {
            return {
                selectedFile: null,
                uploadedItems: [],
                uploading: false,
                uploadPercentage: 0,
                unassignedFiles: [],
                selectedUnassignedFile: null
            }
        },
        methods: {
            openFile(storageId) {
                this.$emit('open-file', storageId);
            },
            updateItemValue: function() {
                this.item.value = this.uploadedItems.map(v => v);
            },

        },
        watch: {
            uploadedItems: function () {
                this.updateItemValue();
            }
        },
        mounted() {
            if (this.item.value && this.item.value.length > 0) {
                this.uploadedItems = this.item.value.map(item => {
                    return { id : item.id, name: item.name };
                });
            }
            this.downloadUnassignedFiles();
        }
    }
</script>

<style scoped>

</style>
