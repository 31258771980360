<template>
    <div class="page">
        <label v-if="this.allCheckboxes()">{{page.title}}</label>
        <div id="form-item" v-for="item in activatedItems" :key="item.identifier">
            <FreeTextFormItem :item="item" v-if="itemActivated(item, 1)" />
            <LongTextFormItem :item="item" v-else-if="itemActivated(item, 2)" />
            <DropdownFormItem :item="item" v-else-if="itemActivated(item, 4)" />
            <div class="hours" v-else-if="itemActivated(item, 5) && item.title === 'Number of Hours'" >
                <HoursFormItem :item="item" v-if="item.value <= 24"/>
                <FreeTextFormItem :item="item" :type="'number'" v-else />
            </div>
            <FreeTextFormItem :item="item" type="number" v-else-if="itemActivated(item, 5)" />
            <CheckboxFormItem :item="item" v-else-if="itemActivated(item, 7)" />
            <DateFormItem     :item="item" v-else-if="itemActivated(item, 9)" />
            <FreeTextFormItem :item="item" type="url" v-else-if="itemActivated(item, 10)" />
            <UploadFormItem   :item="item" v-else-if="itemActivated(item, 8)" @open-file="openFile"/>
            <p v-else>Not implemented: {{item.title}} (Type: {{item.type}})</p>
        </div>
    </div>
</template>

<script>
    import FreeTextFormItem from './FreeTextFormItem'
    import DropdownFormItem from "./DropdownFormItem";
    import LongTextFormItem from "./LongTextFormItem";
    import DateFormItem from "./DateFormItem";
    import CheckboxFormItem from "./CheckboxFormItem";
    import UploadFormItem from "./UploadFormItem";
    import HoursFormItem from "./HoursFormItem";

    import FormUtils from '../../utilities/FormUtils';

    export default {
        name: "FormPage",
        props: ['page', 'formData'],
        computed: {
            activatedItems: function() {
                return FormUtils.activatedItems(this.page);
            }
        },
        methods: {
            allCheckboxes: function() {
                let allCheckboxes = true;
                for (let i = 0; i < this.page.items.length; i++) {
                    if (this.page.items[i].type === 7) {
                        allCheckboxes = allCheckboxes && true;
                    } else {
                        allCheckboxes = false;
                    }
                }
                return allCheckboxes;
            },
            itemActivated: function(item, expectedType) {
                return item.type === expectedType;

            },
            isEmptyObject: function(obj) {
                for(var prop in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                        return false;
                    }
                }
                return true;
            },
            openFile($e) {
                this.$emit('open-file', $e);
            }
        },
        components: {
            HoursFormItem,
            UploadFormItem,
            CheckboxFormItem, DateFormItem, LongTextFormItem, DropdownFormItem, FreeTextFormItem}
    }
</script>

<style scoped>
label, legend {
    font-weight: bold !important;
}
</style>