<template>
    <div class="approval-info">
        <b-form-group label="Approval">
            <div class="approval-info-inner">
                <b-form-checkbox v-model="approvalInfo.required" v-if="!noConfirmation">
                    Does this entry require approval?
                </b-form-checkbox>
                <div class="approval-required mt-2" v-if="approvalInfo.required || noConfirmation">
                    <b-form-group label="Approver Type" v-if="allowExternal">
                        <b-form-radio-group block size="sm" buttons button-variant="outline-primary" v-model="approvalInfo.approverType" :options="approverOptions" />
                    </b-form-group>

                    <b-form-group label="Mentor" v-if="approvalInfo.approverType === 'Mentor'">
                        <b-form-select :options="managers" v-model="approvalInfo.mentor">
                            <template v-slot:first>
                                <b-form-select-option :value="null" disabled>-- Please select --</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                    <div v-if="approvalInfo.approverType === 'Clinician'">
                        <b-form-group label="Clinician - Full Name">
                            <b-form-input type="text" autocomplete="off" v-model="approvalInfo.clinicianName" />
                        </b-form-group>

                        <b-form-group label="Clinician - Job Title">
                            <b-form-input type="text" autocomplete="off" v-model="approvalInfo.clinicianJobTitle"/>
                        </b-form-group>

                        <b-form-group label="Clinician - Registration Number">
                            <b-form-input type="text" autocomplete="off" v-model="approvalInfo.clinicianRegistrationNumber"/>
                        </b-form-group>

                        <b-form-group label="Clinician - Email Address">
                            <b-form-input type="email" autocomplete="off" v-model="approvalInfo.clinicianEmail" />
                        </b-form-group>
                    </div>
                    <div v-if="approvalInfo.approverType === 'OtherSupervisor'">
                        <b-form-group label="Full Name">
                            <b-form-input type="text" autocomplete="off" v-model="approvalInfo.supervisorName" />
                        </b-form-group>

                        <b-form-group label="Job Title">
                            <b-form-input type="text" autocomplete="off" v-model="approvalInfo.supervisorJobTitle"/>
                        </b-form-group>

                        <b-form-group label="Email Address">
                            <b-form-input type="email" autocomplete="off" v-model="approvalInfo.supervisorEmail" />
                        </b-form-group>
                    </div>

                </div>
            </div>
        </b-form-group>
    </div>
</template>

<script>
    import Providers from '../../providers/index';
    import DEFAULT_APPROVAL_INFO from './defaultApprovalInfo';

    export default {
        name: "ApprovalInfo",
        props: {
            allowExternal: {
                type: Boolean,
                required: false,
                defaultValue: false
            },
            noConfirmation: {
                type: Boolean,
                required: false,
                defaultValue: false
            },
            approvalInfo: {
                type: Object,
                required: false,
                default: () => ({...DEFAULT_APPROVAL_INFO})
            }
        },
        watch: {
            approvalInfo() {
                this.setApproverTypeIfMissing()
            }
        },
        data() {
            return {
                loading: false,
                managers: [],

            }
        },
        computed: {
            approverOptions() {
                if (this.allowExternal) {
                    return [
                        {
                            value: 'Mentor',
                            text: 'Mentor'
                        },
                        {
                            value: 'Clinician',
                            text: 'Clinician'
                        },
                        {
                            value: 'OtherSupervisor',
                            text: 'Other Supervisor'
                        }
                    ];
                }
                return ['Mentor'];
            }
        },
        methods: {
            loadManagers() {
                this.loading = true;
                Providers.entries.mentors().then((resp) => {
                    this.managers = resp.body.managers.map(m => {
                        return {
                            value: m.userId,
                            text: m.name
                        }
                    });
                }).catch((resp) => {
                    this.$httpError('Failed to load managers', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            setApproverTypeIfMissing() {
                if (!this.approvalInfo.approverType || this.approvalInfo.approverType === 'SelfCertify') {
                    this.approvalInfo.approverType = 'Mentor';
                }
                this.approvalInfo.required = true;
            }
        },
        created() {
            this.loadManagers();
        },
        mounted() {
            if (this.noConfirmation) {
                this.approvalInfo.required = true;
            }
            this.setApproverTypeIfMissing();
        }
    }
</script>

<style scoped lang="scss">
    @import '../../assets/css/custom.scss';


    .approval-info-inner {
        border-style: dashed;
        border-width: 2px;
        border-color: #BBB;
        padding: 10px;
    }
</style>
