<template>
    <div>
        <p v-html="formattedString"></p>
        <div>
            <span v-show="text.length > maxChars">
                <b-button variant="outline-dark" size="sm" v-show="!isReadMore" @click="triggerReadMore($event, true)">{{moreStr}}</b-button>
                <b-button variant="outline-dark" size="sm" v-show="isReadMore" @click="triggerReadMore($event, false)">{{lessStr}}</b-button>
            </span>
            <b-button :variant="actionBtnVariant" v-if="this.actionTitle" size="sm" @click="this.actionCallback" :disabled="actionDisabled">{{this.actionTitle}}</b-button>
        </div>
    </div>
</template>

<script>
    export default{
        name: 'ReadMore',
        props: {
            actionTitle: {
                type: String,
                default: null
            },
            actionCallback: {
                type: Function,
                default: () => {

                }
            },
            actionDisabled: {
                type: Boolean,
                default: false
            },
            moreStr: {
                type: String,
                default: 'See more...'
            },
            lessStr: {
                type: String,
                default: 'Show less'
            },
            text: {
                type: String,
                required: true
            },
            link: {
                type: String,
                default: '#'
            },
            maxChars: {
                type: Number,
                default: 100
            }
        },

        data (){
            return{
                isReadMore: false
            }
        },

        computed: {
            formattedString(){
                var val_container = this.text;

                if(!this.isReadMore && this.text.length > this.maxChars){
                    val_container = val_container.substring(0,this.maxChars) + '...';
                }

                return(val_container);
            },
            actionBtnVariant() {
                if (this.actionDisabled) {
                    return 'outline-dark';
                }
                return 'outline-info';
            }
        },

        methods: {
            triggerReadMore(e, b){
                if(this.lessStr !== null || this.lessStr !== '')
                    this.isReadMore = b;
            }
        }
    }
</script>

<style scoped>
    .btn {
        margin-right: 10px;
    }
</style>