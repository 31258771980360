<template>
    <div class="add-clinical-exposure-modal">
        <b-modal ref="modal" header-bg-variant="primary" header-text-variant="light" @ok="add" title="Add Clinical Exposure"
                 size="lg" no-close-on-esc no-close-on-backdrop :ok-disabled="loading" :cancel-disabled="loading">
            <b-overlay :show="loading">
                <b-form-group label="Type">
                    <b-form-radio-group buttons :options="types" v-model="request.type" style="width: 100%" button-variant="outline-primary">

                    </b-form-radio-group>
                </b-form-group>
                <div class="type-dpc" v-if="request.type === 'dpc'">
                    <b-form-group label="Title">
                        <b-form-input type="text" autocomplete="off" v-model="request.title" />
                    </b-form-group>
                </div>
                <div class="type-ets" v-if="request.type === 'ets'">
                    <b-form-group label="Training">
                        <b-form-select :options="availableTraining" v-model="request.trainingItem">
                            <template v-slot:first>
                                <b-form-select-option disabled :value="null">-- Please Select --</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </div>
                <b-form-group label="Date">
                    <b-form-datepicker v-model="request.dateCompleted" />
                </b-form-group>
                <b-form-group label="Description">
                    <b-form-textarea rows="5" />
                </b-form-group>
                <b-form-group label="CCE Hours">
                    <b-form-spinbutton min="0" step="0.5" v-model="request.cceHours" />
                </b-form-group>

                <b-form-group label="CPD Hours"  v-if="request.type === 'ets'">
                    <b-form-spinbutton min="0" step="0.5" v-model="request.cpdHours" />
                </b-form-group>
                <ChooseEntry v-if="request.type === 'dpc'" title="Link a Reflection" v-model="request.linkedReflection">
                    <small>(Optional) You can link a reflection to support this entry. The entry will be visible by the approver chosen.</small>
                </ChooseEntry>

                <ScopeOfPractice :scope-of-practice-info="request.scopeOfPracticeInfo" />
                <ApprovalInfo allow-external no-confirmation :approval-info="request.approvalInfo" />
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
    import DEFAULT_APPROVAL_INFO from "../forms/defaultApprovalInfo";
    import ApprovalInfo from "../forms/ApprovalInfo";
    import Providers from "../../providers";
    import ScopeOfPractice from "../forms/ScopeOfPractice";
    import ChooseEntry from "../entries/ChooseEntry";

    export default {
        name: "AddClinicalExposureModal",
        components: {ChooseEntry, ScopeOfPractice, ApprovalInfo},
        data() {
            return {
                loading: false,
                availableTraining: [],
                request: {
                    type: 'dpc',
                    trainingItem: null,
                    title: '',
                    dateCompleted: null,
                    description: '',
                    cceHours: 0,
                    cpdHours: 0,
                    linkedReflection: null,
                    approvalInfo: {...DEFAULT_APPROVAL_INFO},
                    scopeOfPracticeInfo: {items: []}
                },
                types: [
                    {
                        value: 'dpc',
                        text: 'Patient Contact / Placement'
                    },
                    {
                        value: 'ets',
                        text: 'Simulation Training'
                    }
                ]
            }
        },
        methods: {
            add(e) {
                e.preventDefault();
                this.loading = true;
                Providers.clinicalExposure.create(this.request).then(() => {
                    this.$success('Entry Created', 'Your entry has been created successfully');
                    this.$emit('clinical-exposure-added');
                    this.hide();
                }).catch((resp) => {
                    this.$httpError('Failed to create entry', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            hide() {
                this.$refs.modal.hide();
            },
            show() {
                this.reset();
                this.$refs.modal.show();
            },
            reset() {
                this.request = {
                    type: 'dpc',
                    trainingItem: null,
                    title: '',
                    dateCompleted: null,
                    description: '',
                    cceHours: 0,
                    cpdHours: 0,
                    linkedReflection: null,
                    approvalInfo: {...DEFAULT_APPROVAL_INFO},
                    scopeOfPracticeInfo: {items: []}
                };
            },
            loadTraining() {
                this.loading = true;
                Providers.training.availableTraining().then((resp) => {
                    this.availableTraining = resp.body.map(ti => { return { value: ti.id, text: ti.title }});
                }).catch((resp) => {
                    this.$httpError('Failed to load training', resp);
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        created() {
            this.loadTraining();
        }
    }
</script>

<style scoped>

</style>