<template>
    <div class="mandatory-training-guidance">
        <b-card title="Guidance">
            <p>This section allows you to show that you have completed the mandatory training and professional courses required by your employer. Links to the training are available where possible.
                Courses are split into those which are required and those which remain optional but are relevant to your role.</p>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: "Guidance"
    }
</script>

<style scoped>

</style>