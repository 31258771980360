<template>
    <div class="password-reset">
        <PasswordResetFinishPage :loading="loading" @update-password="updatePassword" />
    </div>
</template>

<script>
    import Providers from '../../providers';
    import PasswordResetFinishPage from 'cpdcloud-dashboard/src/components/authentication/PasswordResetFinishPage';

    export default {
        name: "FinishPasswordReset",
        components: {PasswordResetFinishPage},
        data() {
            return {
                loading: false,
                resetToken: ''
            }
        },
        methods: {
            updatePassword({password}) {
                this.loading = true;
                Providers.authentication.updatePassword(this.resetToken, password).then(() => {
                    this.$success('Password Changed', 'Your password has been updated successfully. Please login with your new details.');
                    this.$router.push({name: 'login'});
                }).catch((resp) => {
                    this.$httpError('Failed to update password', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        created() {
            this.resetToken = this.$route.params.token;
        }
    }
</script>

<style scoped>

</style>