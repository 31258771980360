<template>
    <div class="pdp-objective">
        <b-form-group label="Objective">
            <b-textarea rows="4" v-model="objective.objective">

            </b-textarea>
        </b-form-group>
        <b-form-group label="How will I achieve this?">
            <b-textarea rows="4" v-model="objective.achieve">

            </b-textarea>
        </b-form-group>
        <b-form-group label="How will I demonstrate completion?">
            <b-textarea rows="4" v-model="objective.demonstrate">

            </b-textarea>
        </b-form-group>
        <b-form-group label="When by?">
            <b-form-datepicker v-model="objective.completeBy" />
        </b-form-group>
    </div>
</template>

<script>
    import DEFAULT_OBJECTIVE from './defaults/defaultPDPObjective';

    export default {
        name: "PDPObjectiveInformation",
        props: {
            objective: {
                type: Object,
                required: false,
                default: () => ({...DEFAULT_OBJECTIVE})
            }
        },
        data() {
            return {}
        }
    }
</script>

<style scoped>

</style>