<template>
    <div class="choose-entry-table">
        <b-overlay :show="loading">

            <b-table ref="table" :fields="fields" :items="entries" table-class="light" @row-selected="rowSelected"
                     selectable selected-variant="success" select-mode="multi">
                <template v-slot:table-colgroup="scope">
                    <col v-for="field in scope.fields"
                            :key="field.key"
                            :style="{ 'width': field.key === 'selected' ? '50px' : 'auto' }">
                </template>
                <template v-slot:cell(selected)="{ rowSelected }" style="max-width: 50px">
                    <CheckIcon v-if="rowSelected" />
                </template>
            </b-table>
            <div class="actions">
                <b-button variant="light" class="text-right mt-2" size="sm" @click="selectAll"><CheckboxMarkedOutlineIcon /> Select All</b-button>
                <b-button variant="light" class="text-right mt-2" size="sm" @click="deselectAll"><CheckboxBlankOutlineIcon /> Deselect All</b-button>
            </div>

        </b-overlay>
    </div>
</template>

<script>
    import Providers from '../../providers';
    import CheckIcon from 'vue-material-design-icons/Check';
    import CheckboxBlankOutlineIcon from 'vue-material-design-icons/CheckboxBlankOutline';
    import CheckboxMarkedOutlineIcon from 'vue-material-design-icons/CheckboxMarkedOutline';
    const FIELDS = [
        {
            key: 'selected',
            label: '',
            class: 'selected-column'
        },
        {
            key: 'startDate',
            label: 'Date'
        },
        {
            key: 'title'
        }
    ]

    export default {
        name: "ChooseEntryTable",
        components: {CheckIcon, CheckboxBlankOutlineIcon, CheckboxMarkedOutlineIcon},
        props: {
            startDate: {
                type: String,
                required: true,
            },
            endDate: {
                type: String,
                required: true,
            },
            value: {
                type: Array,
                required: true
            },
            type: {
                type: String,
                required: false,
                default: 'Diary'
            }
        },
        data() {
            return {
                loading: false,
                fields: FIELDS,
                entries: []
            }
        },
        computed: {
            entryFilter() {
                return {type: this.type, from: this.startDate, to: this.endDate, state: 'Approved'}
            },
        },
        methods: {
            loadEntries() {
                this.loading = true;
                Providers.entries.entries(1, 100, this.entryFilter).then((resp) => {
                    this.entries = resp.body.items;
                }).catch((resp) => {
                    this.$httpError('Failed to load entries', resp);
                }).finally(() => {
                    this.loading = false;
                    this.selectAll();
                })
            },
            rowSelected(val) {
                this.$emit('input', val.map(entry => entry.entryId));
            },
            selectAll() {
                this.$refs.table.selectAllRows();
            },
            deselectAll() {
                this.$refs.table.clearSelected();
            }
        },
        created() {
            this.loadEntries();
        }
    }
</script>

<style scoped>
    div.actions > * {
        margin-right: 4px;
    }
    .selected-column {
        max-width: 50px;
    }
</style>