import userManagement from './user-management';
import configuration from "./configuration";
import activityLog from './activity-log';
import sharedCPD from './shared-cpd';

export default {
    configuration: configuration,
    userManagement: userManagement,
    activityLog: activityLog,
    sharedCPD: sharedCPD,
}