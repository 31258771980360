<template>
    <div class="share-cpd-modal">

        <b-modal class="cpd-modal" scrollable title="Share your CPD" ref="modal" size="lg" okTitle="Share"
                 okVariant="info" :okDisabled="!formState" header-bg-variant="info" header-text-variant="light"
                 @ok="share" no-close-on-backdrop no-close-on-esc>

            <p>Would you like to share this CPD with other users of the DMS system? Here's a preview of what other users will be able to see:</p>

            <SharedCPDListItem :title="sharedCpd.title"
                               :description="sharedCpd.description"
                               :avatar-url="profileImageUrl"
                               :nickname="nickname"
                               :category="sharedCpd.category"
                               :description-as-readmore="false"
                               :website-url="sharedCpd.websiteUrl"
                               date="Unpublished"/>

            <b-form-group class="mt-3" label="Category *" required>
                <b-form-select v-model="sharedCpd.category" :options="categories"></b-form-select>
            </b-form-group>

            <b-form-group label="Title *" label-for="title" :invalid-feedback="titleValid" :state="titleState" required>
                <b-form-input id="title" v-model="sharedCpd.title" type="text" :state="titleState" trim autocomplete="off"/>
            </b-form-group>

            <b-form-group class="mt-3" label="Description *" :invalid-feedback="descriptionValid" :state="descriptionState" required>
                <b-textarea v-model="sharedCpd.description" :state="descriptionState"></b-textarea>
                <small>{{ sharedCpd.description.length }} / 400 characters</small>
            </b-form-group>

            <b-form-group label="Website URL" label-for="website-url" :invalid-feedback="websiteUrlValid" :state="websiteUrlState" >
                <b-form-input id="website-url" v-model="sharedCpd.websiteUrl" type="text" :state="websiteUrlState" trim autocomplete="off"/>
            </b-form-group>

            <b-checkbox v-model="agree" class="mt-4 confidential">I agree that the above does not contain any sensitive information, and I agree to share it with other users of the platform.</b-checkbox>

        </b-modal>

    </div>
</template>

<script>
    import SharedCPDListItem from "./SharedCPDListItem";
    import Providers from '../../providers';

    export default {
        name: "ShareCPDModal",
        components: {SharedCPDListItem},
        data() {
            return {
                loading: false,
                agree: false,
                entryId: null,
                nickname: 'Loading...',
                categories: [],
                sharedCpd: {
                    title: '',
                    description: '',
                    category: '',
                    websiteUrl: ''
                }
            }
        },
        methods: {
            reset() {
                this.agree = false;
                this.categories = [
                    'Business Management and Admin',
                    'Clinical Education',
                    'Coaching and Mentoring',
                    'Computer Science and Technology',
                    'Financial Management and Admin',
                    'Health, Safety and Security',
                    'Leadership',
                    'Mandatory Training',
                    'Management',
                    'Trauma Care',
                    'Wellbeing',
                    'Other'
                ];
            },
            show(diaryEntry) {
                this.reset();
                this.entryId = diaryEntry.entryId;
                this.sharedCpd.title = diaryEntry.title;
                this.sharedCpd.category = null;
                this.sharedCpd.description = '';
                this.sharedCpd.websiteUrl = '';
                this.categories.sort();
                this.$refs.modal.show();
            },
            share($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.sharedCPD.share(this.entryId, this.sharedCpd).then((resp) => {
                    this.$success('Shared CPD', resp.body.message);
                    this.$emit('cpd-shared');
                    this.$refs.modal.hide();
                }).catch((resp) => {
                    this.$httpError('Failed to share', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            validURL(str) {
                var pattern = new RegExp('^https?:\\/\\/'+ // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
                return !!pattern.test(str);
            }
        },
        computed: {
            profileImageUrl() {
                let baseUrl = process.env.VUE_APP_API_ROOT;
                let authToken = this.$store.state.authentication.loginToken;
                return `${baseUrl}profile/image?auth_token=${authToken}`
            },
            websiteUrlValid() {
                if (this.websiteUrlState) {
                    return ''
                }
                return 'Please enter a valid website address, starting with http(s)://';
            },
            websiteUrlState() {
                return this.sharedCpd.websiteUrl === '' || this.validURL(this.sharedCpd.websiteUrl);
            },
            titleValid() {
                if (this.titleState) {
                    return '';
                }
                return 'A title is required'
            },
            titleState() {
                return this.sharedCpd.title && this.sharedCpd.title.trim() !== ''
            },
            descriptionValid() {
                if (this.descriptionState) {
                    return '';
                }
                if (this.sharedCpd.description && this.sharedCpd.description.length > 400) {
                    return 'The description is too long.'
                }
                return 'A description is required'
            },
            descriptionState() {
                return this.sharedCpd.description && this.sharedCpd.description.length <= 400;
            },
            formState() {
                return this.agree && this.titleState && this.descriptionState && this.websiteUrlState;
            }
        },
        created() {
            this.$http.get('profile').then((resp) => {
                if (resp.body.nickname) {
                    this.nickname = resp.body.nickname;
                } else {
                    this.nickname = resp.body.firstName;
                }
            })
        }
    }
</script>

<style scoped>

</style>