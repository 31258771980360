<template>
    <b-form-group :label="item.title" :description="item.helpText">
       <Datepicker v-model="dateValue" :bootstrap-styling="true" :clear-button="true" :format="dateFormat" />
    </b-form-group>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import FormItem from './FormItem'

    export default {
        name: "DateFormItem",
        extends: FormItem,
        data: function() {
            return {
                dateFormat: 'dd/MM/yyyy',
                dateValue: null,
                moment: require('moment')
            }
        },
        watch: {
            dateValue: function(newValue) {
                if (newValue) {
                    this.item.value = this.moment(newValue).format('YYYY-MM-DD');
                }
            }
        },
        components: {Datepicker},
        created: function() {
            if (this.item.value != null && this.item.value !== '' && this.item.value.length === 10) {
                this.dateValue = this.moment(this.item.value, 'YYYY-MM-DD').toDate();
            }
        }
    }
</script>

<style scoped>
    label, legend.col-form-label {
        font-weight: bold;
    }
</style>