<template>
    <div>
        <b-row>
            <b-col sm="12" >
                <div class="uploaded-files">
                    <span class="uploaded-nofiles" v-if="uploadedItems.length === 0">No files uploaded</span>
                    <div class="evidence-item" :key="idx" v-for="(e, idx) of this.uploadedItems">
                        <b-dropdown size="xs" variant="outline-dark" class="mx-1" right :text="e.name" :title="e.name">
                            <b-dropdown-item @click="openFile(e.id)" target="_blank">Open</b-dropdown-item>
                            <b-dropdown-item @click="deleteFile(e)" class="dropdown-danger">Remove</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </b-col>
        </b-row>

        <b-row v-if="uploading">
            <b-col sm="12" >
                <b-progress :value="uploadPercentage" :max="100" variant="info" show-progress animated />
            </b-col>
        </b-row>
        <b-row v-if="unassignedFiles.length > 0">
            <b-col sm="10">
                <b-form-select v-model="selectedUnassignedFile">
                    <option :value="null">-- Select Evidence from My Files --</option>
                    <option v-for="file in unassignedFiles" v-bind:key="file.id" :value="file">{{file.filename}}</option>
                </b-form-select>
            </b-col>
            <b-col sm="2">
                <b-button variant="dark" @click="attachUnassignedFile" :block="true" :disabled="selectedUnassignedFile == null">
                    <span class="ready" >Attach</span>
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="10">
                <b-form-file ref="fileInput" v-model="selectedFile" />
            </b-col>
            <b-col sm="2">
                <b-button variant="dark" @click="uploadFile" :block="true" :disabled="selectedFile == null">
                    <span class="loading"  v-if="uploading"><moon-loader class="loader" size="20px" color="white"></moon-loader></span>
                    <span class="ready" v-else>Upload</span>
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "ChooseFiles",
        props: {
            value: {
                type: Array,
                required: false,
                default: () => []
            }
        },
        data() {
            return {
                selectedFile: null,
                uploadedItems: [],
                uploading: false,
                uploadPercentage: 0,
                unassignedFiles: [],
                selectedUnassignedFile: null
            }
        },
        watch: {
            uploadedItems: function () {
                this.updateItemValue();
            }
        },
        methods: {
            openFile(storageId) {
                this.$emit('open-file', storageId);
            },
            deleteFile(e) {
                this.uploadedItems = this.uploadedItems.filter((val) => val.id !== e.id);
            },
            uploadFile: function() {
                if (this.selectedFile.size > 10 * 1024 * 1024) {
                    this.$error('File Size', 'The file cannot exceed 10MB');
                    return;
                }

                this.uploading = true;
                this.uploadPercentage = 0;

                let fileName = this.selectedFile.name;
                let reader = new FileReader();
                let vueThis = this;
                reader.onload = (e) => {
                    let contentType = vueThis.selectedFile.type;
                    let data = e.target.result;
                    this.$http.post(`file/upload?filename=${encodeURIComponent(fileName)}`, data, {
                        progress(e) {
                            if (e.lengthComputable) {
                                vueThis.uploadPercentage = e.loaded / e.total * 100;
                            }
                        },
                        headers: {
                            'Content-Type': contentType
                        }
                    }).then((resp) => {
                        this.uploadedItems.push({
                            id: resp.body.storageId,
                            name: fileName,
                        });
                        this.$refs.fileInput.reset();
                    }).finally(() => {
                        this.uploading = false;
                    }).catch((resp) => {
                        this.$httpError('Failed to upload file', resp);
                        this.uploading = false;
                    });
                };

                reader.readAsArrayBuffer(this.selectedFile);
            },
            updateItemValue: function() {
                this.$emit('input', this.uploadedItems.map(v => v))
            },
            downloadUnassignedFiles: function() {
                this.$http.get('file/unassigned').then(resp => {
                    this.unassignedFiles = resp.body;
                });
            },
            attachUnassignedFile: function() {
                let storageId = this.selectedUnassignedFile.storageId;
                this.uploadedItems.push({
                    id: storageId,
                    name: this.selectedUnassignedFile.filename,
                });
                this.unassignedFiles = this.unassignedFiles.filter(item => item.storageId !== storageId);
                this.selectedUnassignedFile = null;
            }
        },
        mounted() {
            if (this.value && this.value.length > 0) {
                this.uploadedItems = this.value.map(item => {
                    return { id : item.id, name: item.name };
                });
            }
            this.downloadUnassignedFiles();
        }
    }
</script>

<style scoped>
    .uploaded-files {
        /*margin-bottom: 20px;*/
        border: 3px dashed #BBB;
        padding: 12px;
    }
    .row {
        margin-bottom:20px;
    }
    span.uploaded-nofiles {
        font-style: italic;
    }

    div.loader {
        margin-left: auto;
        margin-right: auto;
    }

    div.evidence-item {
        display:inline-block;
    }

</style>
