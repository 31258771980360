<template>
    <div class="portfolio-builder">
        <b-card>
            <WizardProgress :steps="steps">
                <router-view></router-view>
            </WizardProgress>
        </b-card>
    </div>
</template>

<script>
    import WizardProgress from "../components/common/WizardProgress";

    const STEPS = [
        {
            title: 'What to Include',
            routeName: 'portfolio-builder-start'
        },
        {
            title: 'Date Range',
            routeName: 'portfolio-builder-step2'
        },
        {
            title: 'Choose Entries',
            routeName: 'portfolio-builder-step3'
        },
        {
            title: 'Finish and Download',
            routeName: 'portfolio-builder-finish'
        }
    ]

    export default {
        name: "PortfolioBuilder",
        components: {WizardProgress},
        data() {
            return {
                steps: STEPS
            }
        }
    }
</script>

<style scoped>

</style>