<template>
    <div class="reviews">
        <b-overlay :show="loading">
            <b-alert variant="primary" :show="reviews.length === 0">No reviews have been recorded yet</b-alert>
            <PreviousReview v-for="(review, i) in reviews" :review="review" v-bind:key="i" />
        </b-overlay>
    </div>
</template>

<script>
    import PreviousReview from "./PreviousReview";
    import Providers from '../../providers/index';

    export default {
        name: "PreviousReviews",
        components: {PreviousReview},
        props: {
            userId: {
                type: Number,
                default: null,
                required: false,
            }
        },
        data() {
            return {
                loading: false,
                reviews: []
            }
        },
        methods: {
            loadReviewsAsUser() {
                this.loading = true;
                Providers.profile.reviews().then((resp) => {
                    this.reviews = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load previous reviews', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            loadReviewsAsMentor() {
                this.loading = true;
                Providers.mentor.reviews.reviews(this.userId).then((resp) => {
                    this.reviews = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load previous reviews', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            loadReviews() {
                if (this.userId) {
                    this.loadReviewsAsMentor();
                } else {
                    this.loadReviewsAsUser();
                }
            }
        },
        created() {
            this.loadReviews();
        }
    }
</script>

<style scoped>

</style>