import Authentication from '../providers/authentication';

const providerMixin = {

    data() {
        return {
            $api: {
                authentication: Authentication
            }
        }
    }

};

export default providerMixin;