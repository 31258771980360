<template>
    <div class="file-list">
        <b-table class="entries-table" ref="entriesTable" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :busy.sync="isBusy" striped :items="filesProvider" :fields="fields" :per-page="perPage" :current-page="currentPage">
            <template v-slot:cell(actions)="data">
                <div class="actions" style="width: 170px;">
                    <b-button @click="openEvidence(data.item)" variant="outline-secondary" size="sm" class="mr-2"><eye-outline-icon></eye-outline-icon> Preview</b-button>
                    <b-button @click="deleteFile(data.item)" variant="outline-danger" size="sm" v-requires-role="'User'"><delete-outline-icon /> Delete</b-button>
                </div>
            </template>
            <template v-slot:cell(attached-to)="data">
                <a v-if="data.item.entryId != null" href="#" @click.prevent="editAttachedEntry(data.item)"><b-badge variant="success" v-b-tooltip.hover.top="data.item.entryTitle">{{capitalise(data.item.entryType)}} Entry</b-badge></a>
                <b-badge variant="secondary" v-else>None</b-badge>
            </template>
        </b-table>
        <b-pagination v-show="!isBusy" class="example" align="center" :hideGotoEndButtons="true" prevText="Previous" nextText="Next" size="sm" :limit="perPage" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" />
        <DeleteFileModal ref="deleteFileModal" @file-deleted="refresh" />
    </div>
</template>

<script>
    import EyeOutlineIcon from 'vue-material-design-icons/EyeOutline';
    import DeleteOutlineIcon from 'vue-material-design-icons/DeleteOutline';
    import FormatterUtils from 'cpdcloud-dashboard/src/utilities/FormatterUtils';
    import DeleteFileModal from "./DeleteFileModal";
    import { mapGetters } from 'vuex'
    import Providers from '../../providers';

    export default {
        name: "PaginatedFileList",
        components: {DeleteFileModal, EyeOutlineIcon, DeleteOutlineIcon},
        data() {
            return {
                perPage: 10,
                currentPage: 1,
                totalRows: 0,
                isBusy: false,
                fields: [
                    {
                        key: 'filename',
                        label: 'Filename',
                    },
                    {
                        key: 'dateUploaded',
                        label: 'Date Uploaded',
                    },
                    {
                        key: 'attached-to',
                        label: 'Attached To',
                    },
                    {
                        key: 'actions',
                        label: 'Actions',
                    }
                ],
                moment: require('moment'),
            }
        },
        computed: {
            ...mapGetters({
                authToken: 'authentication/getToken'
            }),
        },
        methods: {
            filesProvider: function (ctx, callback) {
                this.isBusy = true;
                let url = `file?per_page=${ctx.perPage}&page=${ctx.currentPage}`;
                this.$http.get(url).then(response => {
                    let items = response.body.items;
                    items.map(item => {
                        if (item.dateUploaded) {
                            item.dateUploaded = this.moment(item.dateUploaded, 'YYYY-MM-DD\'T\'HH:mm:ss').format('DD/MM/YYYY');
                        }
                    });
                    callback(items);
                    this.totalRows = response.body.totalItems
                    this.isBusy = false;
                }).catch((resp) => {
                    this.$httpError('Failed to load files', resp);
                }).then(() => {
                    this.isBusy = false;
                });

                // Must return null or undefined to signal b-table that callback is being used
                return null
            },
            editAttachedEntry: function(item) {
                let entryId = item.entryId;
                let entryType = item.entryType;
                if (!entryId || !entryType) {
                    return;
                }
                let routeName = 'edit/' + entryType.toLowerCase();
                this.$router.push({name: routeName, params: {id: entryId}});

            },
            deleteFile(e) {
                this.$refs.deleteFileModal.show(e);
            },
            openEvidence: function(e) {
                Providers.files.requestFilePreview(e.storageId).then((resp) => {
                    window.open(resp.body.url, '_blank');
                }).catch((resp) => {
                    this.$httpError('Failed to download file', resp);
                });
            },
            refresh() {
                this.$refs.entriesTable.refresh();
            },
            capitalise(val) {
                return FormatterUtils.capitalize(val);
            },
        }
    }
</script>

<style scoped>

</style>