<template>
    <div class="start">
        <b-form-group label="Include Pages">
            <b-checkbox-group :options="includeOptions" v-model="include" switches stacked size="lg"/>
        </b-form-group>
        <b-form-group labe="Options">
            <div>
                <b-checkbox v-model="includeApprovalComments">Include Approval Comments for each Entry</b-checkbox>
                <b-checkbox v-model="includeAttachments">Attach Uploaded Evidence to Entries</b-checkbox>
            </div>
        </b-form-group>

    </div>
</template>

<script>

    const INCLUDE_OPTIONS = [
        {text: 'Front Page', value: 'FrontPage'},
        // {text: 'Contents Page', value: 'ContentsPage'},
        {text: 'Profile', value: 'Profile'},
        {text: 'Training Record', value: 'TrainingRecord'},
        {text: 'Skills Usage', value: 'SkillsUsage'},
        {text: 'Clinical Exposure', value: 'ClinicalExposure'}
    ]

    export default {
        name: "Start",
        data() {
            return {
                includeOptions: INCLUDE_OPTIONS
            }
        },
        computed: {
            include: {
                get() {
                    return this.$store.state.portfolioBuilder.request.include;
                },
                set(val) {
                    this.$store.commit('portfolioBuilder/updateIncludes', val);
                }
            },
            includeApprovalComments: {
                get() {
                    return this.$store.state.portfolioBuilder.request.options.includeApprovalComments;
                },
                set(val) {
                    let originalValue = {...this.$store.state.portfolioBuilder.request.options}
                    originalValue.includeApprovalComments = val;
                    this.$store.commit('portfolioBuilder/updateOptions', originalValue);
                }
            },
            includeAttachments: {
                get() {
                    return this.$store.state.portfolioBuilder.request.options.includeAttachments;
                },
                set(val) {
                    let originalValue = {...this.$store.state.portfolioBuilder.request.options}
                    originalValue.includeAttachments = val;
                    this.$store.commit('portfolioBuilder/updateOptions', originalValue);
                }
            }
        },
        created() {
            if (this.$route.params.reset) {
                this.$store.commit('portfolioBuilder/reset');
            }
        }
    }
</script>

<style scoped>

</style>