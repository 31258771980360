const graphUtilsMixin = {

    methods: {

        $transformToChartData(categoryDataItems) {
            let values = [];
            let colours = [];
            let categories = [];

            let appendText = '';

            let allZero = true;

            for (let dataItem of categoryDataItems) {
                values.push(dataItem.value);
                colours.push(dataItem.colour);
                categories.push(dataItem.title);
                if (dataItem.appendText) {
                    appendText = dataItem.appendText;
                }
                if (dataItem.value === 0) {
                    allZero = allZero && true;
                } else {
                    allZero = false;
                }
            }

            if (allZero) {
                return {
                    chartData: {
                        datasets: [
                            {
                                data: [1],
                            }
                        ],
                        labels: ['No Data']
                    },
                    options: {
                        tooltips: {
                            enabled: false
                        }
                    }
                }
            }

            return {
                chartData: {
                    datasets: [
                        {
                            data: values,
                            backgroundColor: colours
                        }

                    ],
                    labels: categories,
                },
                options: {
                    tooltips: {
                        callbacks: {
                            label: (tooltipItems, data) => {
                                return ' ' + data.labels[tooltipItems.index] +': ' + data.datasets[tooltipItems.datasetIndex] .data[tooltipItems.index] + appendText;
                            },
                        },
                    }
                }
            }
        },

        $transformToLineData(lineData) {
            let labels = lineData.categories;
            let datasets = [];
            for (let set of lineData.data) {
                datasets.push({
                    data: set.values,
                    backgroundColor: set.colour,
                    label: set.title
                });
            }
            return {labels, datasets};
        }

    }

};

export default graphUtilsMixin;