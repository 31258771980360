<template>
    <b-form-group :label="item.title" :description="item.helpText">
        <b-form-select v-model="item.value">
            <option disabled value="">-- Please Select --</option>
            <option v-for="option in item.values" :key="option">{{option}}</option>
        </b-form-select>
    </b-form-group>
</template>

<script>
    import FormItem from './FormItem'

    export default {
        name: "DropdownFormItem",
        extends: FormItem,
    }
</script>

<style scoped>

</style>