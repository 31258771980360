import { render, staticRenderFns } from "./HoursFormItem.vue?vue&type=template&id=05359690&scoped=true&"
import script from "./HoursFormItem.vue?vue&type=script&lang=js&"
export * from "./HoursFormItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../.jenkins/workspace/DMSDashboardDeployLIVE/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05359690",
  null
  
)

export default component.exports