import Vue from "vue";

export default {
    sharedCPD(perPage, page) {
        return Vue.http.get(`shared-cpd?per_page=${perPage}&page=${page}`);
    },
    share(entryId, sharedCPD) {
        return Vue.http.post(`shared-cpd?entryId=${entryId}`, sharedCPD);
    },
    unshare(entryId) {
        return Vue.http.delete(`shared-cpd/${entryId}`);
    }
}