<template>
    <b-overlay :show="loading" spinner-small>
        <b-button :variant="variant" :block="block" :type="type" @click.prevent="click" @submit="submit" :disabled="disabled">
            <slot>

            </slot>
        </b-button>
    </b-overlay>
</template>

<script>
    export default {
        name: "LoadingButton",
        props: {
            variant: {
                type: String,
                required: false,
                default: 'primary'
            },
            block: {
                type: Boolean,
                required: false,
                default: false
            },
            loading: {
                type: Boolean,
                required: false,
                default: false,
            },
            type: {
                type: String,
                required: false,
                default: 'button'
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
        methods: {
            click($e) {
                this.$emit('click', $e);
            },
            submit($e) {
                this.$emit('submit', $e);
            }
        }
    }
</script>

<style scoped>

</style>