<template>
    <div class="contact-information">
        <b-card header="Contact Information">
            <b-overlay :show="loading">
                <b-form-group label="Primary Email Address" v-b-tooltip.hover="'Contact your administrator to change your primary email address'">
                    <b-form-input type="email" v-model="userInformation.primaryEmailAddress"  plaintext />
                </b-form-group>
                <fieldset class="form-group">
                    <legend class="col-form-label pt-0" style="width: 100%;">Secondary Email Address <a href="#" v-if="!editing" @click.prevent="enableEditing" v-requires-role="'User'"><PencilIcon /> Edit</a></legend>
                    <b-form-input type="email" v-model="userInformation.secondaryEmailAddress" :plaintext="!editing" trim />
                    <small v-if="editing">If specified, this email address will be CC'd into any communications.</small>
                </fieldset>
                <b-button v-if="editing" variant="primary" @click="save">Save Changes</b-button>
            </b-overlay>
        </b-card>
    </div>
</template>

<script>
    import PencilIcon  from 'vue-material-design-icons/Pencil';
    import Providers from '../../providers';

    export default {
        name: "ContactInformation",
        components: {PencilIcon},
        props: {
            userInformation: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                editing: false
            }
        },
        methods: {
            enableEditing() {
                this.editing = true;
            },
            save() {
                this.loading = true;
                Providers.profile.updateSecondaryEmail(this.userInformation.secondaryEmailAddress).then(() => {
                    this.editing = false;
                    this.$emit('contact-updated');
                }).catch((resp) => {
                    this.$httpError('Failed to update Contact Information', resp);
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>