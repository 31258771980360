<template>
    <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>{{title}}</span>
    </h6>
</template>

<script>
    export default {
        name: "SidebarHeading",
        props: {
            title: String
        }
    }
</script>

<style scoped>
    .sidebar-heading {
        font-size: .75rem;
        text-transform: uppercase;
    }
</style>