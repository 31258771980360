<template>
    <div class="login-2fa">
        <b-form class="form-login">
            <h1 class="h3 mb-3 font-weight-normal">Verify</h1>
            <b-form-group label="Verification Method">
                <b-form-select :options="dropdownValues" v-model="method">
                </b-form-select>
            </b-form-group>

            <LoadingButton :loading="loading" type="submit" :block="true" :disabled="method === null" @click="sendVerification">Send Verification</LoadingButton>
        </b-form>
    </div>
</template>

<script>

import LoadingButton from 'cpdcloud-dashboard/src/components/common/LoadingButton';
import Providers from '../../providers';

export default {
    name: "TwoFactorAuth",
    components: {LoadingButton},
    data() {
        return {
            username: '',
            password: '',
            method: null,
            loading: false,
            availableMethods: [

            ]
        }
    },
    computed: {
        dropdownValues() {
            return [
                {
                    text: '--- Please Select ---',
                    value: null,
                    disabled: true
                },
                ...this.availableMethods.map((val) => {
                    return {
                        text: val.displayTitle,
                        value: val.type
                    }
                })
            ]
        }
    },
    methods: {
        sendVerification() {
            this.loading = true;
            Providers.authentication.send2FA(this.username, this.password, this.method).then(() => {
                this.$router.push({name: '2fa-verify', params: {username: this.username, password: this.password, method: this.method}});
            }).catch((err) => {
                this.$httpError('Failed to perform Two-factor authentication', err);
            }).finally(() => {
                this.loading = false;
            });
        },
        loadMethods() {
            Providers.authentication.get2FAOptions(this.username, this.password).then((resp) => {
                this.availableMethods = resp.body;
            }).catch((err) => {
                this.$httpError('Failed to load Two-factor authentication options', err);
            })
        }
    },
    created() {
        this.username = this.$route.params.username;
        this.password = this.$route.params.password;
        this.loadMethods();
    }
}
</script>

<style scoped>
.form-login {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}
</style>
