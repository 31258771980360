<template>
    <div class="mandatory-training">
        <b-row>
            <b-col md="4">
                <Guidance />
            </b-col>
            <b-col md="8">
                <Statistics />
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <TrainingActions @training-added="refresh"/>
        </b-row>
        <b-row class="mt-4">
            <b-col sm="12">
                <TrainingLog ref="trainingLog" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import Providers from '../providers/index';
    import TrainingLog from "../components/mandatory-training/TrainingLog";
    import TrainingActions from "../components/mandatory-training/TrainingActions";
    import Guidance from "../components/mandatory-training/Guidance";
    import Statistics from "../components/mandatory-training/Statistics";
    export default {
        name: "MandatoryTraining",
        components: {Guidance, TrainingActions, TrainingLog, Statistics},
        data() {
            return  {
                statistics: null
            }
        },
        computed: {
            trainingCompliance() {
                if (this.statistics.total === 0) {
                    return '--'
                }
                return ((this.statistics.approved / this.statistics.total) * 100) + '%'
            },
            completedCount() {
                return this.statistics.approved + ' / ' + this.statistics.total;
            }
        },
        methods: {
            refresh() {
                this.$refs.trainingLog.refresh();
                this.loadStatistics();
            },
            loadStatistics() {
                Providers.training.statistics().then((resp) => {
                    this.statistics = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load statistics', resp);
                });
            }
        },
        created() {
            this.loadStatistics();
        },
    }
</script>

<style scoped>

</style>