<template>
    <b-form-group :label="item.title">
        <b-textarea v-model="item.value" :rows="10" @focus="showHelp" @blur="hideHelp"/>
        <span class="wordcount">{{wordCount}}</span>
        <p class="help-text mt-2" v-html="helpText"></p>
    </b-form-group>
</template>

<script>
    import FormItem from './FormItem'
    import WordCountUtils from "../../utilities/WordCountUtils";

    export default {
        name: "LongTextFormItem",
        extends: FormItem,
        computed: {
            wordCount: function() {
                let wc = WordCountUtils.wordCount(this.item.value);
                if (wc === 0) return '';

                let suffix = wc !== 1 ? ' words' : ' word';
                return '' + wc + suffix;
            }
        }
    }
</script>

<style scoped>
    .wordcount {
        font-size: 12px;
        text-transform: uppercase;
    }
    .help-text {
        font-size: 0.8rem;
    }
</style>