const state = {
    override: false
};

const getters = {
    useOverride: (state) => {
        return state.override;
    }
};

const actions = {
    setOverride: ({commit}, {override}) => {
        commit('setOverride', override)
    }
};

const mutations = {
    setOverride(state, override) {
        state.override = override;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}