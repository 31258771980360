import authentication from '../providers/authentication'
import store from '../store';
import Vue from "vue";
import router from "../router";
import jwt_decode from 'jwt-decode';

export default {
    login(username, password, totp = null) {
        return authentication.login(username, password, totp).then((resp) => {
            let token = resp.body.token;
            let roles = resp.body.roles;
            store.dispatch('authentication/storeLoginDetails', {token, roles});
            this.configureHttpClient();
            router.push({name: 'home'});
        });
    },
    overrideLogin(userId, userInfo) {
        return authentication.loginAs(userId).then((resp) => {
            let token = resp.body.token;
            let roles = [];
            let user = userInfo;
            store.dispatch('authentication/storeOverrideLoginDetails', {token, roles, user}).then(() => {
                let loginAsUrl = router.resolve({name: 'login-as'});
                window.open(loginAsUrl.href, '_blank');
            });
        });
    },
    logout() {
        store.dispatch('authentication/logout').then(() => {
            Vue.http.headers.common['Authorization'] = null;
            router.push({name: 'login'});
        });
    },
    roles() {
        let loginToken = this.loginToken();
        let token = jwt_decode(loginToken);
        return token.roles;
    },
    name() {
        let loginToken = this.loginToken();
        let token = jwt_decode(loginToken);
        return `${token.firstName} ${token.lastName}`;
    },
    loginToken() {
        if (store.getters["session/useOverride"]) {
            return store.getters["authentication/getOverrideToken"];
        }
        return store.getters["authentication/getToken"];
    },
    configureHttpClient() {
        let token = store.state.authentication.loginToken;
        this.configureHttpClientUsing(token);
    },
    configureHttpClientUsing(token) {
        Vue.http.headers.common['Authorization'] = 'Bearer ' + token;
    }
}
