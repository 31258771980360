import Vue from "vue";

export default {
    entries(page, perPage, filter) {
        return Vue.http.get(`all-entries?page=${page}&per_page=${perPage}&title=${filter.title || ''}&year=${filter.year || ''}&state=${filter.state || ''}&type=${filter.type || ''}`);
    },
    createEntry(type, formData, approvalInfo, scopeOfPracticeInfo) {
        return Vue.http.post(`entries/${type}`, {
            content: formData,
            approvalInfo: approvalInfo,
            scopeOfPracticeInfo: scopeOfPracticeInfo
        })
    },
    updateEntry(entryId, formData, approvalInfo, scopeOfPracticeInfo) {
        return Vue.http.put(`entries/${entryId}/update`, {
            content: formData,
            approvalInfo: approvalInfo,
            scopeOfPracticeInfo: scopeOfPracticeInfo
        });
    },
    submitForApproval(entryId) {
        return Vue.http.post(`entries/${entryId}/submit-for-approval`);
    },
    deleteEntry(entryId) {
        return Vue.http.delete(`entries/${entryId}`);
    },
    mentors() {
        return Vue.http.get('mentors');
    },
    approvalInfo(entryId) {
        return Vue.http.get(`entries/${entryId}/approval-info`)
    },
    scopeOfPracticeInfo(entryId) {
        return Vue.http.get(`entries/${entryId}/scope-of-practice-info`);
    }
}