<template>
    <div class="loader">
        <Spinner name="three-bounce" :color="color" :noFadeIn="true" />
    </div>
</template>

<script>
    import Spinner from 'vue-spinkit';
    export default {
        name: "Pulse",
        components: {Spinner},
        props: {
            color: {
                type: String,
                default: '#e3e3e3'
            }
        }
    }
</script>

<style scoped>
    .v-spinner {
        display:inline-block;
    }

    .loader {
        text-align:center;
    }

</style>