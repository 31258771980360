<template>
    <div class="diary container">
        <b-overlay :show="isBusy">
            <Form ref="form"  :load-form="loadForm" :entry-id="entryId" :approval-info="approvalInfo"
                  :scope-of-practice-info="scopeOfPracticeInfo" @show-message="showMessage" @loading-started="isBusy = true"
                  @loading-finished="isBusy = false" :form="form" v-if="form" />
        </b-overlay>
    </div>
</template>

<script>
    import Providers from "../../providers";
    import Form from '../../components/forms/Form';
    import DEFAULT_APPROVAL_INFO from '../../components/forms/defaultApprovalInfo';

    export default {
        name: "Diary",
        components: {Form},
        data() {
            return {
                form: null,
                isBusy: false,
                entryId: null,
                approvalInfo: {...DEFAULT_APPROVAL_INFO},
                scopeOfPracticeInfo: {items: []}
            }
        },
        methods: {
            loadForm: function() {
                this.form = null;
                if (this.$route.params && this.$route.params.id !== undefined) {
                    this.loadExistingEntry(this.$route.params.id);
                } else {
                    this.loadNewForm();
                }
            },
            loadNewForm: function() {
                this.isBusy = true;
                Providers.forms.form('diary').then(response => {
                    this.form = response.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load Diary form', resp);
                }).finally(() => {
                    this.isBusy = false;
                });
            },
            loadExistingEntry: function(id) {
                this.isBusy = true;
                this.entryId = id;

                Providers.forms.filledForm(this.entryId).then(response => {
                    this.form = response.body;
                }).then(() => {
                    return Providers.entries.approvalInfo(this.entryId).then((response) => {
                        this.approvalInfo = response.body;
                    });
                }).then(() => {
                    return Providers.entries.scopeOfPracticeInfo(this.entryId).then((response) => {
                        this.scopeOfPracticeInfo = response.body;
                    })
                }).catch((resp) => {
                    this.$httpError('Failed to load entry', resp);
                }).finally(() => {
                    this.isBusy = false;
                })

                // Providers.forms.filledForm(this.entryId).then(response => {
                //     this.form = response.body;
                //     Providers.entries.approvalInfo(this.entryId).then((response) => {
                //         this.approvalInfo = response.body;
                //     }).catch((resp) => {
                //         this.$httpError('Failed to load Approval Information', resp);
                //     }).finally(() => {
                //         this.isBusy = false;
                //     });
                //     Providers.entries.scopeOfPracticeInfo(this.entryId).then((response) => {
                //         this.scopeOfPracticeInfo = response.body;
                //     }).catch((resp) => {
                //         this.$httpError('Failed to load Scope of Practice', resp);
                //     }).finally(() => {
                //         this.isBusy = false;
                //     });
                // }).catch((resp) => {
                //     this.$httpError('Failed to load entry', resp);
                //     this.isBusy = false;
                // })
            },
            showMessage: function($e) {
                this.$error('Error', $e.message);
            },
            onLoad: function() {
                this.loadForm();
            }

        },
        beforeRouteEnter(to,from,next) {
            next(vm => {
                vm.form = null;
                vm.message = null;
                vm.entryId = null;
                vm.onLoad();
            });
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.form.leavingRoute(to, from, next);
        },
        created() {
            this.loadForm();
        }
    }
</script>

<style scoped>
    .container {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
    }
</style>