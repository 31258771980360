<template>
    <div class="statistics">
        <b-card header="Statistics">
            <b-overlay :show="loading">
                <b-row>
                    <b-col md="7">
                        <ReportingPeriodSelector v-model="selectedPeriod" />
                        <b-row>
                            <b-col cols="12">
                                <b-card-group deck>
                                    <TopStatistic header="Skills Utilisation" :value="skillsUtilisation" small />
                                    <TopStatistic header="Skills Logged" :value="skillsLogged" small />
                                    <TopStatistic header="Additional Competencies" :value="additionalSkills" small />
                                </b-card-group>
                            </b-col>
                        </b-row>


                    </b-col>
                    <b-col md="5">
                        <PieGraph :height="200" :chart-data="graphData.chartData" :options="graphData.options" v-if="graphData"/>
                    </b-col>
                </b-row>
            </b-overlay>
        </b-card>
    </div>
</template>

<script>
    import TopStatistic from "cpdcloud-dashboard/src/components/common/TopStatistic";
    import PieGraph from "../graphs/PieGraph";
    import graphUtilsMixin from "../../mixins/graphUtils";
    import Providers from '../../providers';
    import ReportingPeriodSelector from "../common/ReportingPeriodSelector";

    export default {
        name: "Statistics",
        components: {ReportingPeriodSelector, TopStatistic, PieGraph},
        mixins: [graphUtilsMixin],
        data() {
            return {
                loading: false,
                statistics: null,
                selectedPeriod: null,
            }
        },
        watch: {
            selectedPeriod: {
                deep: true,
                handler() {
                    this.loadStatistics();
                }
            }
        },
        methods: {
            loadStatistics() {
                if (this.selectedPeriod === null)
                    return;
                this.loading = true;
                Providers.clinicalSkills.statistics(this.selectedPeriod).then((resp) => {
                    this.statistics = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load statistics', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        computed: {
            graphData() {
                if (this.statistics) {
                    return this.$transformToChartData(this.statistics.utilisationBreakdown);
                }
                return null;
            },
            skillsLogged() {
                if (this.statistics) {
                    return `${this.statistics.skillsLogged} / ${this.statistics.totalSkills}`;
                }
                return '--'
            },
            skillsUtilisation() {
                if (this.statistics) {
                    if (this.statistics.totalSkills === 0) {
                        return '100%';
                    }
                    let utilisation = (this.statistics.skillsLogged / this.statistics.totalSkills) * 100;
                    return `${utilisation.toFixed(1)}%`;
                }
                return '--';
            },
            additionalSkills() {
                if (this.statistics) {
                    return `${this.statistics.additionalCompetencies}`;
                }
                return '--'
            }
        },
        created() {
            this.loadStatistics();
        }
    }
</script>

<style scoped>

</style>