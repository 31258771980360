<template>
    <div class="login">
        <LoginPage :login="login" @password-reset="passwordReset" :loading="loading" />
    </div>
</template>

<script>
    import LoginPage from 'cpdcloud-dashboard/src/components/authentication/LoginPage'
    import Managers from '../../managers';

    export default {
        name: "Login",
        components: {LoginPage},
        data() {
            return {
                loading: false,
            }
        },
        methods: {
            login(username, password) {
                this.loading = true;
                Managers.authentication.login(username, password).catch((err) => {
                    if (err.status === 403) {
                        console.log('2fa');
                        this.$router.push({name: '2fa-send', params: {username, password}});
                    } else {
                        this.$httpError('Failed to login', err);
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            passwordReset() {
                this.$router.push('password-reset');
            }
        }
    }
</script>

<style scoped>

</style>
