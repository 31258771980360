<template>
    <div class="log-training-modal">
        <b-modal size="lg" title="Log Training Completion" header-bg-variant="primary" header-text-variant="light" ref="modal" @ok="addToLog"
                 :ok-disabled="loading" :cancel-disabled="loading" ok-title="Add to Log" no-close-on-esc no-close-on-backdrop>
            <b-overlay :show="loading" blur="0.9">
                <b-form-group label="Training">
                    <b-select :options="trainingItems" v-model="request.trainingItem">
                        <template v-slot:first>
                            <b-form-select-option :value="null" disabled>-- Please select --</b-form-select-option>
                        </template>
                    </b-select>
                    <small>Select the training item you wish to log</small>
                </b-form-group>
                <b-form-group label="Completion Date">
                    <b-form-datepicker v-model="request.completionDate" />
                </b-form-group>
                <b-form-group label="Comments / Description">
                    <b-textarea rows="5" v-model="request.description"></b-textarea>
                </b-form-group>
                <b-form-group label="Attachments">
                    <ChooseFiles v-model="request.attachments" />
                </b-form-group>
                <div class="sop">
                    <ScopeOfPractice :scope-of-practice-info="scopeOfPracticeInfo" />
                </div>
                <div class="approval">
                    <ApprovalInfo :approval-info="approvalInfo" no-confirmation />
                </div>
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
    import ApprovalInfo from "../forms/ApprovalInfo";
    import Providers from '../../providers/index';
    import DEFAULT_APPROVAL_INFO from '../forms/defaultApprovalInfo';
    import ScopeOfPractice from "../forms/ScopeOfPractice";
    import ChooseFiles from 'cpdcloud-dashboard/src/components/common/ChooseFiles';

    export default {
        name: "LogTrainingModal",
        components: {ScopeOfPractice, ApprovalInfo, ChooseFiles},
        data() {
            return {
                loading: false,
                trainingItems: [],
                request: {
                    trainingItem: null,
                    completionDate: null,
                    description: '',
                    attachments: [],
                    cceHours: 0
                },
                approvalInfo: {...DEFAULT_APPROVAL_INFO},
                scopeOfPracticeInfo: {items: []}
            }
        },
        methods: {
            show() {
                this.reset();
                this.$refs.modal.show();
            },
            hide() {
                this.$refs.modal.hide();
            },
            addToLog($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.training.logTraining(this.request.trainingItem, this.request.completionDate, this.request.description, this.request.cceHours, this.request.attachments, this.approvalInfo, this.scopeOfPracticeInfo).then(() => {
                    this.$success('Training Logged', 'Your training has been logged successfully');
                    this.$emit('training-added');
                    this.hide();
                }).catch((resp) => {
                    this.$httpError('Failed to log training', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            loadAvailableTraining() {
                this.loading = true;
                Providers.training.availableTraining().then((resp) => {
                    this.trainingItems = resp.body.map(ti => { return { value: ti.id, text: ti.title }});
                }).catch((resp) => {
                    this.$httpError('Failed to get Available Training', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            reset() {
                this.request = {
                    trainingItem: null,
                    completionDate: null,
                    description: '',
                    cceHours: 0
                }
                this.approvalInfo = {...DEFAULT_APPROVAL_INFO};
                this.scopeOfPracticeInfo = {items: []}
            },
        },
        created() {
            this.loadAvailableTraining();
        }
    }
</script>

<style scoped>

</style>
