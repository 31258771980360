<template>
    <div class="password-reset">
        <h1 class="h3 mb-3 font-weight-normal">Password Reset</h1>
        <b-form class="form-password-reset" @submit.prevent="doReset">
            <b-form-group label="Email Address">
                <b-form-input id="emailAddress"
                              type="email"
                              v-model="email"
                              required
                              autofocus
                              placeholder="bob@example.com"
                              :disabled="loading" />
            </b-form-group>

            <LoadingButton :loading="loading" @click="doReset" type="submit" :block="true">Reset Password</LoadingButton>
        </b-form>
    </div>
</template>

<script>
    import LoadingButton from "../common/LoadingButton";

    export default {
        name: "PasswordResetPage",
        components: {LoadingButton},
        props: {
            loading: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                email: ''
            }
        },
        methods: {
            doReset() {
                this.$emit('password-reset', {email: this.email});
            }
        }
    }
</script>

<style scoped>
    .password-reset {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }
</style>