<template>
    <div class="entry-preview">
        <b-modal :title="title" header-bg-variant="info" header-text-variant="light" ref="modal" size="lg" scrollable>
            <ApprovalDetails  v-if="showApprovalInfo" :entry-id="entryId" :type="entryType" />
            <div class="loader text-center" v-if="loading">
                <b-spinner></b-spinner>
            </div>
            <EntryPreview v-if="!isStandardEntry && entryPreview" type="" :attributes="entryPreview.attributes" />
            <pdf :resize="true" :src="pdfUrl" :page="currentPage" v-if="pdfUrl && isStandardEntry" @numpages="updatePageCount">
                <template slot="loading">
                    Loading...
                </template>
            </pdf>
            <template slot="modal-footer" v-if="isStandardEntry">
                <b-button variant="info" @click="print"><printer-icon></printer-icon> Print</b-button>
                <b-button :disabled="!canMoveBack" @click="previous"><chevron-left-icon /> Previous Page</b-button>
                <b-button :disabled="!canMoveForward" @click="next">Next Page <chevron-right-icon /></b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import ChevronRightIcon from "vue-material-design-icons/ChevronRight";
    import ChevronLeftIcon from "vue-material-design-icons/ChevronLeft";
    import PrinterIcon from "vue-material-design-icons/Printer";
    import printJS from 'print-js';
    import ApprovalDetails from "./ApprovalDetails";
    import EntryPreview from "../external/approval/EntryPreview";
    import Providers from '../../providers';

    export default {
        name: "PreviewEntryModal",
        components: {
            EntryPreview,
            ApprovalDetails,
            ChevronLeftIcon,
            ChevronRightIcon,
            PrinterIcon,
            pdf: () => import('pdfvuer')
        },
        props: {
            showApprovalInfo: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
        data() {
            return {
                loading: false,
                apiRoot: '',
                token: '',
                pdfUrl: '',
                numPages: 0,
                currentPage: 1,
                entryId: null,
                entryType: null,
                entryPreview: null
            }
        },
        methods: {
            show(entryId, entryType = 'unknown') {
                this.entryPreview = null;
                this.entryId = entryId;
                this.entryType = entryType
                if (this.isStandardEntry) {
                    this.pdfUrl = this.apiRoot + 'entries/' + entryId + '/preview?auth_token=' + this.token;
                } else {
                    this.loadEntryPreview(entryType, entryId);
                }
                this.$refs.modal.show();
            },
            loadEntryPreview(type, entryId) {
                this.loading = true;
                Providers.allEntries.preview(type, entryId).then((resp) => {
                    this.entryPreview = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load preview', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            updatePageCount(pageCount) {
                this.numPages = pageCount;
            },
            next() {
                this.currentPage++;
            },
            previous() {
                this.currentPage--;
            },
            print() {
                printJS({printable: this.pdfUrl, type: 'pdf', showModal: true});
            }
        },
        computed: {
            title() {
                if (this.entryPreview) {
                    return `Preview - ${this.entryPreview.type}`
                }
                return 'Preview';
            },
            isStandardEntry() {
                return ['Diary', 'Reflection'].includes(this.entryType);
            },
            canMoveForward() {
                return this.currentPage < this.numPages;
            },
            canMoveBack() {
                return this.currentPage > 1;
            }
        },
        created() {
            if (this.$store.state.session.override) {
                this.token = this.$store.state.authentication.override.loginToken;
            } else {
                this.token = this.$store.state.authentication.loginToken;
            }
            this.apiRoot = process.env.VUE_APP_API_ROOT;
        }
    }
</script>

<style scoped>

</style>
