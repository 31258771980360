<template>
    <div class="available-training-modal">
        <b-modal ref="modal" size="lg" title="Available Training" header-bg-variant="primary" header-text-variant="light" :ok-only="true" ok-title="Close" ok-variant="dark">
            <b-form-group label="Training Item">
                <b-select :options="trainingItemOptions" v-model="selectedItemId">
                    <template v-slot:first>
                        <b-form-select-option :value="null" disabled>-- Please select --</b-form-select-option>
                    </template>
                </b-select>
            </b-form-group>
            <div class="training-info" v-if="selectedTrainingItem">
                <b-form-group label="Description">
                    <p v-text="selectedTrainingItem.description"></p>
                </b-form-group>
                <div class="actions">
                    <b-button target="_blank" variant="primary" :href="selectedTrainingItem.link" v-if="selectedTrainingItem.link">Open Training</b-button>
                    <b-button target="_blank" variant="outline-dark" :href="selectedTrainingItem.guidanceLink" v-if="selectedTrainingItem.guidanceLink">Guidance</b-button>
                </div>


            </div>
        </b-modal>
    </div>
</template>

<script>
    import Providers from '../../providers/index';

    export default {
        name: "AvailableTrainingModal",
        data() {
            return {
                selectedItemId: null,
                trainingItemOptions: [],
                trainingItemsMapping: new Map(),
                loading: false
            }
        },
        computed: {
            selectedTrainingItem() {
                if (this.selectedItemId == null) {
                    return null;
                }
                return this.trainingItemsMapping.get(this.selectedItemId);
            }
        },
        methods: {
            show() {
                this.$refs.modal.show();
            },
            hide() {
                this.$refs.modal.hide();
            },
            loadTraining() {
                this.loading = true;
                Providers.training.availableTraining().then((resp) => {
                    this.trainingItemOptions = resp.body.map(ti => { return { value: ti.id, text: ti.title }});
                    this.trainingItemsMapping = new Map();
                    resp.body.forEach(trainingItem => {
                        this.trainingItemsMapping.set(trainingItem.id, trainingItem);
                    });
                }).catch((resp) => {
                    this.$httpError('Failed to load training items', resp);
                    this.hide();
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        created() {
            this.loadTraining();
        }
    }
</script>

<style scoped>
    div.actions > * {
        margin-right: 5px;
    }
</style>