<template>
    <div class="login">
        <b-form class="form-login" @submit.prevent="doLogin">
            <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
            <b-form-group label="Email Address">
                <b-form-input id="emailAddress"
                              type="email"
                              v-model="email"
                              required
                              autofocus
                              placeholder="bob@example.com"
                              :disabled="loading" />
            </b-form-group>

            <b-form-group label="Password">
                <b-form-input id="password"
                              type="password"
                              v-model="password"
                              required
                              placeholder="********"
                              :disabled="loading" />
            </b-form-group>

            <LoadingButton :loading="loading" type="submit" :block="true" @click="doLogin">Login</LoadingButton>
            <b-button variant="default" type="button" :block="true" @click="doPasswordReset">Reset Password</b-button>
        </b-form>
    </div>
</template>

<script>
    import LoadingButton from "../common/LoadingButton";
    export default {
        name: "LoginPage",
        components: {LoadingButton},
        props: {
            login: Function,
            loading: Boolean
        },
        data() {
            return {
                email: '',
                password: '',
            }
        },
        methods: {
            doLogin() {
                this.login(this.email, this.password);
            },
            doPasswordReset() {
                this.$emit('password-reset');
            }
        }
    }
</script>

<style scoped>
    .form-login {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }
</style>