<template>
    <div class="pdp">
        <b-overlay :show="loading">
            <b-table table-class="light" :fields="fields" :items="items" show-empty>
                <template v-slot:empty>
                    <b-alert variant="primary" show>You haven't set any objectives yet</b-alert>
                </template>
                <template v-slot:cell(render_completeness)="data" style="padding: 0;">
                    <div :class="'completeness completeness-' + rowClass(data.item)"></div>
                </template>
                <template v-slot:cell(status)="data">
                    <b-badge variant="success" v-if="data.item.completed"><CheckIcon /> Completed</b-badge>
                    <b-badge variant="warning" v-else-if="!data.item.completed && moment(data.item.completeBy).isSameOrAfter(moment())"> In Progress</b-badge>
                    <b-badge variant="danger" v-else><CloseIcon  /> Overdue</b-badge>

                </template>
                <template v-slot:cell(actions)="data">
                    <div class="actions" v-if="!data.item.completed">
                        <b-button variant="outline-dark" size="sm" @click="edit(data.item)">Edit</b-button>
                        <b-button variant="outline-success" size="sm" @click="markAsComplete(data.item.id)">Mark as Complete</b-button>
                    </div>
                </template>
            </b-table>
            <b-button variant="primary" size="sm" @click="addObjective"><PlusIcon /> Add Objective</b-button>
        </b-overlay>
        <CreatePDPObjectiveModal ref="createModal" @objective-created="loadObjectives"/>
        <EditPDPObjectiveModal ref="editModal" @objective-updated="loadObjectives" />
    </div>
</template>

<script>
    import CheckIcon from 'vue-material-design-icons/Check';
    import CloseIcon from 'vue-material-design-icons/Close';
    import PlusIcon from 'vue-material-design-icons/Plus';
    import CreatePDPObjectiveModal from "./CreatePDPObjectiveModal";
    import Providers from '../../providers';
    import EditPDPObjectiveModal from "./EditPDPObjectiveModal";

    export default {
        name: "PersonalDevelopmentPlan",
        components: {EditPDPObjectiveModal, CreatePDPObjectiveModal, CheckIcon, CloseIcon, PlusIcon},
        data() {
            return {
                loading: false,
                items: [],
                fields: [
                    {
                        key: 'render_completeness',
                        label: ''
                    },
                    {
                        key: 'objective',
                        label: 'Objective'
                    },
                    {
                        key: 'achieve',
                        label: 'How will I achieve this?'
                    },
                    {
                        key: 'demonstrate',
                        label: 'How will I demonstrate completion'
                    },
                    {
                        key: 'completeBy',
                        label: 'By When',
                        formatter: (val) => this.moment(val).format('DD/MM/YYYY')
                    },
                    {
                        key: 'status',
                        label: 'Status'
                    },
                    {
                        key: 'actions',
                        label: ''
                    }
                ]
            }
        },
        methods: {
            rowClass(item) {
                if (item.completed) return '1';
                if (!item.completed && this.moment(item.completeBy).isBefore(this.moment())) return '3';
                return '2';
            },
            addObjective() {
                this.$refs.createModal.show();
            },
            loadObjectives() {
                this.loading = true;
                Providers.profile.objectives().then((resp) => {
                    this.items = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load objectives', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            edit(objective) {
                this.$refs.editModal.show(objective);
            },
            markAsComplete(objectiveId) {
                this.loading = true;
                Providers.profile.objectiveCompleted(objectiveId).then(() => {
                    this.$success('Objective Updated', 'The objective has now been marked as completed.');
                    this.loadObjectives();
                }).catch((resp) => {
                    this.$httpError('Failed to update objective', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        created() {
            this.loadObjectives();
        }
    }
</script>

<style scoped>
div.actions > * {
    margin-right: 5px;
}
.completeness {
    height: 25px;
    width: 8px;
    display: inline-block;
    background-color:darkgray;
}

.completeness-3 { /* incomplete */
    background-color: #cc3232;
}

.completeness-2 { /* partial */
    background-color: #e7b416;
}

.completeness-1 { /* complete */
    background-color: #99c140;
}
</style>