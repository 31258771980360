import Vue from 'vue';

export default {
    reviews(userId) {
        return Vue.http.get(`mentor/reviews/${userId}`);
    },
    createReview(userId, review) {
        return Vue.http.post(`mentor/reviews/${userId}`, review);
    },
    downloadPortfolio(userId, reviewDate) {
        return Vue.http.post(`mentor/reviews/${userId}/portfolio?review_date=${reviewDate}`, null, {
            responseType: 'blob'
        }).then(response => {
            let result = document.createElement('a');
            let contentDisposition = response.headers.get('Content-Disposition') || '';
            let filename;
            if (contentDisposition) {
                filename = contentDisposition.split('filename=')[1];
                filename = filename.replace(/"/g, "")
            } else {
                filename = "portfolio.pdf";
            }

            return response.blob()
                .then(function(data) {
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(data, filename);
                        return null;
                    } else {
                        result.href = window.URL.createObjectURL(data);
                        result.target = '_self';
                        result.download = filename;
                        return result;
                    }
                });
        }).then(link => {
            return () => link.click();
        });
    }
}