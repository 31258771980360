<template>
    <b-modal title="Add Objective" header-bg-variant="primary" header-text-variant="light" ref="modal"
             no-close-on-backdrop no-close-on-esc size="lg" ok-title="Add Objective" @ok="create"
             :ok-disabled="loading" :cancel-disabled="loading">
        <b-overlay :show="loading">
            <PDPObjectiveInformation :objective="objective" />
        </b-overlay>
    </b-modal>
</template>

<script>
    import DEFAULT_OBJECTIVE from './defaults/defaultPDPObjective';
    import PDPObjectiveInformation from "./PDPObjectiveInformation";
    import Providers from '../../providers';

    export default {
        name: "CreatePDPObjectiveModal",
        components: {PDPObjectiveInformation},
        data() {
            return {
                objective: null,
                loading: false,
            }
        },
        methods: {
            show() {
                this.reset();
                this.$refs.modal.show();
            },
            close() {
                this.$refs.modal.hide();
            },
            create($e) {
                $e.preventDefault();
                this.loading = true;
                Providers.profile.createObjective(this.objective).then(() => {
                    this.$success('Objective Added', 'The objective has been added to your Personal Development Plan.');
                    this.$emit('objective-created');
                    this.close();
                }).catch((resp) => {
                    this.$httpError('Failed to add Objective', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            reset() {
                this.objective = {...DEFAULT_OBJECTIVE}
            }
        }
    }
</script>

<style scoped>

</style>