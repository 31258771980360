import Vue from "vue";

export default {
    statistics(period) {
        return Vue.http.get(`clinical-exposure/statistics?from=${period.from}&to=${period.to}`);
    },
    create(request) {
        return Vue.http.post('clinical-exposure', request);
    },
    clinicalExposures(dateRange) {
        return Vue.http.get(`clinical-exposure?from=${dateRange.from}&to=${dateRange.to}`);
    }
}