<template>
    <div class="clinical-exposure">
        <b-row>
            <b-col md="4">
                <Guidance />
            </b-col>
            <b-col md="8">
                <Statistics ref="statistics" />
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col md="12">
                <div class="actions" v-requires-role="'User'">
                    <b-button variant="primary" @click="addClinicalExposure"><PlusIcon /> Clinical Exposure</b-button>
                </div>
            </b-col>
        </b-row>
        <b-row class="mt-2">
            <b-col md="12" >
                <b-card no-body>
                    <b-tabs lazy card fill>
                        <b-tab lazy title="Current Reporting Period" v-if="currentReportingPeriod">
                            <Entries ref="entries" :date-range="currentReportingPeriod" />
                        </b-tab>
                        <b-tab lazy title="Previous Reporting Period" v-if="previousReportingPeriod">
                            <Entries ref="entries" :date-range="previousReportingPeriod" />
                        </b-tab>
                    </b-tabs>
                </b-card>

            </b-col>
        </b-row>
        <AddClinicalExposureModal ref="addClinicalExposureModal" @clinical-exposure-added="refresh" />
    </div>
</template>

<script>
    import Guidance from "../components/clinical-exposure/Guidance";
    import Statistics from "../components/clinical-exposure/Statistics";
    import PlusIcon from 'vue-material-design-icons/Plus';
    import Entries from "../components/clinical-exposure/Entries";
    import AddClinicalExposureModal from "../components/clinical-exposure/AddClinicalExposureModal";
    import Providers from '../providers';

    export default {
        name: "ClinicalExposure",
        components: {AddClinicalExposureModal, Entries, Statistics, Guidance, PlusIcon},
        data() {
            return {
                loading: false,
                currentReportingPeriod: null,
                previousReportingPeriod: null
            }
        },
        methods: {
            addClinicalExposure() {
                this.$refs.addClinicalExposureModal.show();
            },
            refresh() {
                this.$refs.entries.refresh();
                this.$refs.statistics.refresh();
            },
            loadReportingPeriods() {
                this.loading = true;
                Providers.information.reportingPeriods().then((resp) => {
                    this.currentReportingPeriod = resp.body.current;
                    this.previousReportingPeriod = resp.body.previous;
                }).catch((resp) => {
                    this.$httpError('Failed to get reporting periods', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        created() {
            this.loadReportingPeriods();
        }
    }
</script>

<style scoped>
    .actions > * {
        margin-right: 5px;
    }
</style>