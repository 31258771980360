<template>
    <b-form-group :label="title">
        <b-row>
            <b-col cols="6">
                <b-form-select v-model="hoursValue">
                    <option v-for="hour of options.hours" :key="hour" :value="hour">{{hour}} hours</option>
                    <option v-if="!options.hours.includes(hoursValue)" :value="hoursValue">{{hoursValue}} hours</option>
                </b-form-select>
            </b-col>
            <b-col cols="6">
                <b-form-select v-model="minutesValue">
                    <option v-for="(minute) of options.minutes" :key="minute" :value="minute">{{minute}} minutes</option>
                    <option v-if="!options.minutes.includes(minutesValue)" :value="minutesValue">{{minutesValue}} minutes</option>
                </b-form-select>
            </b-col>
        </b-row>
        <b-button @click="item.type = 1" variant="default" size="sm">This activity is more than 24 hours</b-button>
    </b-form-group>
</template>

<script>
    import FormItem from './FormItem'

    export default {
        name: "HoursFormItem",
        components: {},
        props: {
            type: {
                required: false,
                default: 'text'
            }
        },
        data: function() {
            return {
                options: {
                  hours: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24],
                  minutes: [0, 15, 20, 25, 30, 35, 40, 45, 50, 55]
                },
                hoursValue: 0,
                minutesValue: 0
            }
        },
        watch: {
            hoursValue: function() {
                this.updateValue();
            },
            minutesValue: function() {
                this.updateValue();
            }
        },
        methods: {
            fieldState: function() {
                if (!this.item.required) {
                    return undefined;
                }
                return this.item.value !== '';
            },
            updateValue: function() {
                this.item.value = (this.hoursValue + this.twoDP(this.minutesValue / 60)).toString();
            },
            twoDP: function(num) {
                return Math.round(num * 100) / 100
            },
            forceFreeText() {
                this.item.forceFreeText = true;
            }
        },
        computed: {
            title: function() {
                if (this.item.required) {
                    return this.item.title + ' *';
                }
                return this.item.title;
            },

        },
        mounted() {
            let itemValue = this.item.value;
            if (itemValue) {
                if (!isNaN(itemValue)) {
                    let hours = Math.floor(itemValue);
                    let minutes = Math.round((itemValue - hours) * 60);
                    this.hoursValue = hours;
                    this.minutesValue = minutes;
                } else {
                    this.hoursValue = 0;
                    this.minutesValue = 0;
                }
            }

        },
        extends: FormItem,

    }
</script>

<style scoped>

</style>