<template>
    <div class="additional-competencies-modal">
        <b-modal ref="modal" title="Manage Additional Competencies"  header-bg-variant="primary" header-text-variant="light"
                 size="lg" ok-title="Close" ok-variant="dark" ok-only>
            <b-overlay :show="loading">
            <b-button variant="primary" @click="addCompetency" v-requires-role="'User'"><PlusIcon /> Add New</b-button>
            <b-table class="mt-2" table-class="light"  :fields="fields" :items="competencies">

            </b-table>
            </b-overlay>
        </b-modal>
        <AddAdditionalCompetencyModal ref="addModal" @competency-added="loadCompetencies"/>
    </div>
</template>

<script>
    import Providers from '../../providers/index';
    import PlusIcon from 'vue-material-design-icons/Plus';
    import AddAdditionalCompetencyModal from "./AddAdditionalCompetencyModal";

    export default {
        name: "ManageAdditionalCompetenciesModal",
        components: {AddAdditionalCompetencyModal, PlusIcon},
        data() {
            return {
                fields: [
                    {
                        key: 'name',
                        label: 'Name'
                    },
                    {
                        key: 'expiry',
                        label: 'Expiry'
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                competencies: [],
                loading: false
            }
        },
        methods: {
            show() {
                this.$refs.modal.show();
            },
            loadCompetencies() {
                this.loading = true;
                Providers.clinicalSkills.additionalCompetencies().then((resp) => {
                    this.competencies = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load additional competencies', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            addCompetency() {
                this.$refs.addModal.show();
            }
        },
        created() {
            this.loadCompetencies();
        }
    }
</script>

<style scoped>

</style>