<template>
    <div class="filter">
        <b-form :inline="true" @submit.prevent="changed">
            <b-form-group>
                <b-dropdown :text="filterYearText" class="m-md-3" variant="info" :disabled="loading">
                    <b-dropdown-header>Select Year</b-dropdown-header>
                    <b-dropdown-item v-for="year in years" v-bind:key="year.value" @click="changeYear(year.value)">{{year.title}}</b-dropdown-item>
                </b-dropdown>
            </b-form-group>

            <b-form-group>
                <b-dropdown :text="filterStateText" class="m-md-3" variant="info" :disabled="loading">
                    <b-dropdown-header>Select Status</b-dropdown-header>
                    <b-dropdown-item v-for="state in stateOptions" v-bind:key="state.value" @click="changeState(state.value)">{{state.title}}</b-dropdown-item>
                </b-dropdown>
            </b-form-group>

            <b-form-group>
                <b-dropdown :text="filterTypeText" class="m-md-3" variant="info" :disabled="loading">
                    <b-dropdown-header>Select Type</b-dropdown-header>
                    <b-dropdown-item v-for="type in typeOptions" v-bind:key="type.value" @click="changeType(type.value)">{{type.title}}</b-dropdown-item>
                </b-dropdown>
            </b-form-group>

            <b-form-group>
                <b-input-group>
                    <b-input type="text" autocomplete="off"  placeholder="Search by title..." v-model="filter.title" :readonly="loading"/>
                    <b-input-group-append>
                        <b-button type="submit" variant="info" :disabled="loading">Search</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
            <b-button class="ml-4" v-if="canReset" variant="outline-dark" @click="reset">Clear Filter</b-button>

        </b-form>
    </div>
</template>

<script>
    export default {
        name: "EntryFilter",
        props: {
            loading: {
                type: Boolean,
                defaultValue: false,
                required: false
            }
        },
        data() {
            return {
                filter: {
                    title: '',
                    year: '',
                    state: '',
                    type: ''
                },
                states: new Map(),
                types: new Map()
            }
        },
        computed: {
            years() {
                let years = [];
                let yearToday = (new Date()).getFullYear();
                years.push(yearToday, yearToday - 1, yearToday - 2, yearToday - 3);
                let yearObjs = years.map(y => {
                    return {
                        value: y,
                        title: y
                    }
                });

                let all = [{
                    value: '', title: 'All'
                }];
                all.push(...yearObjs);
                return all
            },
            stateOptions() {
                let options = [];
                options.push({
                    title: 'Any',
                    value: ''
                });
                this.states.forEach((text, val) => {
                    options.push({
                        title: text,
                        value: val
                    });
                });
                return options;
            },
            typeOptions() {
                let options = [];
                options.push({
                    title: 'Any',
                    value: ''
                });
                this.types.forEach((text, val) => {
                    options.push({
                        title: text,
                        value: val
                    });
                });
                return options;
            },
            filterYearText() {
                if (this.filter.year === '') {
                    return 'All Years';
                }
                return this.filter.year;
            },
            filterStateText() {
                if (this.filter.state === '') {
                    return 'Any Status';
                }
                return this.states.get(this.filter.state);
            },
            filterTypeText() {
                if (this.filter.type === '') {
                    return 'Any Type'
                }
                return this.types.get(this.filter.type);
            },
            canReset() {
                return this.filter.title !== '' || this.filter.year !== '' || this.filter.state !== '' || this.filter.type !== '';
            }
        },
        methods: {
            changed() {
                this.$emit('filter-changed',  this.filter);
            },
            changeYear(val) {
                this.filter.year = val;
                this.changed();
            },
            changeState(state) {
                this.filter.state = state;
                this.changed();
            },
            changeType(type) {
                this.filter.type = type;
                this.changed();
            },
            reset() {
                this.filter = {
                    title: '',
                    year: '',
                    state: '',
                    type: ''
                };
                this.changed();
            },
        },
        created() {
            this.states.set('Approved', 'Approved');
            this.states.set('PendingApproval', 'Pending Approval');
            this.states.set('Created', 'Created');
            this.states.set('Rejected', 'Rejected');

            this.types.set('Diary', 'Diary');
            this.types.set('Reflection', 'Reflection');
            this.types.set('ClinicalExposure', 'Clinical Exposure');
            this.types.set('ClinicalSkills', 'Clinical Skills');
            this.types.set('TrainingItem', 'Training');
        }

    }
</script>

<style scoped>

</style>