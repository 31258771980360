<template>
    <div class="add-additional-competency-modal">
        <b-modal ref="modal" title="Add Competency" header-bg-variant="dark" header-text-variant="light" no-close-on-esc no-close-on-backdrop
                 ok-title="Add" @ok="add">
            <b-form-group label="Competency Name">
                <b-input type="text" autocomplete="off" v-model="name">

                </b-input>
            </b-form-group>
            <b-form-group label="Expiry (Years)">
                <b-form-spinbutton min="1" max="5" v-model="expiry">

                </b-form-spinbutton>
                <small>How often does this competency need to be completed?</small>
            </b-form-group>
        </b-modal>
    </div>
</template>

<script>
    import Providers from '../../providers/index';

    export default {
        name: "AddAdditionalCompetencyModal",
        data() {
            return {
                name: '',
                expiry: 1,
                loading: false
            }
        },
        methods: {
            show() {
                this.reset();
                this.$refs.modal.show();
            },
            hide() {
                this.$refs.modal.hide();
            },
            add(e) {
                e.preventDefault();
                this.loading = true;
                Providers.clinicalSkills.addAdditionalCompetency(this.name, this.expiry).then(() => {
                    this.$success('Additional Competency Added', 'Your additional competency has now been added and is available to certify with.');
                    this.$emit('competency-added');
                    this.hide();
                }).catch((resp) => {
                    this.$httpError('Failed to add competency', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            reset() {
                this.name = '';
                this.expiry = 1;
            }
        }
    }
</script>

<style scoped>

</style>