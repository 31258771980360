<template>
    <div class="clinical-skills-table">
        <b-table :fields="fields" :items="items" show-empty>
            <template v-slot:empty>
                <b-alert show variant="primary">
                    You haven't logged any skills yet
                </b-alert>
            </template>
            <template v-slot:cell(type)="data">
                <b-badge variant="dark">{{data.item.type}}</b-badge>
            </template>
            <template v-slot:cell(clinicalSkill.type)="data">
                <b-badge variant="primary" v-if="data.item.clinicalSkill.type === 'Mandatory'">{{data.item.clinicalSkill.type}}</b-badge>
                <b-badge variant="dark" v-else>{{data.item.clinicalSkill.type}}</b-badge>
            </template>
        </b-table>
    </div>
</template>

<script>
    import Providers from '../../providers/index';

    export default {
        name: "ClinicalSkillsTable",
        data() {
            return {
                loading: false,
                fields: [
                    {
                        key: 'clinicalSkill.name',
                        label: 'Skill',
                    },
                    {
                        key: 'clinicalSkill.type',
                        label: 'Mandatory / Additional',
                    },
                    {
                        key: 'dateCompleted',
                        label: 'Last Completed'
                    },
                    {
                        key: 'expiry',
                        label: 'Expiry Date'
                    }
                ],
                items: [

                ]
            }
        },
        methods: {
            refresh() {
                this.loadSkillsLog();
            },
            loadSkillsLog() {
                this.loading = true;
                Providers.clinicalSkills.skillLog().then((resp) => {
                    this.items = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load Skills Log', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        created() {
            this.loadSkillsLog();
        }
    }
</script>

<style scoped>

</style>