import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

import AuthenticationPage from 'cpdcloud-dashboard/src/components/authentication/AuthenticationPage'
import DashboardLayout from "./components/common/DashboardLayout";
import Home from "./views/Home";
import Login from "./views/authentication/Login";
import store from "./store/index";
import Diary from "./views/create/Diary";
import Entries from "./views/Entries";
import Profile from "./views/Profile";
import Files from "./views/Files";
import ClinicalSkills from "./views/ClinicalSkills";
import MandatoryTraining from "./views/MandatoryTraining";
import Reflection from "./views/create/Reflection";
import SharedCPD from "./views/SharedCPD";
import Activate from "./views/authentication/Activate";
import PasswordReset from "./views/authentication/PasswordReset";
import ClinicalExposure from "./views/ClinicalExposure";
import LoginUsingOverride from "./views/authentication/LoginUsingOverride";
import Settings from "./views/Settings";
import FinishPasswordReset from "./views/authentication/FinishPasswordReset";
import PortfolioBuilder from "./views/PortfolioBuilder";
import Start from "./views/portfolio-builder/Start";
import DateRange from "./views/portfolio-builder/DateRange";
import ChooseEntries from "./views/portfolio-builder/ChooseEntries";
import Finish from "./views/portfolio-builder/Finish";
import TwoFactorAuth from "@/views/authentication/TwoFactorAuth";
import TwoFactorAuthCapture from "@/views/authentication/TwoFactorAuthCapture";

let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkExactActiveClass: 'active',
    routes: [
        {
            path: '/auth',
            component: AuthenticationPage,
            meta: {
                guest: true
            },
            children: [
                {
                    path: 'login',
                    component: Login,
                    name: 'login',
                    meta: {
                        guest: true
                    }
                },
                {
                    path: 'login/2fa',
                    component: TwoFactorAuth,
                    name: '2fa-send',
                    meta: {
                        guest: true
                    }
                },
                {
                    path: 'login/verify',
                    component: TwoFactorAuthCapture,
                    name: '2fa-verify',
                    meta: {
                        guest: true
                    }
                },
                {
                    path: 'login-as',
                    component: LoginUsingOverride,
                    name: 'login-as'
                },
                {
                    path: 'activate/:activationToken',
                    component: Activate,
                    name: 'activate',
                    meta: {
                        guest: true
                    }
                },
                {
                    path: 'password-reset',
                    component: PasswordReset,
                    name: 'password-reset',
                    meta: {
                        guest: true
                    }
                },
                {
                    path: 'password-reset/:token',
                    component: FinishPasswordReset,
                    name: 'password-reset/finish',
                    meta: {
                        guest: true
                    }
                }
            ]
        },
        {
            path: '/respond/:type/:accessKey',
            name: 'approval/respond',
            component: () => import('./views/external/RespondToApproval'),
            meta: {
                guest: true,
            }
        },
        {
            path: '/',
            name: 'root',
            component: DashboardLayout,
            children: [
                {
                    path: '/',
                    name: 'home',
                    component: Home
                },
                {
                    path: '/profile',
                    name: 'profile',
                    component: Profile,
                    meta: {
                        breadcrumbTitle: 'Profile'
                    }
                },
                {
                    path: 'entries',
                    name: 'entries',
                    component: Entries,
                    meta: {
                        breadcrumbTitle: 'All Entries'
                    }
                },
                {
                    path: 'files',
                    name: 'files',
                    component: Files,
                    meta: {
                        breadcrumbTitle: 'My Files'
                    }
                },
                {
                    path: 'shared-cpd',
                    name: 'shared-cpd',
                    component: SharedCPD,
                    meta: {
                        breadcrumbTitle: 'Shared CPD'
                    }
                },
                {
                    path: 'create/diary',
                    name: 'create-diary',
                    component: Diary,
                    meta: {
                        breadcrumbTitle: 'Create Diary'
                    }
                },
                {
                    path: 'edit/diary/:id',
                    component: Diary,
                    name: 'edit/diary',
                    meta: {
                        breadcrumbTitle: 'Edit Diary'
                    }
                },
                {
                    path: 'create/reflection',
                    name: 'create-reflection',
                    component: Reflection,
                    meta: {
                        breadcrumbTitle: 'Create Reflection'
                    }
                },
                {
                    path: 'edit/reflection/:id',
                    name: 'edit/reflection',
                    component: Reflection,
                    meta: {
                        breadcrumbTitle: 'Edit Reflection'
                    }
                },
                {
                    path: 'clinical-exposure',
                    name: 'clinical-exposure',
                    component: ClinicalExposure,
                    meta: {
                        breadcrumbTitle: 'Clinical Exposure'
                    }
                },
                {
                    path: 'edit/clinical-exposure/:id',
                    name: 'edit/cce',
                    component: ClinicalExposure,
                    meta: {
                        breadcrumbTitle: 'Edit Clinical Exposure'
                    }
                },
                {
                    path: 'clinical-skills',
                    name: 'clinical-skills',
                    component: ClinicalSkills,
                    meta: {
                        breadcrumbTitle: 'Clinical Skills'
                    }
                },
                {
                    path: 'mandatory-training',
                    name: 'mandatory-training',
                    component: MandatoryTraining,
                    meta: {
                        breadcrumbTitle: 'Course Compliance'
                    }
                },
                {
                    path: 'settings',
                    name: 'settings',
                    component: Settings,
                    meta: {
                        breadcrumbTitle: 'Settings'
                    }
                },
                {
                    path: 'portfolio-builder',
                    name: 'portfolio-builder',
                    component: PortfolioBuilder,
                    redirect: () => ({name: 'portfolio-builder-start', params: {reset: true}}),
                    meta: {
                        breadcrumbTitle: 'Portfolio Builder'
                    },
                    children: [
                        {
                            path: 'start',
                            name: 'portfolio-builder-start',
                            component: Start,
                            meta: {
                                breadcrumbTitle: 'Portfolio Builder - What to include'
                            }
                        },
                        {
                            path: 'step-2',
                            name: 'portfolio-builder-step2',
                            component: DateRange,
                            meta: {
                                breadcrumbTitle: 'Portfolio Builder - Date Range'
                            }
                        },
                        {
                            path: 'step-3',
                            name: 'portfolio-builder-step3',
                            component: ChooseEntries,
                            meta: {
                                breadcrumbTitle: 'Portfolio Builder - Choose Entries'
                            }
                        },
                        {
                            path: 'finish',
                            name: 'portfolio-builder-finish',
                            component: Finish,
                            meta: {
                                breadcrumbTitle: 'Portfolio Builder - Finish and Download'
                            }
                        }
                    ]
                },
                {
                    path: 'mentor/for-approval',
                    name: 'mentor/for-approval',
                    component: () => import('./views/mentoring/ForApproval'),
                    meta: {
                        role: 'Mentor',
                        breadcrumbTitle: 'Pending Approval'
                    }
                },
                {
                    path: 'mentor/view-dashboard',
                    name: 'mentor/view-dashboard',
                    component: () => import('./views/mentoring/ViewDashboard'),
                    meta: {
                        role: 'Mentor',
                        breadcrumbTitle: 'View Dashboard'
                    }
                },
                {
                    path: 'mentor/reviews',
                    name: 'mentor/reviews',
                    component: () => import('./views/mentoring/Reviews'),
                    meta: {
                        role: 'Mentor',
                        breadcrumbTitle: 'Complete Reviews'
                    }
                },
                {
                    path: 'manager/reporting',
                    name: 'manager/reporting',
                    component: () => import('./views/management/Reporting'),
                    meta: {
                        role: 'Manager',
                        breadcrumbTitle: 'Reporting'
                    }
                },
                {
                    path: 'admin/users',
                    name: 'admin/users',
                    component: () => import('./views/admin/UserManagement'),
                    meta: {
                        role: 'Admin',
                        breadcrumbTitle: 'User Management'
                    }
                },
                {
                    path: 'admin/configuration',
                    name: 'admin/configuration',
                    component: () => import('./views/admin/SystemConfiguration'),
                    meta: {
                        role: 'Superadmin',
                        breadcrumbTitle: 'System Configuration'
                    }
                },
                {
                    path: 'admin/activity-log',
                    name: 'admin/activity-log',
                    component: () => import('./views/admin/ActivityLog'),
                    meta: {
                        role: 'Admin',
                        breadcrumbTitle: 'Activity Log'
                    }
                },
                {
                    path: 'admin/shared-cpd',
                    name: 'admin/shared-cpd',
                    component: () => import('./views/admin/SharedCPD'),
                    meta: {
                        role: 'Admin',
                        breadcrumbTitle: 'Shared CPD'
                    }
                }
            ]
        },
        {
            path: '/brand',
            name: 'brand',
            component: () => import('./views/Brand.vue'),
            meta: {
                guest: true
            }
        },
        {
            path: '*',
            redirect: '/'
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (to.name) {
        // NProgress.start()
    }
    let isGuestRoute = to.meta.guest;
    let isAdminRoute = to.meta.admin;

    let isAdmin = false;

    let isLoggedIn = store.state.authentication.loggedIn;

    if (isGuestRoute !== true && !isLoggedIn) {
        next({
            name: 'login',
            params: { nextUrl: to.fullPath }
        });
        return;
    } else if (isAdminRoute && !isAdmin) {
        next({
            name: 'home'
        });
    }
    next();
});

export default router;
