<template>
    <img alt="Profile Picture" :src="cacheBustingUrl" class="profile-image" :style="imgStyle"/>
</template>

<script>
    export default {
        name: "ProfileImage",
        props: {
            profileImageUrl: {
                required: false,
                type: String
            },
            width: {
                required: false,
                type: Number,
                default: 120
            }
        },
        data() {
            return {
                cacheBuster: '',
            }
        },
        computed: {
            imgStyle() {
                return {
                    'width': this.width + 'px',
                    'height': this.width + 'px',
                    '-webkit-border-radius': Math.floor(this.width / 2) + 'px',
                    '-moz-border-radius': Math.floor(this.width / 2) + 'px',
                    'border-radius': Math.floor(this.width / 2) + 'px',
                }
            },
            cacheBustingUrl() {
                return this.profileImageUrl + '&t=' + this.cacheBuster;
            }
        },
        methods: {
            refresh() {
                this.cacheBuster = new Date().getTime();
            }
        },
        created() {
            this.refresh();
        }
    }
</script>

<style scoped>

    .profile-image {
        border-color: #2c3e50;
        border-width: 2px;
        border-style: solid;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
</style>