import Vue from "vue";

export default {

    settings() {
        return Vue.http.get('settings/dashboard');
    },
    update(settings) {
        return Vue.http.put('settings/dashboard', settings);
    }

}