import Vue from "vue";

export default {
    roles() {
        return Vue.http.get('admin/role-management/roles');
    },
    addRole(role) {
        return Vue.http.post('admin/role-management/role', role);
    },
    role(roleId) {
        return Vue.http.get(`admin/role-management/role/${roleId}`);
    },
    updateRole(roleId, role) {
        return Vue.http.put(`admin/role-management/role/${roleId}`, role);
    },
    trainingItems() {
        return Vue.http.get('admin/training-configuration/training-items');
    },
    trainingItem(trainingItemId) {
        return Vue.http.get(`admin/training-configuration/training-item/${trainingItemId}`);
    },
    createTrainingItem(trainingItem) {
        return Vue.http.post('admin/training-configuration/training-item', trainingItem);
    },
    updateTrainingItem(trainingItemId, trainingItem) {
        return Vue.http.put(`admin/training-configuration/training-item/${trainingItemId}`, trainingItem);
    },
    archiveTrainingItem(trainingItemId) {
        return Vue.http.post(`admin/training-configuration/training-item/${trainingItemId}/archive`);
    },
    unarchiveTrainingItem(trainingItemId) {
        return Vue.http.delete(`admin/training-configuration/training-item/${trainingItemId}/archive`);
    },
    allSkills() {
        return Vue.http.get('admin/clinical-skills/skills');
    },
    clinicalSkillsGroups() {
        return Vue.http.get('admin/clinical-skills/groups');
    },
    clinicalSkillsGroup(groupId) {
        return Vue.http.get(`admin/clinical-skills/group/${groupId}`);
    },
    createClinicalSkillsGroup(name) {
        return Vue.http.post('admin/clinical-skills/group', {name});
    },
    updateClinicalSkillsGroup(groupId, group) {
        return Vue.http.put(`admin/clinical-skills/group/${groupId}`, group);
    },
    createClinicalSkill(groupId, name, expiry, roles) {
        return Vue.http.post(`admin/clinical-skills/group/${groupId}/skill`, {name, expiry, roles});
    },
    clinicalSkills(groupId) {
        return Vue.http.get(`admin/clinical-skills/group/${groupId}/skills`);
    },
    clinicalSkill(groupId, skillId) {
        return Vue.http.get(`admin/clinical-skills/group/${groupId}/skill/${skillId}`);
    },
    updateClinicalSkill(groupId, skillId, skill) {
        return Vue.http.put(`admin/clinical-skills/group/${groupId}/skill/${skillId}`, skill);
    },
    archiveClinicalSkill(groupId, skillId) {
        return Vue.http.post(`admin/clinical-skills/group/${groupId}/skill/${skillId}/archive`);
    },
    unarchiveClinicalSkill(groupId, skillId) {
        return Vue.http.delete(`admin/clinical-skills/group/${groupId}/skill/${skillId}/archive`);
    },
    units() {
        return Vue.http.get('admin/organisation-management/units');
    },
    unit(unitId) {
        return Vue.http.get(`admin/organisation-management/unit/${unitId}`);
    },
    updateUnit(unitId, unit) {
        return Vue.http.put(`admin/organisation-management/unit/${unitId}`, unit);
    },
    allFacilities() {
        return Vue.http.get('admin/organisation-management/facility-options');
    },
    facilities(unitId) {
        return Vue.http.get(`admin/organisation-management/unit/${unitId}/facilities`);
    },
    facility(unitId, facilityId) {
        return Vue.http.get(`admin/organisation-management/unit/${unitId}/facility/${facilityId}`);
    },
    updateFacility(unitId, facilityId, facility) {
        return Vue.http.put(`admin/organisation-management/unit/${unitId}/facility/${facilityId}`, facility)
    },
    createFacility(unitId, name) {
        return Vue.http.post(`admin/organisation-management/unit/${unitId}/facility`, {name});
    },
    createUnit(name, logo) {
        return Vue.http.post('admin/organisation-management/unit', {name, logo});
    },
    scopesOfPracticeGroups() {
        return Vue.http.get('admin/sop-configuration/groups');
    },
    addScopeOfPracticeGroup(group) {
        return Vue.http.post('admin/sop-configuration/group', group)
    },
    scopeOfPracticeGroup(groupId) {
        return Vue.http.get(`admin/sop-configuration/group/${groupId}`);
    },
    updateScopeOfPracticeGroup(groupId, group) {
        return Vue.http.put(`admin/sop-configuration/group/${groupId}`, group);
    },
    scopeOfPracticeCategories(groupId) {
        return Vue.http.get(`admin/sop-configuration/group/${groupId}/categories`);
    },
    scopeOfPracticeCategory(groupId, categoryId) {
        return Vue.http.get(`admin/sop-configuration/group/${groupId}/category/${categoryId}`);
    },
    createScopeOfPracticeCategory(groupId, category) {
        return Vue.http.post(`admin/sop-configuration/group/${groupId}/category`, category);
    },
    updateScopeOfPracticeCategory(groupId, categoryId, category) {
        return Vue.http.put(`admin/sop-configuration/group/${groupId}/category/${categoryId}`, category);
    },
    scopesOfPractice(groupId, categoryId) {
        return Vue.http.get(`admin/sop-configuration/group/${groupId}/category/${categoryId}/scopes-of-practice`);
    },
    scopeOfPractice(groupId, categoryId, scopeOfPracticeId) {
        return Vue.http.get(`admin/sop-configuration/group/${groupId}/category/${categoryId}/scope-of-practice/${scopeOfPracticeId}`);
    },
    createScopeOfPractice(groupId, categoryId, scopeOfPractice) {
        return Vue.http.post(`admin/sop-configuration/group/${groupId}/category/${categoryId}/scope-of-practice`, scopeOfPractice);
    },
    updateScopeOfPractice(groupId, categoryId, scopeOfPracticeId, scopeOfPractice) {
        return Vue.http.put(`admin/sop-configuration/group/${groupId}/category/${categoryId}/scope-of-practice/${scopeOfPracticeId}`, scopeOfPractice);
    },
    archiveScopeOfPractice(groupId, categoryId, scopeOfPracticeId) {
        return Vue.http.post(`admin/sop-configuration/group/${groupId}/category/${categoryId}/scope-of-practice/${scopeOfPracticeId}/archive`);
    },
    unarchiveScopeOfPractice(groupId, categoryId, scopeOfPracticeId) {
        return Vue.http.delete(`admin/sop-configuration/group/${groupId}/category/${categoryId}/scope-of-practice/${scopeOfPracticeId}/archive`);
    },
}