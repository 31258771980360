const FormatterUtils = {

    hoursToString(value) {
        let mins = value * 60;
        let hours = Math.floor(mins / 60);
        let minutes = mins % 60;

        let str = '';
        if (hours > 0) {
            str += hours + ' hours';
        }
        if (minutes > 0) {
            str += ' ' + Math.floor(minutes) + ' minutes';
        }

        return str;
    },

    capitalize(str) {
        if (!str || str.length === 0) {
            return str;
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

};

export default FormatterUtils;