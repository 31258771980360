let approvalInfo = {
    required: true,
    approverType: 'Mentor',
    mentor: null,
    clinicianName: '',
    clinicianJobTitle: '',
    clinicianEmail: ''
};

export default approvalInfo;
