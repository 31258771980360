<template>
    <div class="speech-bubble" :class="{success: this.variant === 'success', warning: this.variant === 'warning'}">
        <slot></slot>
        <span class="username" v-if="author">{{author}}</span>
    </div>
</template>

<script>
    export default {
        name: "SpeechBubble",
        props: {
            author: {
                type: String,
                required: false,
                default: ''
            },
            variant: {
                type: String,
                required: false,
                default: 'success'
            }
        },
        computed: {
            borderColour() {
                if (this.variant === 'success') {
                    return '#56b273';
                } else if (this.variant === 'warning') {
                    return '#ff8750';
                }
                return '#26272b';
            }
        }
    }
</script>

<style scoped lang="scss">

    $color1 :     #161719;
    $color2 :     #26272b;
    $text :       #9fa2a7;
    $highlight1 : #56b273;
    $highlight2 : #b388dd;
    $highlight3 : #ff8750;
    $highlight4 : #01AD9B;

    $backgroundColour: $light;

    .speech-bubble.success {
        border-color: $highlight1;
    }

    .speech-bubble.warning {
        border-color: $highlight3;
    }

    .speech-bubble {
        background-color: $backgroundColour;
        color: $dark;
        font-size: .8em;
        line-height: 1.75;
        padding: 15px 25px;
        margin-bottom: 10px;
        cursor: default;

        border-left: 5px solid;
        border-color: $primary;

        &:nth-child(2n):after {
            content: '';
            margin-top: -10px;
            padding-top: 0px;
            position: relative;
            bottom: -25px;
            left: 20px;
            border-width: 10px 0 0 10px;
            border-style: solid;
            border-color: $backgroundColour transparent;
            display: block;
            width: 0;
        }

        .username {
            display: inline;
            font-style: italic;
            float: right;

            &:before {
                content: '- ';
            }
        }
    }
</style>