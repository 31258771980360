<template>
    <div class="mandatory-training-statistics">
        <b-card header="Statistics">
            <b-overlay :show="loading">
                <b-row>
                    <b-col md="6">

                        <b-row>
                            <b-col cols="12">
                                <b-card-group deck>
                                    <TopStatistic header="Compliance" :value="trainingCompliance" small />
                                    <TopStatistic header="Completed" :value="completedCount" small />
                                </b-card-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="6">
                        <PieGraph :height="200" :chart-data="statusBreakdown.chartData" :options="statusBreakdown.options" v-if="statusBreakdown"/>
                    </b-col>
                </b-row>
            </b-overlay>
        </b-card>
    </div>
</template>

<script>
    import TopStatistic from 'cpdcloud-dashboard/src/components/common/TopStatistic';
    import Providers from "../../providers";
    import graphUtilsMixin from "../../mixins/graphUtils";
    import PieGraph from "../graphs/PieGraph";

    export default {
        name: "Statistics",
        components: {TopStatistic, PieGraph},
        mixins: [graphUtilsMixin],
        data() {
            return {
                statistics: null,
                loading: false,
            }
        },
        computed: {
            trainingCompliance() {
                if (this.statistics === null || this.statistics.total === 0) {
                    return '--'
                }
                return ((this.statistics.approved / this.statistics.total) * 100).toFixed(2) + '%'
            },
            completedCount() {
                if (this.statistics === null) {
                    return '--';
                }
                return this.statistics.approved + ' / ' + this.statistics.total;
            },
            statusBreakdown() {
                if (this.statistics == null) {
                    return null;
                }
                return this.$transformToChartData(this.statistics.statusBreakdown);
            }
        },
        methods: {
            loadStatistics() {
                Providers.training.statistics().then((resp) => {
                    this.statistics = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load statistics', resp);
                });
            }
        },
        created() {
            this.loadStatistics();
        }
    }
</script>

<style scoped>

</style>
