<template>
    <div class="dropdown">
        <b-dropdown :size="size" :text="currentDisplayValue" :variant="variant" :disabled="disabled" :class="{fill:fill}">
            <b-dropdown-header>{{displayTitle}}</b-dropdown-header>
            <b-dropdown-item v-if="includeEmpty" @click="changeState(null)">{{emptyTitle}}</b-dropdown-item>
            <b-dropdown-item v-for="option in options" v-bind:key="option.value" @click="changeState(option.value)">{{option.title}}</b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
    /*
            Options should be passed in like:
            [
                {
                    title: '',
                    value: ''
                }
            ]
     */

    export default {
        name: "Dropdown",
        props: {
            size: {
                type: String,
                required: false,
                default: 'md'
            },
            variant: {
                type: String,
                required: false,
                default: 'info'
            },
            options: {
                type: Array,
                required: true
            },
            title: {
                type: String,
                required: false,
                default: ''
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            value: {
                type: String,
                required: false,
                default: null
            },
            showTitle: {
                type: Boolean,
                required: false,
                default: false
            },
            emptyTitle: {
                type: String,
                required: false,
                default: 'None'
            },
            includeEmpty: {
                type: Boolean,
                required: false,
                default: false
            },
            fill: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            displayTitle() {
                return `Select ${this.title}`.trim();
            },
            currentDisplayValue() {
                for (let option of this.options) {
                    if (option.value === this.value) {
                        return this.showTitle ? this.title + ': ' + option.title : option.title;
                    }
                }
                return this.emptyTitle;
            }
        },
        methods: {
            changeState(value) {
                this.$emit('input', value);
            }
        }

    }
</script>

<style scoped>
    .fill {
        width: 100%;
    }
</style>