<template>
    <b-modal ref="changePasswordModal" size="md" title="Change Password" header-bg-variant="primary" header-text-variant="light" no-close-on-backdrop no-close-on-esc>

        <b-form-group label="Current Password">
            <b-input type="password" v-model="request.oldPassword" />
        </b-form-group>

        <b-form-group label="New Password">
            <b-input type="password" v-model="request.newPassword" />
        </b-form-group>

        <div slot="modal-footer">
            <b-button variant="light" size="md" class="mr-2" @click="close" :disabled="loading">Cancel</b-button>
            <b-button variant="primary" size="md" @click="changePassword" :disabled="loading">Change Password</b-button>
        </div>

    </b-modal>
</template>

<script>
    import Providers from '../../providers/index';

    export default {
        name: "ChangePasswordModal",
        data() {
            return {
                request: {
                    oldPassword: '',
                    newPassword: ''
                },
                loading: false
            }
        },
        methods: {
            close() {
                this.reset();
                this.$refs.changePasswordModal.hide();
            },
            show() {
                this.$refs.changePasswordModal.show();
            },
            changePassword() {
                this.loading = true;
                Providers.authentication.changePassword(this.request.oldPassword, this.request.newPassword).then(() => {
                    this.$success('Password Changed', 'Your password was changed successfully');
                    this.close();
                }).catch((resp) => {
                    this.$error('Failed to change password', resp.body.message);
                }).finally(() => {
                    this.loading = false;
                })
            },
            reset() {
                this.request.oldPassword = '';
                this.request.newPassword = '';
                this.loading = false;
            }
        }
    }
</script>

<style scoped>

</style>