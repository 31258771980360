import authentication from "./authentication";
import statistics from "./statistics";
import forms from "./forms";
import entries from './entries'
import profile from './profile';
import sharedCpd from './shared-cpd';
import training from './training';
import clinicalSkills from './clinical-skils';
import clinicalExposure from './clinical-exposure';
import branding from "./branding";
import settings from "./settings";
import mentees from "./mentees";
import files from "./files";
import information from "./information";
import allEntries from './all-entries';
import activityLog from './activity-log';
import portfolioBuilder from './portfolio-builder';

import admin from './admin';
import manager from './manager';
import mentor from './mentor';
import externalAccess from './external-access';

export default {
    admin: admin,
    manager: manager,
    mentor: mentor,
    external: externalAccess,
    authentication: authentication,
    allEntries: allEntries,
    statistics: statistics,
    forms: forms,
    entries: entries,
    profile: profile,
    sharedCPD: sharedCpd,
    training: training,
    clinicalSkills: clinicalSkills,
    clinicalExposure: clinicalExposure,
    branding: branding,
    settings: settings,
    mentees: mentees,
    files: files,
    information: information,
    activityLog: activityLog,
    portfolioBuilder: portfolioBuilder,
}
