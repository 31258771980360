<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  import Managers from './managers/index';

  export default {
    name: "App",
    components: {},
    created() {
        Managers.authentication.configureHttpClient();
    }
  }
</script>

<style lang="scss">

</style>
