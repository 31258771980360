<template>
    <div>
        <b-alert variant="warning" show v-if="show">
            <b-row>
                <b-col cols="8" md="10" style="padding-top: 8px;">
                    <LockIcon /> Two-factor authentication is currently turned off for your account. To increase security, please enable it.
                </b-col>
                <b-col cols="4" md="2" style="text-align: right;">
                    <LoadingButton :loading="loading" variant="success" size="sm" @click="turnOn()" style="">Turn on</LoadingButton>
                </b-col>
            </b-row>
        </b-alert>
    </div>
</template>

<script>

import LockIcon from 'vue-material-design-icons/Lock';
import LoadingButton from 'cpdcloud-dashboard/src/components/common/LoadingButton';
import Providers from '../../providers';

export default {
    name: "TwoFactorUpsellNotice",
    components: {LockIcon, LoadingButton},
    data() {
        return {
            show: false,
            loading: false,
        }
    },
    methods: {
        loadStatus() {
            Providers.authentication.has2FA().then((has2FA) => {
                this.show = !has2FA;
            });
        },
        turnOn() {
            this.loading = true;
            Providers.authentication.enable2FA().then(() => {
                this.show = false;
                this.$success('Two-factor login Enabled', 'Next time you login, you may be asked to verify using your email or phone number.')
            }).catch((resp) => {
                this.$httpError('Failed to enable two-factor login', resp);
            }).finally(() => {
                this.loading = false;
            });
        }
    },
    created() {
        this.loadStatus();
    }
}
</script>

<style scoped>

</style>
