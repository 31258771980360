<template>
    <div class="pdp-guidance">
        <p>Your PDP is a rolling area to plan how you will develop your professional practice.
            This will be discussed at annual review with your mentor.</p>

        <p>To keep it meaningful, three to five open objectives are the norm.</p>

        <p>Keep your objectives SMART</p>
        <ul>
            <li>Specific</li>
            <li>Measurable</li>
            <li>Attainable</li>
            <li>Realistic and Relevant</li>
            <li>Time-bound</li>
        </ul>

    </div>
</template>

<script>
    export default {
        name: "PersonalDevelopmentPlanGuidance"
    }
</script>

<style scoped>

</style>