<template>
    <div class="top-banner">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "TopBanner"
    }
</script>

<style lang="scss" scoped>

</style>