<template>
    <div class="training-list">
        <b-table table-class="light" :items="items" :fields="fields" v-if="items.length > 0">
            <template v-slot:cell(actions)="data">
                <div class="actions">
                    <b-button variant="outline-dark" size="sm" target="_blank" v-if="data.item.trainingItem.link != null" :href="data.item.trainingItem.link"><OpenInNewIcon /> Open Training</b-button>
                </div>
            </template>
        </b-table>
        <b-alert show variant="primary" v-else>
            There are no training items of this type in your log.
        </b-alert>
    </div>
</template>

<script>
    import OpenInNewIcon from 'vue-material-design-icons/OpenInNew';

    export default {
        name: "TrainingList",
        components: {OpenInNewIcon},
        props: {
            items: {
                required: true,
                type: Array
            }
        },
        data() {
            return {
                fields: [
                    {
                        key: 'trainingItem.title',
                        label: 'Title',
                    },
                    {
                        key: 'status',
                        label: 'Status'
                    },
                    {
                        key: 'lastCompleted',
                        label: 'Last Completed'
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ]
            }
        }
    }
</script>

<style scoped>
    .actions > * {
        margin: 0 4px;
    }
</style>