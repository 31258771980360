import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from "bootstrap-vue";
import providerMixin from "./mixins/provider";
import VueResource from 'vue-resource';
import Vuex from 'vuex';

Vue.config.productionTip = false;
import './assets/css/custom.scss';
import "vue-material-design-icons/styles.css";
import toastMixin from "./mixins/toaster";
import RequireRole from "./directives/requireRole";
import IfWidgetEnabled from "./directives/ifWidgetEnabled";

import moment from 'moment'

Vue.use(BootstrapVue);
Vue.use(VueResource);
Vue.use(Vuex);

Vue.mixin(providerMixin);
Vue.mixin(toastMixin);

Vue.directive('requires-role', RequireRole);
Vue.directive('if-widget-enabled', IfWidgetEnabled);

Vue.prototype.moment = moment;

Vue.http.options.root = process.env.VUE_APP_API_ROOT;
Vue.http.interceptors.push(function(request) {
  return function(response) {
    if (response.status === 401 && !request.url.startsWith('auth/')) { /* ignore any 401's from authentication endpoints */
      store.dispatch("authentication/logout");
      router.push({'name': 'login'})
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
