<template>
    <div class="guidance">
        <b-card title="Guidance">
            <p>These are the skills which you need to do your job. When you have successfully completed a skill or ‘competency’ you can record as many as are relevant and send them for mentor or supervisor approval. They are divided into these groups:</p>
            <ul>
                <li>Practice related to Pre-Hospital Emergency Care.</li>
                <li>Practice related to Primary Health Care and Force Health Protection</li>
                <li>Practice related to the delivery of Operational Medical Support</li>
            </ul>
            <p>Each has a different timeframe for review and you and your mentor will receive emails to remind you which ones are due.</p>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: "Guidance"
    }
</script>

<style scoped>

</style>