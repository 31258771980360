<template>
    <div class="top-banner-notice">
        <b-alert :show="useOverride" variant="primary">
            <InformationOutlineIcon /> <b>You are viewing {{name}}'s dashboard. </b> Close this window or refresh the page to return to your account.
        </b-alert>
    </div>
</template>

<script>
    import InformationOutlineIcon from 'vue-material-design-icons/InformationOutline';
    import {mapGetters} from "vuex";
    import Managers from '../../managers/index';

    export default {
        name: "TopBannerNotice",
        components: {InformationOutlineIcon},
        data() {
            return {
                name: 'N/A'
            }
        },
        computed: {
            ...mapGetters({
                useOverride: 'session/useOverride'
            }),

        },
        created() {
            this.name = Managers.authentication.name();
        }
    }
</script>

<style scoped>

</style>