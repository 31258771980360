const state = {
    request: {
        include: ['FrontPage', 'ContentsPage', 'Profile', 'TrainingRecord', 'SkillsUsage', 'ClinicalExposure'],
        entries: {
            diary: [],
            reflection: []
        },
        startDate: null,
        endDate: null,
        options: {
            includeApprovalComments: false,
            includeAttachments: false,
        }
    }
};

const getters = {
};

const actions = {

};

const mutations = {
    updateIncludes(state, includes) {
        state.request.include = includes;
    },
    updateOptions(state, options) {
        state.request.options = options;
    },
    updateStartDate(state, startDate) {
        state.request.startDate = startDate;
    },
    updateEndDate(state, endDate) {
        state.request.endDate = endDate;
    },
    updateDiaryEntries(state, diaryEntries) {
        state.request.entries.diary = diaryEntries;
    },
    updateReflectiveEntries(state, reflectiveEntries) {
        state.request.entries.reflection = reflectiveEntries;
    },
    reset(state) {
        state.request = {
            include: ['FrontPage', 'ContentsPage', 'Profile', 'TrainingRecord', 'SkillsUsage', 'ClinicalExposure'],
            entries: {
                diary: [],
                reflection: []
            },
            startDate: null,
            endDate: null,
            options: {
                includeApprovalComments: false,
                includeAttachments: false,
            }
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}