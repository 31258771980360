<template>
    <div class="settings">
        <b-overlay :show="loading">
            <b-row v-if="settings">
                <b-col md="6" class="mt-4">
                    <b-card header="Dashboard Customisation">
                        <b-form-group label="Widgets" description="Decide what to show on your Dashboard Overview page.">
                            <b-form-checkbox v-model="settings.dashboardWidgets.cpdHours" switch>Total Hours of CPD Captured</b-form-checkbox>
                            <b-form-checkbox v-model="settings.dashboardWidgets.cceHours" switch>Total Hours of Clinical Exposure Captured</b-form-checkbox>
                            <b-form-checkbox v-model="settings.dashboardWidgets.hoursOverTime" switch>Hours of Activity Per Month</b-form-checkbox>
                            <b-form-checkbox v-model="settings.dashboardWidgets.activityOverTime" switch>Entries Submitted Per Month</b-form-checkbox>
                            <b-form-checkbox v-model="settings.dashboardWidgets.approvalStatus" switch>Approval Statuses</b-form-checkbox>
                            <b-form-checkbox v-model="settings.dashboardWidgets.topScopeOfPractice" switch>Top Scope of Practice Categories</b-form-checkbox>
                        </b-form-group>
                    </b-card>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col md="4" offset-md="4" offset-sm="0" sm="12">
                    <b-button block variant="success" :disabled="loading" @click="saveSettings"><content-save-icon /> Save Settings</b-button>
                </b-col>
            </b-row>
        </b-overlay>
    </div>
</template>

<script>
    import Providers from '../providers/index';
    import ContentSaveIcon from 'vue-material-design-icons/ContentSave';
    import EventBus from "../event-bus";

    export default {
        name: "Settings",
        components: {ContentSaveIcon},
        data() {
            return {
                loading: false,
                settings: null
            }
        },
        methods: {
            loadSettings() {
                this.loading = true;
                Providers.settings.settings().then((resp) => {
                    this.settings = resp.body;
                }).catch((resp) => {
                    this.$httpError('Failed to load settings', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
            saveSettings() {
                this.loading = true;
                Providers.settings.update(this.settings).then(() => {
                    EventBus.$emit('settings-updated');
                    this.$router.push({name: 'home'});
                    this.$success('Settings Saved', 'Your settings have been saved successfully');
                }).catch((resp) => {
                    this.$httpError('Failed to save settings', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        created() {
            this.loadSettings();
        }
    }
</script>

<style scoped>

</style>