import Vue from 'vue';

export default {
    filterOptions() {
        return Vue.http.get('management/reporting/filter-options');
    },
    report(unit, facility, role, mentor, dateFrom, dateTo) {
        return Vue.http.get(`management/reporting?unit=${unit || ''}&facility=${facility || ''}&role=${role || ''}&mentor=${mentor || ''}&from=${dateFrom}&to=${dateTo}`);
    }
}
