import { render, staticRenderFns } from "./AuthenticationPage.vue?vue&type=template&id=213d3cbb&scoped=true&"
import script from "./AuthenticationPage.vue?vue&type=script&lang=js&"
export * from "./AuthenticationPage.vue?vue&type=script&lang=js&"
import style0 from "./AuthenticationPage.vue?vue&type=style&index=0&id=213d3cbb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../.jenkins/workspace/DMSDashboardDeployLIVE/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "213d3cbb",
  null
  
)

export default component.exports