<template>
    <b-card bg-variant="default" text-variant="dark" header="Upload File">
        <b-form-file class="mb-2" ref="fileInput" v-model="selectedFile" />
        <p class="disclaimer">By uploading this file, you confirm that the information provided above is a true reflection of your personal development and does not contain any person, patient, client or service user identifiable data that could breach data protection laws or legislation relating to your employer, governing bodies, professional organisations, code of conduct and associated revalidation guidance.</p>
        <b-progress v-if="uploading" :value="uploadPercentage" :max="100" variant="info" class="mb-2" show-progress animated />
        <b-button type="submit" variant="outline-info" @click="uploadFile" :disabled="uploading || !selectedFile">{{uploadButtonText}}</b-button>
    </b-card>
</template>

<script>
    export default {
        name: "FileUploadCard",
        data() {
            return {
                selectedFile: null,
                uploadPercentage: 0,
                uploading: false,
            }
        },
        methods: {
            uploadFile() {
                if (this.selectedFile.size > 10 * 1024 * 1024) {
                    this.$error('File Size', 'The file size cannot exceed 10MB');
                    return;
                }
                let fileName = this.selectedFile.name;
                this.uploading = true;
                this.uploadPercentage = 0;

                let reader = new FileReader();
                let vueThis = this;
                reader.onload = (e) => {
                    let contentType = vueThis.selectedFile.type;
                    let data = e.target.result;
                    window.data = data;
                    this.$http.post(`file/upload?filename=${encodeURIComponent(fileName)}`, data, {
                        progress(e) {
                            if (e.lengthComputable) {
                                vueThis.uploadPercentage = e.loaded / e.total * 100;
                            }
                        },
                        headers: {
                            'Content-Type': contentType
                        }
                    }).then(() => {
                        this.$emit('file-uploaded');
                        this.$success('File Uploaded', 'Your file was uploaded successfully');
                        this.$refs.fileInput.reset();
                    }).finally(() => {
                        this.uploading = false;
                    }).catch((resp) => {
                        this.$httpError('Failed to upload file', resp);
                        this.uploading = false;
                    });
                };

                reader.readAsArrayBuffer(this.selectedFile);


            }
        },
        computed: {
            uploadButtonText() {
                if (this.uploading) {
                    return 'Please wait...';
                }
                return 'Upload';
            }
        }
    }
</script>

<style scoped>
    p.disclaimer {
        font-size: 11px;
    }
</style>