<template>
    <footer class="footer" v-bind:class="{'divider': showDivider}">Version {{ version }}. Copyright &copy; {{ copyrightOwner }} {{year}} </footer>
</template>

<script>
    export default {
        name: "Footer",
        props: {
            showDivider: {
                type: Boolean,
                required: false,
                default: true,
            },
            version: {
                type: String,
                required: false,
                default: process.env.APP_VERSION
            },
            copyrightOwner: {
                type: String,
                required: false,
                default: process.env.VUE_APP_COPYRIGHT_OWNER
            },
            copyrightYear: {
                type: String,
                required: false,
                default: process.env.VUE_APP_COPYRIGHT_FROM
            }
        },
        data() {
            return {
                yearOrigin: this.copyrightYear,
                yearNow: new Date().getUTCFullYear(),
            }
        },
        computed: {
            year: function() {
                if (this.yearOrigin !== this.yearNow) {
                    return this.yearOrigin + ' - ' + this.yearNow;
                } else {
                    return this.yearNow;
                }
            }
        }
    }
</script>

<style scoped>
    .footer {
        margin-top: 40px;
        margin-bottom: 50px;
        padding-top: 5px;
        text-align: center;
        font-size: 12px;
    }

    .footer.divider {
        border-top: lightgrey 1px solid;
    }
</style>