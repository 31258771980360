const state = {
    settings: null
};

// getters
const getters = {
    getSettings: (state) => {
        return state.settings;
    },
};

// mutations
const mutations = {
    storeSettings(state, settings) {
        state.settings = settings;
    },
};

// actions
const actions = {
    storeSettings: ({commit}, settings) => {
        commit('storeSettings', settings);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}