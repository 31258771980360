import jwt_decode from 'jwt-decode'

const state = {
    loggedIn: false,
    roles: [],
    loginToken: null,
    override: null
};

// getters
const getters = {
    isLoggedIn: (state) => {
        return state.loggedIn;
    },
    roles: (state) => {
        let token = jwt_decode(state.loginToken);
        return token.roles;
    },
    getToken: (state) => {
        return state.loginToken;
    },
    getOverrideToken: (state) => {
        return (state.override) ? state.override.loginToken : null;
    },
    getOverrideUser: (state) => {
        return (state.override) ? state.override.user : null;
    },
    hasOverride: (state) => {
        return state.override !== null;
    },
    overrideUser: (state) => {
        return state.override.user;
    }
};

// actions
const actions = {
    storeLoginDetails: ({commit}, {token, roles}) => {
        commit('storeLoginDetails', {token, roles});
        commit('setLoggedIn', true);
    },
    storeOverrideLoginDetails: ({commit}, {token, roles, user}) => {
        commit('storeOverrideLoginDetails', {token, roles, user});
    },
    setFullMembershipStatus: ({commit}, {fullMember}) => {
        commit('setFullMembershipStatus', {fullMember});
    },
    logout: ({commit}) => {
        commit('setLoggedIn', false);
    },
};

// mutations
const mutations = {
    storeLoginDetails(state, {token, roles}) {
        state.loginToken = token;
        state.roles = roles;
        state.loggedIn = true;
    },
    storeOverrideLoginDetails(state, {token, roles, user}) {
        state.override = {
            loginToken: token, roles: roles, user: user
        }
    },
    setLoggedIn(state, loggedIn) {
        state.loggedIn = loggedIn;
        if (!loggedIn) {
            state.loginToken = null;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}