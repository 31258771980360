<template>
    <div class="guidance">
        <b-card title="Guidance">
            <p>You are required to capture your hours of Clinical Exposure based upon the following types:</p>
            <ol>
                <li>Placement / Direct Patient Contact</li>
                <li>Simulation Training</li>
            </ol>
            Monitor your Clinical Exposure hours logged here based upon the requirements of your role and keep your record up-to-date.
        </b-card>
    </div>
</template>

<script>
    export default {
        name: "Guidance"
    }
</script>

<style scoped>

</style>