<template>
    <div class="finish">
        <b-card-group deck class="mt-4">

            <b-card title="Download Copy" header-class="d-flex h-100 align-items-center justify-content-center"
                    body-class="flex-column h-100"  footer-bg-variant="light">
                <p>Download a copy directly to your browser. Note that this may take anywhere from a few seconds to a few minutes, depending on the size of your portfolio.</p>
                <small>If you experience issues, choose the email option.</small>
                <template v-slot:footer>
                <LoadingButton  variant="info" @click="download" :loading="downloadLoading"><DownloadIcon /> Download Now</LoadingButton>
                </template>
            </b-card>


            <b-card title="Send via Email" header-class="d-flex h-100 align-items-center justify-content-center"
                    body-class="flex-column h-100" footer-bg-variant="light" style="display:none;">
                <p>Receive a copy of your portfolio to your email address, usually within a few minutes.</p>
                <template v-slot:footer>
                <b-button variant="info" disabled><EmailIcon /> Send via Email</b-button>
                </template>
            </b-card>


        </b-card-group>
    </div>
</template>

<script>
    import DownloadIcon from 'vue-material-design-icons/Download';
    import EmailIcon from 'vue-material-design-icons/Email';
    import Providers from '../../providers';
    import LoadingButton from 'cpdcloud-dashboard/src/components/common/LoadingButton';

    export default {
        name: "Finish",
        components: {DownloadIcon, EmailIcon, LoadingButton},
        computed: {
            request() {
                return this.$store.state.portfolioBuilder.request;
            },
        },
        data() {
            return {
                downloadLoading: false,
            }
        },
        methods: {
            download() {
                this.downloadLoading = true;
                Providers.portfolioBuilder.create(this.request).then((download) => {
                    download();
                    this.$success('Portfolio Downloaded', 'Your portfolio has downloaded successfully. Please check your browser downloads section.')
                }).catch((resp) => {
                    this.$httpError('Failed to download portfolio', resp);
                }).finally(() => {
                    this.downloadLoading = false;
                });
            }
        }
    }
</script>

<style scoped>
div.feature {
}
</style>