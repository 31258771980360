<template>
    <b-form-group :label="title" :description="helpText">
        <b-input :type="type" v-model="item.value" :state="fieldState()" autocomplete="off" @focus="showHelp()" @blur="hideHelp()"/>
        <i v-if="this.isHoursEntry">{{this.hoursDisplay}}</i>
        <b-form-invalid-feedback v-if="item.required">
            Required field
        </b-form-invalid-feedback>
        <p class="help-text mt-2" v-html="helpText"></p>
    </b-form-group>
</template>

<script>
    import FormItem from './FormItem'
    import FormatterUtils from "../../utilities/FormatterUtils";

    export default {
        name: "FreeTextFormItem",
        props: {
            type: {
                required: false,
                default: 'text'
            }
        },
        methods: {
          fieldState: function() {
              if (!this.item.required) {
                  return undefined;
              }
              return this.item.value !== '';
          }
        },
        computed: {
            title: function() {
                if (this.item.required) {
                    return this.item.title + ' *';
                }
                return this.item.title;
            },
            isHoursEntry: function() {
                return !!this.item.title.includes('Hours');
            },
            hoursDisplay: function() {
                if (this.isHoursEntry) {
                    return FormatterUtils.hoursToString(this.item.value);
                }
                return '';
            },
        },
        extends: FormItem,

    }
</script>

<style scoped>
    .help-text {
        font-size: 0.8rem;
    }
</style>