<template>
    <div class="header">
        <div class="nav-wrapper">
            <b-navbar type="dark" fixed="top" :toggleable="false">
                <img @click="showMenu" v-if="isMobile" style="height: 36px; margin-right: 10px;" src="../../assets/white-menu.png" />
                <b-navbar-brand style="margin-right: 5px;" to="/">
                    <img class="navbar-img" :src="logo" alt="Logo">
                    <b v-if="!isMobile">{{title}}</b>
                </b-navbar-brand>
                <b-navbar-brand v-if="breadcrumbTitle"><chevron-right v-if="!isMobile" /> {{breadcrumbTitle}}</b-navbar-brand>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item-dropdown right>
                        <template slot="button-content">
                            {{name}}
                        </template>
                        <b-dropdown-item @click="profile"><account-icon></account-icon> Profile</b-dropdown-item>
                        <b-dropdown-item @click="changePassword"><shield-account-icon></shield-account-icon> Change Password</b-dropdown-item>
                        <b-dropdown-item class="logout" @click="logout"><logout-icon></logout-icon> Logout</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-navbar>
        </div>

    </div>
</template>

<script>
    import ChevronRight from 'vue-material-design-icons/ChevronRight'
    import LogoutIcon from 'vue-material-design-icons/Logout';
    import ShieldAccountIcon from 'vue-material-design-icons/ShieldAccountOutline';
    import AccountIcon from 'vue-material-design-icons/AccountOutline';

    export default {
        name: "Header",
        components: {ChevronRight, LogoutIcon, AccountIcon, ShieldAccountIcon},
        props: {
            logo: String,
            title: String,
            breadcrumbTitle: String,
            name: String
        },
        data() {
            return {
                browserWidth: 0
            }
        },
        computed: {
            isMobile() {
                return this.browserWidth < 900;
            }
        },
        methods: {
            logout() {
                this.$emit('logout');
            },
            changePassword() {
                this.$emit('change-password');
            },
            profile() {
                this.$emit('profile');
            },
            showMenu() {
                this.$emit('show-menu');
            },
            handleResize() {
                this.browserWidth = window.innerWidth;
            }
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        }
    }
</script>

<style lang="scss" scoped>
    .navbar {
        /*background-color: #252525;*/
        background-color: $primary;
    }

    .navbar-brand {
        padding-top: .75rem;
        padding-bottom: .75rem;
        font-size: 1rem;
    }

    .navbar-img {
        height: 30px;
        margin-right: 20px;
    }

    .navbar .form-control {
        padding: .75rem 1rem;
        border-width: 0;
        border-radius: 0;
    }


    .nav-item {
        font-weight: bold;
        color: white;
    }

</style>