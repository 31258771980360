import Vue from "vue";

export default {
    availableSkills() {
        return Vue.http.get('clinical-skills');
    },
    logSkills(request) {
        return Vue.http.post('clinical-skills/log', request);
    },
    skillLog() {
        return Vue.http.get(`clinical-skills/log`);
    },
    additionalCompetencies() {
        return Vue.http.get('clinical-skills/additional');
    },
    addAdditionalCompetency(name, expiry) {
        return Vue.http.post('clinical-skills/additional', {name, expiry});
    },
    statistics(period) {
        return Vue.http.get(`clinical-skills/statistics?from=${period.from}&to=${period.to}`);
    }
}