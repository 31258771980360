<template>
    <div class="password-reset">
        <PasswordResetPage :loading="loading" @password-reset="doPasswordReset" />
    </div>
</template>

<script>
    import PasswordResetPage from 'cpdcloud-dashboard/src/components/authentication/PasswordResetPage';
    import Providers from '../../providers/index';

    export default {
        name: "PasswordReset",
        components: {PasswordResetPage},
        data() {
            return {
                loading: false
            }
        },
        methods: {
            doPasswordReset({email}) {
                this.loading = true;
                Providers.authentication.requestPasswordReset(email).then(() => {
                    this.$success('Password Reset', 'We\'ve sent an email to your registered email address. Please follow the instructions in that email.');
                    this.$router.push('login');
                }).catch((resp) => {
                    this.$httpError('Failed to request password reset', resp);
                }).finally(() => {
                    this.loading = false
                });
            }
        }
    }
</script>

<style scoped>

</style>