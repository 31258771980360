import Vue from "vue";

export default {
    previewEntry(entryId, itemType) {
        return Vue.http.get(`mentor/approvals/${itemType}/${entryId}`);
    },
    pendingApproval(page, perPage) {
        return Vue.http.get(`mentor/approvals/pending?page=${page}&per_page=${perPage}`)
    },
    approveEntry(entryId, itemType, comments) {
        return Vue.http.post(`mentor/approvals/${itemType}/${entryId}/approve`, {comments});
    },
    rejectEntry(entryId, itemType, comments) {
        return Vue.http.post(`mentor/approvals/${itemType}/${entryId}/reject`, {comments});
    }
}