<template>
    <div class="mobile-sidebar">
        <b-modal ref="modal" title="" size="full-screen" class="full-screen" hide-footer>
            <div class="mobile-sidebar-container">
                <SidebarContent :mobile="true" />
                <div class="button-container">
<!--                    <b-button variant="outline-dark" block @click="settings">Settings</b-button>-->
                    <b-button variant="outline-warning" block @click="logout">Logout</b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import Managers from '../../managers/index';
    import SidebarContent from "./SidebarContent";
    import EventBus from '../../event-bus';

    export default {
        name: "MobileSidebar",
        components: {SidebarContent},
        methods: {
            show() {
                this.$refs.modal.show();
            },
            sidebarItemClicked() {
                this.$refs.modal.hide();
            },
            settings() {
                this.$router.push({name: 'settings'});
                this.sidebarItemClicked();
            },
            logout() {
                Managers.authentication.logout();
                this.sidebarItemClicked();
            }
        },
        created() {
            EventBus.$on('sidebar-item-clicked', this.sidebarItemClicked);
        },
        destroyed() {
            EventBus.$off('sidebar-item-clicked', this.sidebarItemClicked);
        }
    }
</script>

<style>
    div.modal-dialog.modal-full-screen {
        width: 100% !important;
        max-width: none;
        margin: 0;
    }
</style>

<style scoped>
    .mobile-sidebar-container {
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
    }

    .button-container {
        padding: 20px;
    }


</style>