<template>
    <div class="choose-entry">
        <b-form-group :label="title">
            <v-select :options="entries" label="title" :reduce="option => option.id" v-model="modelValue"></v-select>
            <slot></slot>
        </b-form-group>
    </div>
</template>

<script>
    import VSelect from 'vue-select';
    import Providers from '../../providers';

    export default {
        name: "ChooseEntry",
        components: {VSelect},
        props: {
            title: {
                type: String,
                required: false,
                default: 'Choose Entry'
            },
            value: {
                required: true
            }
        },
        data() {
            return {
                entries: []
            }
        },
        computed: {
            modelValue: {
                get() { return this.value },
                set(v) { this.$emit("input", v) }
            }
        },
        methods: {
            loadEntries() {
                this.loading = true;
                Providers.allEntries.attachableEntries('Reflection').then((resp) => {
                    this.entries = resp.body.map(entry => ({
                        title: `${entry.startDate} - ${entry.title}`,
                        id: entry.entryId
                    }));
                }).catch((resp) => {
                    this.$httpError('Failed to load attachable entries', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        created() {
            this.loadEntries();
        }

    }
</script>

<style scoped>

</style>