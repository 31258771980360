<template>
    <div class="files">
        <b-row v-requires-role="'User'">
            <b-col md="6">
                <FileUploadCard @file-uploaded="fileUploaded" />
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col cols="12">
                <PaginatedFileList ref="fileList" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import FileUploadCard from "../components/files/FileUploadCard";
    import PaginatedFileList from "../components/files/PaginatedFileList";
    export default {
        name: "Files",
        components: {PaginatedFileList, FileUploadCard},
        data() {
            return {

            }
        },
        methods: {
            fileUploaded() {
                this.$refs.fileList.refresh();
            }
        }
    }
</script>

<style scoped>

</style>