<template>
    <div class="home">
        <b-row>
            <b-col cols="12">
                <ReportingPeriodSelector v-model="selectedPeriod" show-range />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <TopStatistics :statistics="statistics.entries" v-if="statistics"/>
            </b-col>
        </b-row>
        <Widgets :statistics="statistics" v-if="statistics" />
<!--        <AddPhoneNumberModal ref="addPhoneNumber" />-->
    </div>
</template>

<script>
    import TopStatistics from "../components/home/TopStatistics";
    import Providers from "../providers";
    import EventBus from "../event-bus";
    import Widgets from "../components/home/Widgets";
    import ReportingPeriodSelector from "../components/common/ReportingPeriodSelector";

    export default {
        name: "Home",
        components: {ReportingPeriodSelector, Widgets,TopStatistics},
        data() {
            return {
                loading: false,
                statistics: null,
                selectedPeriod: null,
            }
        },
        watch: {
            selectedPeriod() {
                this.loadStatistics();
            }
        },
        methods: {
            loadStatistics() {
                if (this.selectedPeriod === null)
                    return;
                this.loading = true;
                Providers.statistics.statistics(this.selectedPeriod).then((resp) => {
                    this.statistics = resp.body
                }).catch((resp) => {
                    this.$httpError('Failed to load statistics', resp);
                }).finally(() => {
                    this.loading = false;
                })
            },
        },
        created() {
            this.loadStatistics();
            if (this.$route.params.override) {
                EventBus.$emit('override-login', this.$route.params.override);
            }
        },
        mounted() {
            // check if we need to populate a phone number
            // this.$refs.addPhoneNumber.show();
        }
    }
</script>

<style scoped>

</style>
