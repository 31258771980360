<template>
    <div class="profile">
        <div class="loaded" v-if="profile">
            <b-row v-if="userInformation">
                <b-col md="6">
                    <ProfileBasicDetails :user-information="userInformation" />
                </b-col>
                <b-col md="6">
                    <ContactInformation :user-information="userInformation" @contact-updated="loadProfile" />
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col cols="12">
                    <ProfileAdditionalInformation :profile="profile" @profile-changed="profileChanged" />
                </b-col>
            </b-row>
            <b-row class="mt-4" v-if="needsSaving">
                <b-col cols="12" style="text-align:center;">
                    <b-button variant="success" @click="save">Save Changes</b-button>
                </b-col>
            </b-row>
        </div>

    </div>
</template>

<script>
    import ProfileBasicDetails from "../components/profile/ProfileBasicDetails";
    import ProfileAdditionalInformation from "../components/profile/ProfileAdditionalInformation";
    import Providers from '../providers/index';
    import ContactInformation from "../components/profile/ContactInformation";
    export default {
        name: "Profile",
        components: {ContactInformation, ProfileAdditionalInformation, ProfileBasicDetails},
        data() {
            return {
                userInformation: null,
                profile: null,
                needsSaving: false,
                loading: false,
                jobRoles: [
                    `CMT1`,
                    `Reserve CMT 2`,
                    `Reserve CMT 3`,
                    `Mil Paramedic`,
                    `Mil Nurse`
                ],
                jobRole: ['CMT1']

            }
        },
        methods: {
            profileChanged() {
                this.needsSaving = true;
            },
            save() {
                this.loading = true;
                Providers.profile.saveProfile(this.profile).then(() => {
                    this.$success('Saved Profile', 'Your profile has been updated successfully.');
                    this.needsSaving = false;
                    this.loadProfile();
                }).catch((resp) => {
                    this.$httpError('Failed to update profile', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            loadProfile() {
                this.loading = true;
                Providers.profile.profile().then((resp) => {
                    this.profile = resp.body;
                }).then(() => {
                    return Providers.profile.userInformation().then((resp) => {
                        this.userInformation = resp.body;
                    });
                }).catch((resp) => {
                    this.$httpError('Failed to get profile', resp);
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        created() {
            this.loadProfile();
        }
    }
</script>

<style scoped>

</style>