<template>
    <div class="shared-cpd">
        <b-row>
            <b-col cols="12" class="mb-4">
                <b-form :inline="true" @submit.prevent="refresh">

                    <b-form-group label="Sort By" class="mr-4">
                        <b-form-radio-group
                                id="btn-radios-2"
                                v-model="sortBy"
                                :options="sortOptions"
                                buttons
                                size="md"
                                name="radio-btn-outline"
                                @change="sortUpdated"
                        ></b-form-radio-group>
                    </b-form-group>
                    <b-form-group label="Category" class="mr-4">
                        <b-dropdown :text="filterCategoryText" class="" :disabled="loading" size="md">
                            <b-dropdown-header>Select Category</b-dropdown-header>
                            <b-dropdown-item v-for="category in categoryValues" v-bind:key="category.value" @click="changeCategory(category.value)">{{category.title}}</b-dropdown-item>
                        </b-dropdown>
                    </b-form-group>
                    <b-form-group label="Search" class="mr-4">
                        <b-input-group>
                            <b-input type="text" placeholder="Search..." v-model="filter.text" :readonly="loading"/>
                            <b-input-group-append>
                                <b-button type="submit" variant="dark" :disabled="loading">Search</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                </b-form>
            </b-col>
        </b-row>

        <b-row v-if="totalItems === 0">
            <b-col sm="12">
                <b-alert show variant="info">
                    <h4 class="alert-heading">No Results</h4>
                    <p class="mb-0">
                        There is no Shared CPD matching your search.
                    </p>
                </b-alert>
            </b-col>
        </b-row>

        <b-row >
            <b-col sm="6" class="mb-4" v-for="(item, idx) of items" v-bind:key="idx">
                <SharedCPDListItem :shared-cpd-id="item.id"
                                   :title="item.title"
                                   :description="item.description"
                                   :avatar-url="item.avatarUrl"
                                   :category="item.category"
                                   :nickname="item.nickname"
                                   :date="item.date"
                                   :website-url="item.websiteUrl"
                                   :upvotes="item.upvotes"
                                   :has-upvoted="item.hasUpvoted"
                                   :show-helpful="true" />
            </b-col>

        </b-row>
    </div>
</template>

<script>
    import Providers from '../providers/index';
    import SharedCPDListItem from "../components/shared-cpd/SharedCPDListItem";

    export default {
        name: "SharedCPD",
        components: {SharedCPDListItem},
        data: function() {
            return {
                loading: true,
                items: [],
                itemsPerPage: 10,
                currentPage: 0,
                totalItems: -1,
                sortOptions: ['Newest', 'Most Helpful'],
                sortBy: 'Newest',
                filter: {
                    text: '',
                    category: ''
                },
                categories: [
                ],
                moment: require('moment')

            }
        },
        computed: {
            filterCategoryText() {
                if (this.filter.category === '') {
                    return 'All';
                }
                return this.filter.category;
            },
            categoryValues() {
                let values = [];
                values.push({ value: '', title: 'All'});
                let categories = [];
                for (let cat of this.categories) {
                    categories.push({ value: cat, title: cat });
                }
                values.push(...categories);
                return values;
            },
            sortParam() {
                if (this.sortBy === 'Most Helpful') {
                    return 'helpful'
                } else {
                    return 'newest'
                }
            }
        },
        methods: {
            loadData: function() {
                this.loading = true;
                Providers.sharedCPD.sharedCPD(this.itemsPerPage, this.currentPage).then((resp) => {
                    let body = resp.data;
                    this.totalItems = body.totalItems;
                    this.items.push(...body.items.map(item => {
                        item.date = this.moment(item.date).format('DD/MM/YYYY');
                        return item;
                    }));
                }).catch((resp) => {
                    this.$httpError('Failed to load Shared CPD', resp);
                }).then(() => {
                    this.loading = false;
                });
            },
            nextPage: function() {
                if (this.totalItems === -1 || this.currentPage < Math.ceil(this.totalItems / this.itemsPerPage)) {
                    this.currentPage++;
                    this.loadData();
                }

            },
            scroll () {
                window.onscroll = () => {
                    let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.scrollHeight;

                    if (bottomOfWindow) {
                        this.nextPage();
                    }
                }
            },
            refresh() {
                this.items = [];
                this.currentPage = 1;
                this.loadData();
            },
            sortUpdated(newValue) {
                this.sortBy = newValue;
                this.refresh();
            },
            changeCategory(category) {
                this.filter.category = category;
                this.refresh();
            },
            loadCategories() {
                // this.$http.get('shared-cpd/categories').then((resp) => {
                //     this.categories = resp.body;
                // })
                this.categories = [];
            }
        },
        created() {
            this.categories.sort();
            this.nextPage();
            this.loadCategories();
        },
        mounted () {
            this.scroll()
        }
    }
</script>

<style scoped>

</style>