const FormTools = {

    flattenPage: function(page) {
        return this.flattenItems(this.activatedItems(page));
    },

    flattenItems: function(items) {
        let data = new Map();
        for (let item of items) {
            if (item.type === 8) {
                if (item.value && item.value.length > 0) {
                    data.set(item.identifier, item.value.map(v => v.id))
                }
            } else {
                data.set(item.identifier, item.value);
            }
        }
        return data;
    },

    activatedItems: function(page) {
        let items = Array();

        for (let item of page.items) {
            items.push(item);
            if (!this.isEmptyObject(item.activators)) {
                for (let activatorKey in item.activators) {
                    if (Object.prototype.hasOwnProperty.call(item.activators, activatorKey) && activatorKey === item.value) { // TODO: check array of values
                        let activatedItems = item.activators[activatorKey];
                        for (let activatedItem of activatedItems) {
                            items.push(activatedItem);
                        }
                    }
                }
            }
        }

        return items;
    },

    mergeMaps: function(map1, map2) {
        let merged = new Map();
        map1.forEach((v, k) => merged.set(k, v));
        map2.forEach((v, k) => merged.set(k, v));
        return merged;
    },

    isEmptyObject: function(obj) {
        for(let prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }
        return true;
    },

    flatten: function(form) {
        let pages = form.pages;
        let data = new Map();
        for (let page of pages) {
            data = this.mergeMaps(data, this.flattenPage(page));
        }
        return data;
    },

    mapToJson: function(map) {
        let obj = Object.create(null);
        for (let [k,v] of map) {
            obj[k] = v;
        }
        return obj;
    }

};

export default FormTools;