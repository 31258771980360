<template>
    <div class="choose-entries">
        <b-overlay :show="loading" v-if="startDate && endDate">
            <h4 class="mt-2">Diary Entries</h4>
            <ChooseEntryTable :start-date="startDate" :end-date="endDate" v-model="diaryEntries" type="Diary" />

            <h4 class="mt-4">Reflective Entries</h4>
            <ChooseEntryTable :start-date="startDate" :end-date="endDate" v-model="reflectiveEntries" type="Reflection" />
        </b-overlay>
        <b-alert show v-else>You must choose a Start & End date first</b-alert>
    </div>
</template>

<script>
    import ChooseEntryTable from "../../components/portfolio-builder/ChooseEntryTable";

    export default {
        name: "ChooseEntries",
        components: {ChooseEntryTable},
        data() {
            return {
                loading: false,
            }
        },
        computed: {
            startDate() {
                return this.$store.state.portfolioBuilder.request.startDate;
            },
            endDate() {
                return this.$store.state.portfolioBuilder.request.endDate;
            },
            diaryEntries: {
                get() {
                    return this.$store.state.portfolioBuilder.request.entries.diary;
                },
                set(val) {
                    this.$store.commit('portfolioBuilder/updateDiaryEntries', val);
                }
            },
            reflectiveEntries: {
                get() {
                    return this.$store.state.portfolioBuilder.request.entries.reflection;
                },
                set(val) {
                    this.$store.commit('portfolioBuilder/updateReflectiveEntries', val);
                }
            }
        },
    }
</script>

<style scoped>

</style>