import store from '../store/index';
import Managers from '../managers/index';

function updateStyle(el, binding) {

    if (Managers.authentication.roles().includes(binding.value)) {
        el.style.display = el.__disp_prev__;
    } else {
        el.style.display = 'none';
    }
}

const RequireRole = {
    bind: function (el, binding) {
        el.__disp_prev__ = el.style.display;
        updateStyle(el, binding);
        el.__roles_unwatch__ = store.watch(state => state.authentication.loginToken, () => {
            updateStyle(el, binding);
        });
    },

    unbind(el) {
        el.__roles_unwatch__ && el.__roles_unwatch__()
    }

};

export default RequireRole;
