<template>
    <div class="log-clinical-skill-modal">
        <b-modal ref="modal" header-bg-variant="primary" header-text-variant="light" title="Log Clinical Skills" no-close-on-backdrop no-close-on-esc
                 ok-title="Add" @ok="addSkills" :ok-disabled="loading" :cancel-disabled="loading" size="lg">
            <b-overlay :show="loading">
                <b-form-group label="Clinical Skills">
                    <v-select :options="clinicalSkills" label="text" v-model="request.skillIds" :reduce="option => option.value" placeholder="Please select skills" multiple>

                    </v-select>
                </b-form-group>
                <b-form-group label="Date Used">
                    <b-datepicker v-model="request.dateCompleted"></b-datepicker>
                </b-form-group>
                <b-form-group label="Description">
                    <b-textarea v-model="request.comments"></b-textarea>
                </b-form-group>
                <ChooseEntry title="Link a Reflection" v-model="request.linkedReflection">
                    <small>(Optional) You can link a reflection to support this entry. The entry will be visible by the approver chosen.</small>
                </ChooseEntry>
                <ScopeOfPractice :scope-of-practice-info="request.scopeOfPracticeInfo" />
                <ApprovalInfo :approval-info="request.approvalInfo" no-confirmation allow-external/>
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
    import Providers from '../../providers/index';
    import vSelect from 'vue-select'
    import ApprovalInfo from "../forms/ApprovalInfo";
    import DEFAULT_APPROVAL_INFO from '../../components/forms/defaultApprovalInfo';
    import ScopeOfPractice from "../forms/ScopeOfPractice";
    import ChooseEntry from "../entries/ChooseEntry";

    export default {
        name: "LogClinicalSkillModal",
        components: {ChooseEntry, ScopeOfPractice, ApprovalInfo, vSelect},
        data() {
            return {
                request: {
                    skillIds: [],
                    dateCompleted: null,
                    comments: '',
                    linkedReflection: null,
                    approvalInfo: {...DEFAULT_APPROVAL_INFO},
                    scopeOfPracticeInfo: {items: []}
                },
                clinicalSkills: [],
                loading: false
            }
        },
        methods: {
            show() {
                this.reset();
                this.loadClinicalSkills();
                this.$refs.modal.show();
            },
            hide() {
                this.$refs.modal.hide();
            },
            reset() {
                this.request = {
                    skillIds: [],
                    dateCompleted: null,
                    comments: '',
                    linkedReflection: null,
                    approvalInfo: {...DEFAULT_APPROVAL_INFO},
                    scopeOfPracticeInfo: {items: []}
                }
            },
            addSkills(e) {
                e.preventDefault();
                this.loading = true;
                Providers.clinicalSkills.logSkills(this.request).then(() => {
                    this.$success('Added Skills', 'The skills have been logged successfully');
                    this.$emit('skills-added');
                    this.hide();
                }).catch((resp) => {
                    this.$httpError('Failed to add Skills', resp);
                }).finally(() => {
                    this.loading = false;
                });
            },
            loadClinicalSkills() {
                this.loading = true;
                Providers.clinicalSkills.availableSkills().then((resp) => {
                    this.clinicalSkills = resp.body.map(skill => {
                        return { value: skill.id, text: skill.name }
                    })
                }).catch((resp) => {
                    this.$httpError('Failed to load available skills', resp);
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
        created() {
        }
    }
</script>

<style scoped>

</style>