<template>
    <b-card no-body class="mb-4" @change="profileChanged()">
        <b-tabs card>
            <b-tab title="Professional Information & Qualifications">
                <b-row>
                    <b-col lg="3">
                        <b-form-group label="DBS Number">
                            <b-form-input v-model="profile.dbsNumber" />
                        </b-form-group>
                    </b-col>
                    <b-col lg="3">
                        <fieldset class="form-group">
                            <legend class="col-form-label pt-0" style="width: 100%;">DBS Expiry Date <span v-if="dbsExpired" style="float:right;"><b-badge variant="danger">Expired</b-badge></span></legend>
                            <b-form-datepicker v-model="profile.dbsExpiry" @input="profileChanged" />
                        </fieldset>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Qualifications">
                            <b-textarea v-model="profile.qualifications"></b-textarea>
                            <small>{{wordCount(profile.qualifications)}} / 500 words</small>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group label="Summary of Recent Work">
                            <b-textarea v-model="profile.summaryOfWork"></b-textarea>
                            <small>{{wordCount(profile.summaryOfWork)}} / 500 words</small>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group label="Job Description">
                            <b-textarea v-model="profile.jobDescription"></b-textarea>
                            <small>{{wordCount(profile.jobDescription)}} / 500 words</small>
                        </b-form-group>
                    </b-col>

                </b-row>

            </b-tab>
            <b-tab title="Personal Statement">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Personal Statement">
                            <b-textarea v-model="profile.personalStatement"></b-textarea>
                            <small>{{wordCount(profile.personalStatement)}} / 1500 words</small>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Code of Conduct">
                <b-row>
                    <b-col cols="12">
<!--                        <b-form-group label="Do you agree to the above Code of Conduct?">-->
<!--                            <b-form-radio v-model="profile.statementAgreed" :value="true">I agree</b-form-radio>-->
<!--                            <b-form-radio v-model="profile.statementAgreed" :value="false">I disagree</b-form-radio>-->
<!--                        </b-form-group>-->
                        <Agreement v-model="profile.statementAgreed" />
                        <HealthAndConfidentialityStatement />
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Personal Development Plan">
                <b-row>
                    <b-col cols="12">
                        <PersonalDevelopmentPlanGuidance />
                    </b-col>
                    <b-col cols="12">
                        <PersonalDevelopmentPlan />
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Annual Reviews">
                <PreviousReviews />
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
    import WordCountUtils from 'cpdcloud-dashboard/src/utilities/WordCountUtils';
    import HealthAndConfidentialityStatement from "./HealthAndConfidentialityStatement";
    import PersonalDevelopmentPlanGuidance from "./PersonalDevelopmentPlanGuidance";
    import PersonalDevelopmentPlan from "./PersonalDevelopmentPlan";
    import Agreement from "./Agreement";
    import PreviousReviews from "./PreviousReviews";

    export default {
        name: "ProfileAdditionalInformation",
        components: {
            PreviousReviews,
            Agreement,
            PersonalDevelopmentPlan, PersonalDevelopmentPlanGuidance, HealthAndConfidentialityStatement},
        props: {
            profile: {
                type: Object,
                required: true
            }
        },
        computed: {
            dbsExpired() {
                if (this.profile.dbsExpiry) {
                    return this.moment(this.profile.dbsExpiry).isBefore(this.moment());
                }
                return false;
            }
        },
        methods: {
            wordCount(val) {
                return WordCountUtils.wordCount(val);
            },
            profileChanged() {
                this.$emit('profile-changed');
            }
        }
    }
</script>

<style scoped>

</style>