<template>
    <div class="disclaimer">
        <h2>COMBAT MEDICAL TECHNICIAN</h2>
        <h3>CODE OF CONDUCT</h3>
        <h4>The Combat Medical Technician</h4>
        <p>Health is vital to individual well-being and combat effectiveness and my role is to protect,
        promote, maintain and restore health on the battlefield and at home. I will make the best
        use of my knowledge and skills and the resources available to me to safely and effectively
        protect health, manage disease, support the injured and treat the wounded. I will assess
        and treat my patients based on clinical need alone, in accordance with the Laws of Armed
        Conflict and the principles of ethical practice. I will respect my patients at all times, deal
        with them honestly and protect their privacy. I am committed to continuous improvement
        and professional development and I will maintain and update the knowledge and skills I
        need. I am both medic and soldier, able to apply my skills in the most difficult and
        demanding environments. To justify the trust placed in me by my patients, fellow soldiers,
        my colleagues and the Army, I will abide by my Code of Conduct at all times.
        <br /><i>In arduis fidelis</i>.</p>



        <h4>1. You are accountable, at all times, for your actions.</h4>

        <h4>2. Your patients include the whole population at risk for whom you provide
            healthcare, including those who are currently well.</h4>

        <h4>3. You should always act in the best interests of your patients.</h4>
        <p><b>a. You must</b><br />
            (1) Make patient safety and wellbeing your prime concern. Whilst in most
            cases, even when deployed, this will mean prioritising the needs of those
            requiring medical care. In a combat situation you must apply professional
            military judgement to determine how best to maximise patient safety and
            minimise casualties within the bounds of the Law of Armed Conflict.<br />
            (2) Treat patients in accordance with clinical need and your ability to treat,
            applying resources effectively.<br />
            (3) Take all reasonable steps to make sure that you can communicate
            appropriately and effectively with patients<br />
            (4) Treat patients with respect, dignity and equality; respecting their
            autonomy.<br />
            (5) Obtain valid consent10 to provide care or services, wherever possible, prior
            to any examination, investigation, treatment or teaching activity. You must
            ensure that the patient understands your status if you are under training or
            returning to currency. There will be times, in an emergency for instance, where
            this is not always possible but you must ensure that you have made every
            possible effort to obtain the consent of the patient before treating them.<br />
            (6) Make sure that you take all appropriate steps to deal with the risks of
            infection, including cross-infection, adhering to local infection prevention and
            control practices at all times.<br />
            (7) Be active in safeguarding vulnerable patients, advocate for the right of
            patients to receive care and protect patients whilst they are being treated and
            recovering.<br />
            (8) Raise concerns where you identify unsafe clinical practice.<br />
            (9) Follow your education provider’s policies on ethics when carrying out any
            audit or research.
            For consent to be valid the patient be competent, have sufficient information to make an informed choice and able to
            give their consent freely. However, patients who lack capacity to give consent should not be denied necessary treatment
            simply because they are unable to consent to it.
        </p>
        <p>
            <b>b. You should not</b><br />
            (1) Work outside your knowledge, skills and experience in a way that will
            cause harm or risk patient safety.<br />
            (2) Routinely provide healthcare to family and close friends.
        </p>


        <h4>4. You should respect the confidentiality of your patients.</h4>
        <p><b>a. You must</b><br />
            (1) Understand and abide by the Caldicott Principles on Patient Confidentiality
            and by relevant Data Protection Acts.<br />
            (2) Respect the confidentiality of information you have about patients and only
            use that information for the purpose for which it was given.<br />
            (3) Abide by local policies on patient confidentiality and the use of
            chaperones.<br />
            (4) Remove anything that could be used to identify a patient from confidential
            information which you use in your CMT Portfolio or other educational
            submissions.<br />
            (5) Where you believe a patient presents a risk to their safety or the safety of
            others, discuss the information with your supervisor or a Registered Healthcare
            Professional at the earliest possible opportunity.
        </p>
        <p>
            <b>b. You should not</b><br />
            (1) Knowingly give any personal or confidential information to anyone who is
            not entitled to access it.<br />
            (2) Access clinical information about others, or yourself, unless you have a
            valid clinical or managerial reason to do so.<br />
            (3) Use a patient’s confidential information for your own ends or for personal
            gain.
        </p>

        <h4>5. You should keep appropriate records on the care you have given.</h4>
        <p>
            <b>a. You must</b><br />
        (1) Record your findings, decisions, information given to patients, drugs
        issued and other information or treatment offered in an accountable way that
        ensures the information will be entered into the patient’s medical records.<br />
        (2) Make sure that any information you put in someone’s medical record is
        accurate, legible and can be traced back to you.<br />
        (3) Protect information contained in medical records (electronic and paper)
        from being lost, damaged, accessed by someone without permission or
        tampered with.
        </p>
        <p>
            <b>b. You should not</b><br />
        (1) Offer medical advice or medical care without making an appropriate
        record.
        </p>

        <h4>6. You are responsible for your own learning and should keep your professional
            knowledge and skills up to date.</h4>
        <p>
            <b>a. You must</b><br />
        (1) Identify and record training activity and clinical experience, with reflections
        where appropriate.<br />
        (2) Seek out patient and colleague feedback, using it to inform your behaviour
        and your learning. You should include patient complements and complaints in
        your portfolio.<br />
        (3) Ask for help when you need it.<br />
        (4) Make sure that you are appropriately supervised for tasks that you are
        asked to carry out.
        </p>
        <p>
            <b>b. You should not</b><br />
        (1) Act outside your knowledge, skills and experience in a way that may harm
        patients.
        </p>

        <h4>7. You should maintain high standards of personal conduct.</h4>
        <p>
            <b>a. You must</b><br />
            (1) Adhere to the Values and Standards of the British Army at all times.<br />
            (2) Abide by the Army Leadership Code at all times.<br />
            (3) Act within the Law of Armed Conflict, including specific provisions relating
            to Medical Personnel and Protected Persons11.<br />
            (4) Treat your patients and colleagues with respect.<br />
            (5) Behave honestly and ethically at all times.<br />
            (6) Recognise that concerns around your personal conduct on and off duty
            may result in your fitness to practice as a Combat Medical Technician being
            reviewed.
        </p><p>
            <b>b. You must not</b><br />
            (1) Do anything which might damage the trust that the patient, Chain of
            Command and public have in your profession or the wider healthcare system.
        </p>
        <h4>8. You should be honest about how your conduct, competence or health might</h4>
        impact on patients.
        <p>
            <b>a. You must</b><br />
            (1) Seek medical help if you are worried about your health.<br />
            (2) Tell your clinical supervisor about any existing health conditions or
            changes to your health which may put your patients at risk.<br />
            (3) Inform your chain of command if you are convicted of, or cautioned for,
            any military or civilian offence.<br />
            (4) You should communicate effectively and cooperate with all members of
            the multi- disciplinary care team.
        </p>

    </div>
</template>

<script>
    export default {
        name: "HealthAndConfidentialityStatement"
    }
</script>

<style scoped>

    .disclaimer h2 {
        font-size: 1.4em;
    }

    .disclaimer h3 {
        font-size: 1.2em;
    }

    .disclaimer h4 {
        font-size: 1em;
    }

    .disclaimer p {
        font-size: .8em;
    }
</style>