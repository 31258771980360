<template>
    <b-card :text-variant="textVariant"
            :header="header"
            header-class="d-flex h-100 align-items-center justify-content-center"
            body-class="flex-column h-100"
            class="text-center mt-2" :header-bg-variant="variant" :body-bg-variant="variant">
        <Pulse v-if="this.loading" class="loader" />
        <p class="card-text" :class="{'card-text-small': this.small}" v-else>
            {{value}}
        </p>
    </b-card>
</template>

<script>
    import Pulse from '../loaders/Pulse'

    export default {
        name: "TopStatistic",
        props: {
            header: {
                type: String,
                required: true
            },
            value: {
                required: true
            },
            small: {
                type: Boolean,
                required: false,
                defaultValue: false
            },
            loading: {
                type: Boolean,
                required: false,
                defaultValue: false
            },
            variant: {
                type: String,
                required: false,
                defaultValue: "primary"
            },
            textVariant: {
                type: String,
                required: false,
                defaultValue: 'white'
            }
        },
        components: {Pulse},
        watch: {

        }
    }
</script>

<style scoped lang="scss">
    .card-text {
        font-size: 30px;
    }

    .card-text-small {
        font-size: 18px;
    }

    .loader {
        margin-top: 10px;
        margin-bottom: 10px;
    }
</style>