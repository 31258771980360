<template>
    <main role="main" class="col-md-12 no-gutters ml-md-auto col-lg-10 px-4 dark" >
        <TopBanner>
            <slot name="banner-content"></slot>
        </TopBanner>
        <div class="main-container ">
            <router-view></router-view>
            <Footer :version="version" />
        </div>
    </main>
</template>

<script>

    import Footer from "./Footer";
    import TopBanner from "./TopBanner";

    export default {
        name: "MainContent",
        components: {TopBanner, Footer},
        props: {
            version: {
                type: String,
                defaultValue: 'master'
            }
        },
        computed: {

        }

    }
</script>

<style scoped>
    main {
        padding-top: 80px;
    }
</style>