<template>
    <div class="login-override form-login">
        <b-alert show variant="success">
            Please wait
        </b-alert>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Managers from '../../managers/index';
    import EventBus from '../../event-bus';
    import store from "../../store";

    export default {
        name: "LoginUsingOverride",
        data() {

        },
        computed: {
            ...mapGetters({
                hasOverride: 'authentication/hasOverride',
                authToken: 'authentication/getOverrideToken',
                overrideUser: 'authentication/getOverrideUser',
            }),
        },
        methods: {

        },
        created() {
            console.log(this.$route.params);
            console.log('hasOverride', this.hasOverride);
            if (!this.hasOverride) {
                alert('no auth found');
                this.$router.push({name: 'home'});

            } else {
                Managers.authentication.configureHttpClientUsing(this.authToken);
                store.dispatch('session/setOverride', {override: true});
                EventBus.$emit('override-login', this.overrideUser);
                this.$router.push({name: 'home', params: {override: this.overrideUser}});
            }
        }
    }
</script>

<style scoped>
    .form-login {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
    }
</style>