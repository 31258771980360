import Vue from "vue";

export default {
    userInformation() {
        return Vue.http.get('profile/information');
    },
    profile() {
        return Vue.http.get(`profile`);
    },
    saveProfile(profile) {
        return Vue.http.put('profile', profile);
    },
    availableRoles() {
        return Vue.http.get('profile/all-roles');
    },
    updateSecondaryEmail(email) {
        return Vue.http.put('profile/information/secondary-email', {email});
    },
    objectives() {
        return Vue.http.get('profile/pdp/objectives');
    },
    createObjective(objective) {
        return Vue.http.post('profile/pdp/objective', objective);
    },
    updateObjective(objective) {
        return Vue.http.put('profile/pdp/objective', objective);
    },
    objectiveCompleted(objectiveId) {
        return Vue.http.post(`profile/pdp/objective/${objectiveId}/complete`);
    },
    reviews() {
        return Vue.http.get('profile/reviews');
    }
}