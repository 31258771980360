<template>
    <div class="line-chart-widget">
        <b-card :header="title">
            <LineGraph :height="height" :options="lineOptions" :chart-data="chartData" />
        </b-card>
    </div>
</template>

<script>
    import LineGraph from "../../graphs/LineGraph";
    import graphUtilsMixin from "../../../mixins/graphUtils";
    export default {
        name: "LineChartWidget",
        components: {LineGraph},
        mixins: [graphUtilsMixin],
        props: {
            title: {
                type: String,
                required: true
            },
            yAxisLabel: {
                type: String,
                required: false,
                default: ''
            },
            lineData: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                browserWidth: 0,
                lineOptions: {
                    scales: {
                        yAxes: [{
                            stacked: true,
                            ticks: {
                                suggestedMin: 0
                            },
                            scaleLabel: {
                                display: this.yAxisLabel !== '',
                                labelString: this.yAxisLabel
                            }

                        }]
                    }
                }
            }
        },
        computed: {
            chartData() {
                return this.$transformToLineData(this.lineData);
            },
            isMobile() {
                return this.browserWidth < 900;
            },
            height() {
                return this.isMobile ? 300 : 200;
            }
        },
        methods: {
            handleResize() {
                this.browserWidth = window.innerWidth;
            }
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        }
    }
</script>

<style scoped>

</style>