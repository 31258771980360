<template>
    <div class="widgets">
        <b-row  class="mt-4">
            <b-col sm="12" style="text-align: right">
                <router-link style="color: #222222" :to="{name: 'settings'}"><cog-icon /> Customise</router-link>
            </b-col>
        </b-row>
        <b-row v-if="statistics">
            <b-col md="6" class="mt-4" v-if-widget-enabled="'cpdHours'" >
                <PieChartWidget title="Total Hours of CPD Captured" :category-data="statistics.cpdHours" />
            </b-col>
            <b-col md="6" class="mt-4" v-if-widget-enabled="'cceHours'">
                <PieChartWidget title="Total Hours of Clinical Exposure Captured" :category-data="statistics.cceHours" />
            </b-col>
            <b-col md="6" class="mt-4" v-if-widget-enabled="'hoursOverTime'">
                <LineChartWidget title="Hours of Activity Per Month" :line-data="statistics.hoursOverTime" y-axis-label="Hours of Activity" />
            </b-col>
            <b-col md="6" class="mt-4" v-if-widget-enabled="'activityOverTime'">
                <LineChartWidget title="Entries Submitted Per Month" :line-data="statistics.activityOverTime" y-axis-label="Number of Entries" />
            </b-col>
            <b-col md="6" class="mt-4" v-if-widget-enabled="'approvalStatus'">
                <PieChartWidget title="Approval Statuses" :category-data="statistics.statuses" />
            </b-col>
            <b-col md="6" class="mt-4" v-if-widget-enabled="'topScopeOfPractice'">
                <PieChartWidget title="Top 5 Scope of Practice Categories" :category-data="statistics.topScopeOfPracticeCategories" />
            </b-col>
            <b-col md="6" class="mt-4">
                <ActivityWidget />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import PieChartWidget from "./widgets/PieChartWidget";
    import CogIcon from 'vue-material-design-icons/Cog';
    import LineChartWidget from "./widgets/LineChartWidget";
    import ActivityWidget from "./widgets/ActivityWidget";

    export default {
        name: "Widgets",
        components: {ActivityWidget, LineChartWidget, CogIcon, PieChartWidget},
        props: {
            statistics: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>
