<template>
    <div class="shared-cpd-item">
        <b-card class="cpd-card" border-variant="dark">
            <b-row>
                <b-col sm="3" class="user-section">
                    <ProfileImage :width="60" :profile-image-url="avatarUrl"/>
                    <span class="attribute name">{{nickname}}</span>
                    <span class="attribute date">{{date}}</span>
                    <b-badge class="mt-2 category" variant="secondary">{{category}}</b-badge>
                    <div class="helpful-container mt-2" >
                        <a class="helpful mt-2" v-bind:class="{'is-helpful': internalHasUpvoted}" v-if="showHelpful" href="#" @click.prevent="toggleHelpful"> <thumb-up-outline v-if="!internalHasUpvoted"></thumb-up-outline> <thumb-up v-if="internalHasUpvoted"></thumb-up> Helpful </a>
                        <b-badge :variant="helpfulCountVariant" class="mr-2 helpful-count" pill >{{internalUpvotes}}</b-badge>
                    </div>
                </b-col>
                <b-col sm="9">
                    <b-card-title>{{title}}</b-card-title>
                    <b-card-text>
                        <ReadMore v-if="descriptionAsReadmore" :max-chars="200" :text="description" />
                        <p v-else>{{description}}</p>
                    </b-card-text>
                    <b-link v-if="websiteUrl" :href="websiteUrl" class="web-link" target="_blank"><open-in-new></open-in-new> Visit Website</b-link>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
    import ProfileImage from "../profile/ProfileImage";
    import OpenInNew from 'vue-material-design-icons/OpenInNew';
    import ReadMore from "../common/ReadMore";
    import ThumbUp from 'vue-material-design-icons/ThumbUp';
    import ThumbUpOutline from 'vue-material-design-icons/ThumbUpOutline';

    export default {
        name: "SharedCPDListItem",
        components: {ReadMore, ProfileImage, OpenInNew, ThumbUpOutline, ThumbUp},
        props: {
            sharedCpdId: {
                type: Number,
                required: false
            },
            avatarUrl: {
                type: String,
                required: true
            },
            nickname: {
                type: String,
                required: true
            },
            date: {
                type: String,
                required: true
            },
            category: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            description: {
                type: String,
                required: true
            },
            websiteUrl: {
                type: String,
                required: false
            },
            descriptionAsReadmore: {
                type: Boolean,
                defaultValue: true
            },
            helpful: {
                type: Boolean,
                defaultValue: false
            },
            hasUpvoted: {
                type: Boolean,
                defaultValue: false
            },
            upvotes: {
                type: Number,
                defaultValue: 0,
                required: false
            },
            showHelpful: {
                type: Boolean,
                defaultValue: false
            }

        },
        data: function() {
            return {
                internalUpvotes: 0,
                internalHasUpvoted: false
            }
        },
        methods: {
            toggleHelpful() {
                if (this.internalHasUpvoted) {
                    this.$http.delete(`shared-cpd/${this.sharedCpdId}/upvote`).then((resp) => {
                        this.internalUpvotes = resp.body.currentUpvotes;
                        this.internalHasUpvoted = false;
                    }).catch((resp) => {
                        this.$httpError('Failed to remove upvote', resp);
                    });
                } else {
                    this.$http.post(`shared-cpd/${this.sharedCpdId}/upvote`).then((resp) => {
                        this.internalUpvotes = resp.body.currentUpvotes;
                        this.internalHasUpvoted = true;
                    }).catch((resp) => {
                        this.$httpError('Failed to upvote', resp);
                    })
                }
            }
        },
        computed: {
            helpfulCountVariant() {
                return this.internalHasUpvoted ? 'success' : 'light';
            }
        },
        created() {
            this.internalUpvotes = this.upvotes;
            this.internalHasUpvoted = this.hasUpvoted;
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/custom";

    .user-section {
        text-align: center;
    }

    .user-section span.attribute {
        display:block;
    }

    .user-section span.name {
        margin-top: 5px;
        font-weight: bold;
        font-size: 14px;
    }

    .user-section span.date {
        font-weight: lighter;
        font-size: 12px;

    }

    .cpd-card {
        border-radius: 10px;
    }

    a.web-link {
        color: #2c3e50;

    }

    .user-section span.badge.category {
        display:block;
        word-wrap: normal;
        white-space: normal;
    }

    .helpful {
        font-size: 14px;
        color: #555555;
    }

    .helpful.is-helpful {
        color: $green;
    }

    .helpful-count {
        font-size: 12px;
    }

</style>