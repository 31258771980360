const WordCountUtils = {

    wordCount(value) {
        if (value === null || value === undefined) return 0;
        return value.trim()
            .split(/\s+/)
            .filter(function(n) {return n !== ''}).length;
    },

    charCount(value) {
        if (value === null || value === undefined) return 0;
        return value.length;
    }

};

export default WordCountUtils;
