import store from '../store/index';

function updateStyle(el, binding) {

    if (store.getters["settings/getSettings"].dashboardWidgets[binding.value]) {
        el.style.display = el.__disp_prev__;
    } else {
        el.style.display = 'none';
    }
}

const IfWidgetEnabled = {
    bind: function (el, binding) {
        el.__disp_prev__ = el.style.display;
        updateStyle(el, binding);
        el.__settings_unwatch__ = store.watch(state => state.settings.settings, () => {
            updateStyle(el, binding);
        });
    },

    unbind(el) {
        el.__settings_unwatch__ && el.__settings_unwatch__()
    }

};

export default IfWidgetEnabled;
