<template>
    <nav class="col-lg-2 d-none d-lg-block sidebar">
        <div class="sidebar-sticky">
            <slot></slot>
        </div>
    </nav>
</template>

<script>

    export default {
        name: "Sidebar",
    }
</script>

<style scoped lang="scss">
    nav.sidebar {
        background-color: $gray-100;
    }

    .sidebar {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 100; /* Behind the navbar */
        padding: 64px 0 0; /* Height of navbar */
        box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    }

    .sidebar-sticky {
        position: relative;
        top: 0;
        height: calc(100vh - 48px);
        padding-top: .5rem;
        overflow-x: hidden;
        overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    }

    @supports ((position: -webkit-sticky) or (position: sticky)) {
        .sidebar-sticky {
            position: -webkit-sticky;
            position: sticky;
        }
    }


</style>